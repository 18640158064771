import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
};

export type AllAssignmentGradesModel = {
  __typename?: 'AllAssignmentGradesModel';
  overall?: Maybe<Scalars['Float']>;
  users: Array<AssignmentUser>;
};

export type AnecdotalInput = {
  anecdotalTypeId: Scalars['String'];
  isNotifyParent?: Scalars['Boolean'];
  isNotifyStudent?: Scalars['Boolean'];
  isVisibleParent?: Scalars['Boolean'];
  isVisibleStudent?: Scalars['Boolean'];
  referToIds?: InputMaybe<Array<Scalars['String']>>;
  schoolId: Scalars['String'];
  severity: AnecdotalSeverityEnum;
  text: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type AnecdotalModel = {
  __typename?: 'AnecdotalModel';
  anecdotalType: AnecdotalTypeModel;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: UserSchoolModel;
  id: Scalars['String'];
  isNotifyParent: Scalars['Boolean'];
  isNotifyStudent: Scalars['Boolean'];
  isVisibleParent: Scalars['Boolean'];
  isVisibleStudent: Scalars['Boolean'];
  referTo?: Maybe<Array<UserSchoolModel>>;
  severity: AnecdotalSeverityEnum;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: UserSchoolModel;
};

export type AnecdotalPaginationModel = {
  __typename?: 'AnecdotalPaginationModel';
  data?: Maybe<Array<AnecdotalModel>>;
  pagination: CursorPagination;
};

export enum AnecdotalSeverityEnum {
  Minor = 'Minor',
  Moderate = 'Moderate',
  Severe = 'Severe'
}

export type AnecdotalTypeInput = {
  names: Array<Scalars['String']>;
  schoolId: Scalars['String'];
};

export type AnecdotalTypeModel = {
  __typename?: 'AnecdotalTypeModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  districtId: Scalars['String'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  school?: Maybe<SchoolModel>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnecdotalTypePaginationModel = {
  __typename?: 'AnecdotalTypePaginationModel';
  data?: Maybe<Array<AnecdotalTypeModel>>;
  pagination: CursorPagination;
};

export type AnecdotalUpdateInput = {
  anecdotalTypeId: Scalars['String'];
  isVisibleParent: Scalars['Boolean'];
  isVisibleStudent: Scalars['Boolean'];
  referToIds?: InputMaybe<Array<Scalars['String']>>;
  severity: AnecdotalSeverityEnum;
  text: Scalars['String'];
};

export enum AppEnum {
  GoogleClassroom = 'GoogleClassroom'
}

export type AppModel = {
  __typename?: 'AppModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: AppEnum;
  provider: ProviderModel;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AssignmentClassInput = {
  assignees: Array<Scalars['String']>;
  categoryId: Scalars['String'];
  classId: Scalars['String'];
  markingPeriodId: Scalars['String'];
};

export type AssignmentInput = {
  assignees: Array<Scalars['String']>;
  attachments: Array<FileObjectInput>;
  categoryId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  isSubmittable?: Scalars['Boolean'];
  markingPeriodId: Scalars['String'];
  name: Scalars['String'];
  points: Scalars['Float'];
};

export type AssignmentModel = {
  __typename?: 'AssignmentModel';
  attachments: Array<FileModel>;
  averageGrade?: Maybe<Scalars['Float']>;
  category: GradebookSettingValuesModel;
  class: ClassModel;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: UserModel;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  expectedAssignees: Array<ClassUserModel>;
  id: Scalars['String'];
  isSubmittable: Scalars['Boolean'];
  markingPeriod: MarkingPeriodModel;
  name: Scalars['String'];
  points: Scalars['Int'];
  status: AssignmentStatusEnum;
  submissionCount?: Maybe<Scalars['Int']>;
  submissionExpected?: Maybe<Scalars['Int']>;
  submissions: Array<SubmissionModel>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users: Array<ClassUserModel>;
};

export enum AssignmentStatusEnum {
  Deleted = 'Deleted',
  Draft = 'Draft',
  Published = 'Published'
}

export type AssignmentSubmissionsModel = {
  __typename?: 'AssignmentSubmissionsModel';
  assignment: AssignmentModel;
  grade: UserGrade;
  status: SubmissionStatusEnum;
  submission?: Maybe<SubmissionModel>;
  user: ClassUserModel;
};

export type AssignmentUser = {
  __typename?: 'AssignmentUser';
  assignmentGrades: Array<UserGrade>;
  overall?: Maybe<Scalars['Float']>;
  user: ClassUserModel;
};

export type AttendanceClassInput = {
  attendance?: InputMaybe<Array<AttendanceInput>>;
  classId: Scalars['String'];
  date: Scalars['String'];
};

export type AttendanceClassObject = {
  __typename?: 'AttendanceClassObject';
  attendanceStatus?: Maybe<AttendanceStatusObject>;
  user: ClassUserModel;
};

export type AttendanceColumnObject = {
  __typename?: 'AttendanceColumnObject';
  attendanceStatus: AttendanceStatusObject;
  id: Scalars['String'];
};

export type AttendanceInput = {
  attendanceStatusId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type AttendanceRowObject = {
  __typename?: 'AttendanceRowObject';
  attendances: Array<AttendanceColumnObject>;
  class: ClassModel;
};

export type AttendanceStatusObject = {
  __typename?: 'AttendanceStatusObject';
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AttendanceUserObject = {
  __typename?: 'AttendanceUserObject';
  columns: Array<ColumnObject>;
  rows: Array<AttendanceRowObject>;
};

export type BulkAssignmentInput = {
  attachments: Array<FileObjectInput>;
  classes: Array<AssignmentClassInput>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  isSubmittable?: Scalars['Boolean'];
  name: Scalars['String'];
  points: Scalars['Float'];
  schoolId: Scalars['String'];
  status?: AssignmentStatusEnum;
};

export enum BulkMpClassUpdateEnum {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type ClassGoogleModel = {
  __typename?: 'ClassGoogleModel';
  assignments: Scalars['Boolean'];
  connected: Scalars['Boolean'];
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  users: Scalars['Boolean'];
};

export type ClassInput = {
  gradebookSettingId: Scalars['String'];
  markingPeriodIds: Array<Scalars['String']>;
  name: Scalars['String'];
  room?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ClassModel = {
  __typename?: 'ClassModel';
  cleverId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  googleData: ClassGoogleModel;
  gradebookSetting: GradebookSettingModel;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  markingPeriods: Array<MarkingPeriodModel>;
  name: Scalars['String'];
  room?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolModel>;
  schoolId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users: Array<ClassUserModel>;
};

export type ClassPaginationModel = {
  __typename?: 'ClassPaginationModel';
  data?: Maybe<Array<ClassModel>>;
  pagination: CursorPagination;
};

export type ClassUserModel = {
  __typename?: 'ClassUserModel';
  deactivatedInSync: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  user: UserSchoolModel;
  userFromCleverSync: Scalars['Boolean'];
};

export type CleverClassModel = {
  __typename?: 'CleverClassModel';
  cleverId: Scalars['String'];
  courseId: Scalars['String'];
  courseNumber?: Maybe<Scalars['String']>;
  districtId: Scalars['String'];
  lastSyncedAt: Scalars['DateTime'];
  name: Scalars['String'];
  practiceClass?: Maybe<ClassModel>;
  practiceId?: Maybe<Scalars['String']>;
  practiceStatus: ProviderStatusEnum;
  schoolId: Scalars['String'];
  sectionGrade?: Maybe<Scalars['String']>;
  sectionNumber?: Maybe<Scalars['String']>;
  students: Array<Scalars['String']>;
  subject: Scalars['String'];
  teachers: Array<Scalars['String']>;
  termId: Scalars['String'];
};

export type CleverClassesInput = {
  data: Array<CleverSingleClassInput>;
  schoolId: Scalars['String'];
};

export type CleverSingleClassInput = {
  cleverId: Scalars['String'];
  courseNumber?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  practiceId?: InputMaybe<Scalars['String']>;
  practiceStatus: ProviderStatusEnum;
  sectionGrade?: InputMaybe<Scalars['String']>;
  sectionNumber?: InputMaybe<Scalars['String']>;
  students: Array<Scalars['String']>;
  teachers: Array<Scalars['String']>;
  termId: Scalars['String'];
};

export type CleverSingleUserInput = {
  cleverId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  grade?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  practiceId?: InputMaybe<Scalars['String']>;
  practiceStatus: ProviderStatusEnum;
  roleId: Scalars['String'];
  studentNumber?: InputMaybe<Scalars['String']>;
};

export type CleverUserModel = {
  __typename?: 'CleverUserModel';
  cleverId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  grade?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  lastSyncedAt: Scalars['DateTime'];
  practiceId?: Maybe<Scalars['String']>;
  practiceStatus: ProviderStatusEnum;
  practiceUser?: Maybe<UserSchoolModel>;
  roleId: Scalars['String'];
  studentNumber?: Maybe<Scalars['String']>;
};

export type CleverUsersInput = {
  data: Array<CleverSingleUserInput>;
  schoolId: Scalars['String'];
};

export type ColumnObject = {
  __typename?: 'ColumnObject';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CommentModel = {
  __typename?: 'CommentModel';
  comment: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  school: SchoolModel;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum CommentTypeEnum {
  Behavioral = 'Behavioral'
}

export type CreateCommentInput = {
  comment: Scalars['String'];
  schoolId: Scalars['String'];
  type: CommentTypeEnum;
};

export type CreateSchoolUserResponse = {
  __typename?: 'CreateSchoolUserResponse';
  exists: Scalars['Boolean'];
  user: UserSchoolModel;
};

export type CursorPagination = {
  __typename?: 'CursorPagination';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
  totalCount: Scalars['Int'];
};

export type DistrictLoginInput = {
  districtId: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe: Scalars['Boolean'];
};

export type DistrictModel = {
  __typename?: 'DistrictModel';
  background?: Maybe<FileModel>;
  createdAt?: Maybe<Scalars['DateTime']>;
  favicon?: Maybe<FileModel>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  logo?: Maybe<FileModel>;
  name: Scalars['String'];
  schools?: Maybe<Array<SchoolModel>>;
  schoolsCount?: Maybe<Scalars['Int']>;
  timezone: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  users?: Maybe<Array<UserModel>>;
  usersCount?: Maybe<Scalars['Int']>;
};

export type EnableAppInput = {
  authorizationCode: Scalars['String'];
  name: AppEnum;
};

export type FileModel = {
  __typename?: 'FileModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  size: Scalars['Float'];
  url: Scalars['String'];
};

export type FileObjectInput = {
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  size: Scalars['Float'];
  url: Scalars['String'];
};

export type ForgotPasswordInput = {
  districtId: Scalars['String'];
  email: Scalars['String'];
};

export type GoogleAssignmentsInput = {
  classId: Scalars['String'];
  data: Array<GoogleSingleAssignmentInput>;
};

export type GoogleAuthTokenInput = {
  code: Scalars['String'];
  districtId: Scalars['String'];
};

export type GoogleClassroomAssignmentModel = {
  __typename?: 'GoogleClassroomAssignmentModel';
  assignees: Array<Scalars['String']>;
  attachments: Array<FileModel>;
  description: Scalars['String'];
  dueDate?: Maybe<Scalars['DateTime']>;
  googleId: Scalars['String'];
  isSubmittable: Scalars['Boolean'];
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  points: Scalars['Float'];
  practiceAssignment?: Maybe<AssignmentModel>;
  practiceId?: Maybe<Scalars['String']>;
  practiceStatus: ProviderStatusEnum;
  status: AssignmentStatusEnum;
};

export type GoogleClassroomClassModel = {
  __typename?: 'GoogleClassroomClassModel';
  googleId: Scalars['String'];
  name: Scalars['String'];
};

export type GoogleClassroomUserModel = {
  __typename?: 'GoogleClassroomUserModel';
  email: Scalars['String'];
  firstName: Scalars['String'];
  googleId: Scalars['String'];
  lastName: Scalars['String'];
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  practiceId?: Maybe<Scalars['String']>;
  practiceStatus: ProviderStatusEnum;
  practiceUser?: Maybe<UserSchoolModel>;
  roleId: Scalars['String'];
};

export type GoogleSingleAssignmentInput = {
  assignees?: Array<Scalars['String']>;
  attachments?: Array<FileObjectInput>;
  categoryId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  dueDate?: InputMaybe<Scalars['DateTime']>;
  googleId: Scalars['String'];
  isSubmittable?: Scalars['Boolean'];
  markingPeriodId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  points: Scalars['Float'];
  practiceId?: InputMaybe<Scalars['String']>;
  practiceStatus: ProviderStatusEnum;
  status: AssignmentStatusEnum;
};

export type GoogleSingleUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  googleId: Scalars['String'];
  lastName: Scalars['String'];
  practiceId?: InputMaybe<Scalars['String']>;
  practiceStatus: ProviderStatusEnum;
  roleId: Scalars['String'];
};

export type GoogleUsersInput = {
  classId: Scalars['String'];
  data: Array<GoogleSingleUserInput>;
};

export enum GradeActionEnum {
  Draft = 'draft',
  Return = 'return'
}

export type GradeExcuseInput = {
  assignmentId: Scalars['String'];
  gradeExcuseId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type GradeExcuseListInput = {
  grades: Array<GradeExcuseInput>;
};

export type GradeExcuseModel = {
  __typename?: 'GradeExcuseModel';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type GradeInput = {
  assignmentId: Scalars['String'];
  draft?: Scalars['Boolean'];
  gradeExcuseId?: InputMaybe<Scalars['String']>;
  points?: InputMaybe<Scalars['Float']>;
  userId: Scalars['String'];
};

export type GradebookSettingInput = {
  name: Scalars['String'];
  type: GradebookSettingTypeEnum;
  values?: InputMaybe<Array<GradebookSettingValuesInput>>;
};

export type GradebookSettingModel = {
  __typename?: 'GradebookSettingModel';
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  school: SchoolModel;
  type: GradebookSettingTypeEnum;
  values: Array<GradebookSettingValuesModel>;
};

export type GradebookSettingPaginationModel = {
  __typename?: 'GradebookSettingPaginationModel';
  data?: Maybe<Array<GradebookSettingModel>>;
  pagination: CursorPagination;
};

export enum GradebookSettingTypeEnum {
  NoOverallGrade = 'NoOverallGrade',
  TotalPoints = 'TotalPoints',
  WeightedByCategory = 'WeightedByCategory'
}

export type GradebookSettingValuesInput = {
  color: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  value?: InputMaybe<Scalars['Int']>;
};

export type GradebookSettingValuesModel = {
  __typename?: 'GradebookSettingValuesModel';
  color: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
};

export type GradesListInput = {
  grades: Array<GradeInput>;
};

export type ImportAssignmentInput = {
  assignmentId?: InputMaybe<Scalars['String']>;
  points?: InputMaybe<Scalars['Float']>;
};

export type ImportAssignmentObject = {
  __typename?: 'ImportAssignmentObject';
  assignment?: Maybe<AssignmentModel>;
  points?: Maybe<Scalars['Float']>;
};

export type ImportAssignmentsGradesInput = {
  assignments?: InputMaybe<Array<ImportAssignmentInput>>;
  overall?: InputMaybe<Scalars['Float']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ImportAssignmentsGradesObject = {
  __typename?: 'ImportAssignmentsGradesObject';
  assignments?: Maybe<Array<ImportAssignmentObject>>;
  errors?: Maybe<Array<ImportErrorObject>>;
  overall?: Maybe<Scalars['Float']>;
  user?: Maybe<ClassUserModel>;
};

export type ImportErrorObject = {
  __typename?: 'ImportErrorObject';
  error: Scalars['String'];
  key: Scalars['String'];
};

export type IntegrationBlocksDto = {
  __typename?: 'IntegrationBlocksDTO';
  description?: Maybe<Scalars['String']>;
  isDisable: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  key: Scalars['String'];
  label: Scalars['String'];
  required: Scalars['Boolean'];
  value?: Maybe<Scalars['String']>;
};

export type IntegrationSetupModel = {
  __typename?: 'IntegrationSetupModel';
  hasIntegration: Scalars['Boolean'];
  keys: Array<IntegrationBlocksDto>;
  status: Scalars['Boolean'];
  type: ProviderEnum;
};

export type LoginModel = {
  __typename?: 'LoginModel';
  token: Scalars['String'];
};

export type MagicLoginInput = {
  districtId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  rememberMe?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type MagicLoginModel = {
  __typename?: 'MagicLoginModel';
  code?: Maybe<Scalars['String']>;
  districts: Array<DistrictModel>;
  email: Scalars['String'];
};

export type MarkingPeriodInput = {
  fromDate: Scalars['DateTime'];
  name: Scalars['String'];
  termId: Scalars['String'];
  toDate: Scalars['DateTime'];
};

export type MarkingPeriodModel = {
  __typename?: 'MarkingPeriodModel';
  createdAt?: Maybe<Scalars['Boolean']>;
  fromDate: Scalars['DateTime'];
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  term: TermModel;
  toDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type MarkingPeriodPaginationModel = {
  __typename?: 'MarkingPeriodPaginationModel';
  data?: Maybe<Array<MarkingPeriodModel>>;
  pagination: CursorPagination;
};

export type MessageInput = {
  classIds?: InputMaybe<Array<Scalars['String']>>;
  email: Scalars['Boolean'];
  message: Scalars['String'];
  roleIds?: InputMaybe<Array<Scalars['String']>>;
  schoolId: Scalars['String'];
  sms: Scalars['Boolean'];
  subject: Scalars['String'];
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type MultipleIdsInput = {
  ids: Array<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addUserParents: Scalars['Boolean'];
  addUsersToClass: ClassModel;
  bulkAssignmentStatusUpdate: Scalars['Boolean'];
  bulkUserStatusUpdate: Scalars['Boolean'];
  createAnecdotal: Scalars['Boolean'];
  createAnecdotalType: Scalars['Boolean'];
  createAssignment: Scalars['Boolean'];
  createClass: ClassModel;
  createComment: CommentModel;
  createGradeExcuse: GradeExcuseModel;
  createGradeReport: Scalars['Boolean'];
  createGradebook: GradebookSettingModel;
  createMarkingPeriod: MarkingPeriodModel;
  createReport: Scalars['Boolean'];
  createSubmission: SubmissionModel;
  createTag: TagModel;
  createTerm: TermModel;
  createUser: CreateSchoolUserResponse;
  disableUserApp: Scalars['Boolean'];
  districtAuth: LoginModel;
  enableUserOAuthApp: Scalars['Boolean'];
  externalAuth: LoginModel;
  forgotPassword: Scalars['Boolean'];
  hardRemoveAnecdotalType: AnecdotalTypeModel;
  importParseAssignmentGrades: Array<ImportAssignmentsGradesObject>;
  importStoreAssignmentGrades?: Maybe<Array<ImportAssignmentsGradesObject>>;
  magicAuth: LoginModel;
  reSyncGoogleAssignments: Scalars['Boolean'];
  reactivateAnecdotalTypes: Scalars['Boolean'];
  reactivateClasses: Scalars['Boolean'];
  reactivateGradebooks: Scalars['Boolean'];
  reactivateMarkingPeriods: Scalars['Boolean'];
  reactivateTags: Scalars['Boolean'];
  reactivateTerms: Scalars['Boolean'];
  reconnectUserSyncInClass: ClassModel;
  removeAnecdotalTypes: Scalars['Boolean'];
  removeAnecdotals: Scalars['Boolean'];
  removeAssignmentHard: Scalars['Boolean'];
  removeClasses: Scalars['Boolean'];
  removeComments: Scalars['Boolean'];
  removeGradeExcuses: Scalars['Boolean'];
  removeGradebooks: Scalars['Boolean'];
  removeMarkingPeriods: Scalars['Boolean'];
  removeReports: Scalars['Boolean'];
  removeSubmissionHard: Scalars['Boolean'];
  removeTags: Scalars['Boolean'];
  removeTerms: Scalars['Boolean'];
  removeUserParents: Scalars['Boolean'];
  removeUsersFromClass: ClassModel;
  resetPassword: Scalars['Boolean'];
  resetUserOverall: ResetOverallModel;
  returnAllAssignmentGrades: Scalars['Boolean'];
  saveClassAttendance: Array<AttendanceClassObject>;
  sendMagicLink: Scalars['Boolean'];
  sendMessage: Scalars['Boolean'];
  setGradeExcuse: Scalars['Boolean'];
  setOverall: Scalars['Float'];
  storeCleverClasses: Scalars['Boolean'];
  storeCleverUsers: Scalars['Boolean'];
  storeGoogleAssignments: Scalars['Boolean'];
  storeGoogleUsers: Scalars['Boolean'];
  storeGrade: Scalars['Boolean'];
  syncGoogleClass: Scalars['Boolean'];
  toggleAssignmentSubmissions: Scalars['Boolean'];
  toggleClassMarkingPeriods: Array<ClassModel>;
  unSyncCleverClass: Scalars['Boolean'];
  unSyncGoogleAssignments: Scalars['Boolean'];
  unSyncGoogleClass: Scalars['Boolean'];
  unSyncGoogleUsers: Scalars['Boolean'];
  updateAnecdotal: AnecdotalModel;
  updateAssignment: AssignmentModel;
  updateClass: ClassModel;
  updateComment: CommentModel;
  updateGradeExcuse: GradeExcuseModel;
  updateGradebook: GradebookSettingModel;
  updateMarkingPeriod: MarkingPeriodModel;
  updateSchool: SchoolModel;
  updateTag: TagModel;
  updateTerm: TermModel;
  updateUser: UserSchoolModel;
  uploadFiles: Array<UploadFileInput>;
};


export type MutationAddUserParentsArgs = {
  parentIds: Array<Scalars['String']>;
  userId: Scalars['String'];
};


export type MutationAddUsersToClassArgs = {
  id: Scalars['String'];
  input: MultipleIdsInput;
};


export type MutationBulkAssignmentStatusUpdateArgs = {
  input: MultipleIdsInput;
  status: AssignmentStatusEnum;
};


export type MutationBulkUserStatusUpdateArgs = {
  input: UpdateBulkUserSchoolStatusInput;
};


export type MutationCreateAnecdotalArgs = {
  input: AnecdotalInput;
};


export type MutationCreateAnecdotalTypeArgs = {
  input: AnecdotalTypeInput;
};


export type MutationCreateAssignmentArgs = {
  input: BulkAssignmentInput;
};


export type MutationCreateClassArgs = {
  input: ClassInput;
  schoolId: Scalars['String'];
};


export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};


export type MutationCreateGradeExcuseArgs = {
  name: Scalars['String'];
  schoolId: Scalars['String'];
};


export type MutationCreateGradeReportArgs = {
  input: ReportGradeInput;
};


export type MutationCreateGradebookArgs = {
  input: GradebookSettingInput;
  schoolId: Scalars['String'];
};


export type MutationCreateMarkingPeriodArgs = {
  input: MarkingPeriodInput;
  schoolId: Scalars['String'];
};


export type MutationCreateReportArgs = {
  input: ReportInput;
};


export type MutationCreateSubmissionArgs = {
  input: SubmissionInput;
};


export type MutationCreateTagArgs = {
  input: TagInput;
  schoolId: Scalars['String'];
};


export type MutationCreateTermArgs = {
  input: TermInput;
  schoolId: Scalars['String'];
};


export type MutationCreateUserArgs = {
  input: UserSchoolInput;
  schoolId: Scalars['String'];
};


export type MutationDisableUserAppArgs = {
  name: AppEnum;
};


export type MutationDistrictAuthArgs = {
  input: DistrictLoginInput;
};


export type MutationEnableUserOAuthAppArgs = {
  input: EnableAppInput;
};


export type MutationExternalAuthArgs = {
  input: GoogleAuthTokenInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationHardRemoveAnecdotalTypeArgs = {
  input: MultipleIdsInput;
};


export type MutationImportParseAssignmentGradesArgs = {
  classId: Scalars['String'];
  link: Scalars['String'];
};


export type MutationImportStoreAssignmentGradesArgs = {
  classId: Scalars['String'];
  input: Array<ImportAssignmentsGradesInput>;
  markingPeriodId: Scalars['String'];
  skipNotValid?: Scalars['Boolean'];
};


export type MutationMagicAuthArgs = {
  input: ValidateAuthTokenInput;
};


export type MutationReSyncGoogleAssignmentsArgs = {
  classId: Scalars['String'];
};


export type MutationReactivateAnecdotalTypesArgs = {
  input: MultipleIdsInput;
};


export type MutationReactivateClassesArgs = {
  input: MultipleIdsInput;
};


export type MutationReactivateGradebooksArgs = {
  input: MultipleIdsInput;
};


export type MutationReactivateMarkingPeriodsArgs = {
  input: MultipleIdsInput;
};


export type MutationReactivateTagsArgs = {
  input: MultipleIdsInput;
};


export type MutationReactivateTermsArgs = {
  input: MultipleIdsInput;
};


export type MutationReconnectUserSyncInClassArgs = {
  id: Scalars['String'];
  input: MultipleIdsInput;
};


export type MutationRemoveAnecdotalTypesArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveAnecdotalsArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveAssignmentHardArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveClassesArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveCommentsArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveGradeExcusesArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveGradebooksArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveMarkingPeriodsArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveReportsArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveSubmissionHardArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveTagsArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveTermsArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveUserParentsArgs = {
  parentIds: Array<Scalars['String']>;
  userId: Scalars['String'];
};


export type MutationRemoveUsersFromClassArgs = {
  id: Scalars['String'];
  input: MultipleIdsInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationResetUserOverallArgs = {
  input: ResetUserOverallInput;
};


export type MutationReturnAllAssignmentGradesArgs = {
  assignmentId: Scalars['String'];
};


export type MutationSaveClassAttendanceArgs = {
  input: AttendanceClassInput;
};


export type MutationSendMagicLinkArgs = {
  input: MagicLoginInput;
};


export type MutationSendMessageArgs = {
  input: MessageInput;
};


export type MutationSetGradeExcuseArgs = {
  input: GradeExcuseListInput;
};


export type MutationSetOverallArgs = {
  input: OverallGradeInput;
};


export type MutationStoreCleverClassesArgs = {
  input: CleverClassesInput;
};


export type MutationStoreCleverUsersArgs = {
  input: CleverUsersInput;
};


export type MutationStoreGoogleAssignmentsArgs = {
  input: GoogleAssignmentsInput;
};


export type MutationStoreGoogleUsersArgs = {
  input: GoogleUsersInput;
};


export type MutationStoreGradeArgs = {
  input: GradesListInput;
};


export type MutationSyncGoogleClassArgs = {
  classId: Scalars['String'];
  googleClassId: Scalars['String'];
  googleClassName: Scalars['String'];
};


export type MutationToggleAssignmentSubmissionsArgs = {
  id: Scalars['String'];
  isSubmittable: Scalars['Boolean'];
};


export type MutationToggleClassMarkingPeriodsArgs = {
  input: UpdateMpClassInput;
};


export type MutationUnSyncCleverClassArgs = {
  classId: Scalars['String'];
  removeData?: Scalars['Boolean'];
};


export type MutationUnSyncGoogleAssignmentsArgs = {
  classId: Scalars['String'];
  removeData?: Scalars['Boolean'];
};


export type MutationUnSyncGoogleClassArgs = {
  classId: Scalars['String'];
  removeData?: Scalars['Boolean'];
};


export type MutationUnSyncGoogleUsersArgs = {
  classId: Scalars['String'];
  removeData?: Scalars['Boolean'];
};


export type MutationUpdateAnecdotalArgs = {
  id: Scalars['String'];
  input: AnecdotalUpdateInput;
};


export type MutationUpdateAssignmentArgs = {
  id: Scalars['String'];
  input: AssignmentInput;
};


export type MutationUpdateClassArgs = {
  id: Scalars['String'];
  input: UpdateClassInput;
};


export type MutationUpdateCommentArgs = {
  id: Scalars['String'];
  input: UpdateCommentInput;
};


export type MutationUpdateGradeExcuseArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdateGradebookArgs = {
  id: Scalars['String'];
  input: GradebookSettingInput;
};


export type MutationUpdateMarkingPeriodArgs = {
  id: Scalars['String'];
  input: MarkingPeriodInput;
};


export type MutationUpdateSchoolArgs = {
  id: Scalars['String'];
  input: SchoolInput;
};


export type MutationUpdateTagArgs = {
  id: Scalars['String'];
  input: TagInput;
};


export type MutationUpdateTermArgs = {
  id: Scalars['String'];
  input: TermInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserSchoolInput;
  schoolId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUploadFilesArgs = {
  files: Array<Scalars['Upload']>;
};

export type OverallGradeInput = {
  classId: Scalars['String'];
  markingPeriodId: Scalars['String'];
  points?: InputMaybe<Scalars['Float']>;
  userId: Scalars['String'];
  userStatus?: InputMaybe<Scalars['Boolean']>;
};

export type ParentModel = {
  __typename?: 'ParentModel';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  image?: Maybe<FileModel>;
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export enum ProviderEnum {
  Clever = 'Clever',
  Google = 'Google',
  GoogleLogin = 'GoogleLogin'
}

export type ProviderModel = {
  __typename?: 'ProviderModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum ProviderStatusEnum {
  Ignore = 'ignore',
  Map = 'map',
  New = 'new',
  NotImported = 'not_imported'
}

export type Query = {
  __typename?: 'Query';
  generateAuthLink: Scalars['String'];
  getAllAssignmentsGrades: AllAssignmentGradesModel;
  getAnecdotal: AnecdotalModel;
  getAnecdotalTypes: AnecdotalTypePaginationModel;
  getAnecdotals: AnecdotalPaginationModel;
  getAssignment: AssignmentModel;
  getAssignmentSubmissions: Array<AssignmentSubmissionsModel>;
  getAssignments: Array<AssignmentModel>;
  getAttendanceStatuses: Array<AttendanceStatusObject>;
  getClass: ClassModel;
  getClassAttendance: Array<AttendanceClassObject>;
  getClassCleverUsers: Array<UserSchoolModel>;
  getClasses: ClassPaginationModel;
  getClassesByUser: ClassPaginationModel;
  getCleverAuthLink: Scalars['String'];
  getCleverClasses: Array<CleverClassModel>;
  getCleverUsers: Array<CleverUserModel>;
  getCommentTemplates: Array<CommentModel>;
  getComments: Array<CommentModel>;
  getDistrict: DistrictModel;
  getDistricts: Array<DistrictModel>;
  getGoogleAssignments: Array<GoogleClassroomAssignmentModel>;
  getGoogleAuthLink: Scalars['String'];
  getGoogleClasses: Array<GoogleClassroomClassModel>;
  getGoogleUsers: Array<GoogleClassroomUserModel>;
  getGradeExcuse: GradeExcuseModel;
  getGradeExcuses: Array<GradeExcuseModel>;
  getGradebook: GradebookSettingModel;
  getGradebooks: GradebookSettingPaginationModel;
  getMarkingPeriod: MarkingPeriodModel;
  getMarkingPeriods: MarkingPeriodPaginationModel;
  getParentStudents: Array<UserSchoolModel>;
  getPublicIntegration: IntegrationSetupModel;
  getReports: ReportPaginationModel;
  getRoles: Array<RoleModel>;
  getSchool: SchoolModel;
  getStudentAssignments: Array<StudentAssignmentsListModel>;
  getStudentGrades: StudentGradesModel;
  getStudentOverall: Scalars['Float'];
  getStudentSubmission: AssignmentSubmissionsModel;
  getSubmission: SubmissionModel;
  getSubmissions: Array<SubmissionModel>;
  getTag: TagModel;
  getTags: TagPaginationModel;
  getTerm: TermModel;
  getTermMarkingPeriods: Array<MarkingPeriodModel>;
  getTerms: TermPaginationModel;
  getUser: UserSchoolModel;
  getUserApps: Array<AppModel>;
  getUserAttendance: AttendanceUserObject;
  getUserGradeList: Array<Scalars['String']>;
  getUserParents: Array<UserSchoolModel>;
  getUsers: UserSchoolPaginationModel;
  me: UserModel;
  validateCleverAuth: MagicLoginModel;
  validateDistrictURL: ValidateDistrictAuthModel;
  validateGoogleAuth: MagicLoginModel;
  validateMagicLink: MagicLoginModel;
};


export type QueryGenerateAuthLinkArgs = {
  name: AppEnum;
};


export type QueryGetAllAssignmentsGradesArgs = {
  classId: Scalars['String'];
  markingPeriodId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  userStatus?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetAnecdotalArgs = {
  anecdotalId: Scalars['String'];
  schoolId: Scalars['String'];
};


export type QueryGetAnecdotalTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  limit?: Scalars['Int'];
  schoolId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetAnecdotalsArgs = {
  after?: InputMaybe<Scalars['String']>;
  anecdotalTypeIds?: InputMaybe<Array<Scalars['String']>>;
  before?: InputMaybe<Scalars['String']>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  from?: InputMaybe<Scalars['DateTime']>;
  limit?: Scalars['Int'];
  referToIds?: InputMaybe<Array<Scalars['String']>>;
  schoolId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  severities?: InputMaybe<Array<AnecdotalSeverityEnum>>;
  to?: InputMaybe<Scalars['DateTime']>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryGetAssignmentArgs = {
  assignmentId: Scalars['String'];
  schoolId: Scalars['String'];
};


export type QueryGetAssignmentSubmissionsArgs = {
  assignmentId: Scalars['String'];
};


export type QueryGetAssignmentsArgs = {
  categoryId?: InputMaybe<Scalars['String']>;
  classId: Scalars['String'];
  markingPeriodId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<AssignmentStatusEnum>;
  userStatus?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetAttendanceStatusesArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetClassArgs = {
  id: Scalars['String'];
  schoolId: Scalars['String'];
};


export type QueryGetClassAttendanceArgs = {
  classId: Scalars['String'];
  date: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetClassCleverUsersArgs = {
  classId: Scalars['String'];
};


export type QueryGetClassesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  limit?: Scalars['Int'];
  markingPeriodId?: InputMaybe<Scalars['String']>;
  schoolId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetClassesByUserArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  limit?: Scalars['Int'];
  markingPeriodId?: InputMaybe<Scalars['String']>;
  schoolId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};


export type QueryGetCleverClassesArgs = {
  schoolId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  status: ProviderStatusEnum;
};


export type QueryGetCleverUsersArgs = {
  schoolId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  status: ProviderStatusEnum;
};


export type QueryGetCommentsArgs = {
  schoolId: Scalars['String'];
};


export type QueryGetDistrictArgs = {
  id: Scalars['String'];
};


export type QueryGetGoogleAssignmentsArgs = {
  classId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  status: ProviderStatusEnum;
};


export type QueryGetGoogleClassesArgs = {
  classId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetGoogleUsersArgs = {
  classId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  status: ProviderStatusEnum;
};


export type QueryGetGradeExcuseArgs = {
  id: Scalars['String'];
  schoolId: Scalars['String'];
};


export type QueryGetGradeExcusesArgs = {
  schoolId: Scalars['String'];
};


export type QueryGetGradebookArgs = {
  id: Scalars['String'];
  schoolId: Scalars['String'];
};


export type QueryGetGradebooksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  limit?: Scalars['Int'];
  schoolId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetMarkingPeriodArgs = {
  id: Scalars['String'];
  schoolId: Scalars['String'];
};


export type QueryGetMarkingPeriodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  limit?: Scalars['Int'];
  schoolId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetParentStudentsArgs = {
  parentId: Scalars['String'];
  schoolId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetPublicIntegrationArgs = {
  schoolId: Scalars['String'];
  type: ProviderEnum;
};


export type QueryGetReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: Scalars['Int'];
  markingPeriodId?: InputMaybe<Scalars['String']>;
  schoolId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetSchoolArgs = {
  id: Scalars['String'];
};


export type QueryGetStudentAssignmentsArgs = {
  categoryId?: InputMaybe<Scalars['String']>;
  classId: Scalars['String'];
  markingPeriodId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetStudentGradesArgs = {
  schoolId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  termId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryGetStudentOverallArgs = {
  classId: Scalars['String'];
  markingPeriodId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryGetStudentSubmissionArgs = {
  assignmentId: Scalars['String'];
  schoolId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryGetSubmissionArgs = {
  id: Scalars['String'];
};


export type QueryGetSubmissionsArgs = {
  assignmentId: Scalars['String'];
};


export type QueryGetTagArgs = {
  id: Scalars['String'];
  schoolId: Scalars['String'];
};


export type QueryGetTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  limit?: Scalars['Int'];
  schoolId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetTermArgs = {
  id: Scalars['String'];
  schoolId: Scalars['String'];
};


export type QueryGetTermMarkingPeriodsArgs = {
  schoolId: Scalars['String'];
  termId: Scalars['String'];
};


export type QueryGetTermsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  limit?: Scalars['Int'];
  schoolId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserArgs = {
  id: Scalars['String'];
  schoolId: Scalars['String'];
};


export type QueryGetUserAttendanceArgs = {
  classId?: InputMaybe<Scalars['String']>;
  from: Scalars['String'];
  to: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryGetUserGradeListArgs = {
  schoolId: Scalars['String'];
};


export type QueryGetUserParentsArgs = {
  schoolId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryGetUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  grade?: InputMaybe<Scalars['String']>;
  limit?: Scalars['Int'];
  role?: InputMaybe<Array<RoleTypeEnum>>;
  schoolId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatuses>;
};


export type QueryMeArgs = {
  districtId: Scalars['String'];
};


export type QueryValidateCleverAuthArgs = {
  code: Scalars['String'];
};


export type QueryValidateDistrictUrlArgs = {
  url: Scalars['String'];
};


export type QueryValidateGoogleAuthArgs = {
  code: Scalars['String'];
};


export type QueryValidateMagicLinkArgs = {
  token: Scalars['String'];
};

export type ReportGradeInput = {
  classIds?: InputMaybe<Array<Scalars['String']>>;
  grades?: InputMaybe<Array<Scalars['String']>>;
  markingPeriodIds?: InputMaybe<Array<Scalars['String']>>;
  onlyActiveClass?: Scalars['Boolean'];
  schoolId: Scalars['String'];
  termId: Scalars['String'];
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ReportInput = {
  classIds?: InputMaybe<Array<Scalars['String']>>;
  grades?: InputMaybe<Array<Scalars['String']>>;
  markingPeriodIds: Array<Scalars['String']>;
  onlyActiveClass?: Scalars['Boolean'];
  schoolId: Scalars['String'];
  type: ReportTypeEnum;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ReportModel = {
  __typename?: 'ReportModel';
  createdAt: Scalars['DateTime'];
  createdBy: UserModel;
  file?: Maybe<FileModel>;
  id: Scalars['String'];
  markingPeriods?: Maybe<Array<MarkingPeriodModel>>;
  onlyActiveClass: Scalars['Boolean'];
  term?: Maybe<TermModel>;
  type: ReportTypeEnum;
};

export type ReportPaginationModel = {
  __typename?: 'ReportPaginationModel';
  data?: Maybe<Array<ReportModel>>;
  pagination: CursorPagination;
};

export enum ReportTypeEnum {
  StudentGrades = 'student_grades',
  StudentProgress = 'student_progress',
  StudentProgressShort = 'student_progress_short',
  StudentReportCard = 'student_report_card'
}

export type ResetOverallModel = {
  __typename?: 'ResetOverallModel';
  classOverall?: Maybe<Scalars['Float']>;
  userOverall?: Maybe<Scalars['Float']>;
};

export type ResetPasswordInput = {
  code: Scalars['String'];
  password: Scalars['String'];
};

export type ResetUserOverallInput = {
  classId: Scalars['String'];
  markingPeriodId: Scalars['String'];
  userId: Scalars['String'];
  userStatus?: InputMaybe<Scalars['Boolean']>;
};

export type RoleModel = {
  __typename?: 'RoleModel';
  id: Scalars['String'];
  name: Scalars['String'];
  type: RoleTypeEnum;
};

export enum RoleTypeEnum {
  Parent = 'parent',
  SchoolAdmin = 'schoolAdmin',
  Student = 'student',
  Teacher = 'teacher'
}

export type SchoolInput = {
  background?: InputMaybe<FileObjectInput>;
  defaultGradebookId?: InputMaybe<Scalars['String']>;
  defaultMarkingPeriodId?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<FileObjectInput>;
  name: Scalars['String'];
};

export type SchoolModel = {
  __typename?: 'SchoolModel';
  background?: Maybe<FileModel>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultGradebook?: Maybe<GradebookSettingModel>;
  defaultMarkingPeriod?: Maybe<MarkingPeriodModel>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  logo?: Maybe<FileModel>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<Array<UserSchoolModel>>;
  usersCount?: Maybe<Scalars['Int']>;
};

export type StudentAssignmentsListModel = {
  __typename?: 'StudentAssignmentsListModel';
  assignment: AssignmentModel;
  submission?: Maybe<SubmissionModel>;
  userPoints?: Maybe<Scalars['Float']>;
};

export type StudentGradeClassMarkingPeriodModel = {
  __typename?: 'StudentGradeClassMarkingPeriodModel';
  id: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type StudentGradeClassModel = {
  __typename?: 'StudentGradeClassModel';
  id: Scalars['String'];
  markingPeriods: Array<StudentGradeClassMarkingPeriodModel>;
  name: Scalars['String'];
  overall?: Maybe<Scalars['Float']>;
};

export type StudentGradesMarkingPeriodsModel = {
  __typename?: 'StudentGradesMarkingPeriodsModel';
  fromDate: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  toDate: Scalars['DateTime'];
};

export type StudentGradesModel = {
  __typename?: 'StudentGradesModel';
  classes: Array<StudentGradeClassModel>;
  markingPeriods: Array<StudentGradesMarkingPeriodsModel>;
};

export type SubmissionInput = {
  assignmentId: Scalars['String'];
  attachments: Array<FileObjectInput>;
};

export type SubmissionModel = {
  __typename?: 'SubmissionModel';
  attachments: Array<FileModel>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: ClassUserModel;
  id: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum SubmissionStatusEnum {
  Missing = 'Missing',
  NotSubmitted = 'NotSubmitted',
  Submitted = 'Submitted',
  SubmittedLate = 'SubmittedLate'
}

export type TagInput = {
  name: Scalars['String'];
};

export type TagModel = {
  __typename?: 'TagModel';
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  school: SchoolModel;
};

export type TagPaginationModel = {
  __typename?: 'TagPaginationModel';
  data?: Maybe<Array<TagModel>>;
  pagination: CursorPagination;
};

export type TermInput = {
  fromDate: Scalars['DateTime'];
  name: Scalars['String'];
  toDate: Scalars['DateTime'];
};

export type TermModel = {
  __typename?: 'TermModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  fromDate: Scalars['DateTime'];
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  markingPeriods?: Maybe<Array<MarkingPeriodModel>>;
  name: Scalars['String'];
  school?: Maybe<SchoolModel>;
  toDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TermPaginationModel = {
  __typename?: 'TermPaginationModel';
  data?: Maybe<Array<TermModel>>;
  pagination: CursorPagination;
};

export type UpdateBulkUserSchoolStatusInput = {
  schoolId: Scalars['String'];
  status: UserStatuses;
  userIds: Array<Scalars['String']>;
};

export type UpdateClassInput = {
  gradebookSettingId: Scalars['String'];
  markingPeriodIds: Array<Scalars['String']>;
  name: Scalars['String'];
  room?: InputMaybe<Scalars['String']>;
};

export type UpdateCommentInput = {
  comment?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CommentTypeEnum>;
};

export type UpdateMpClassInput = {
  classIds: Array<Scalars['String']>;
  markingPeriodId: Scalars['String'];
  schoolId: Scalars['String'];
  type: BulkMpClassUpdateEnum;
};

export type UpdateUserSchoolInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  grade?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<FileObjectInput>;
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  roleId: Scalars['String'];
  status: UserStatuses;
};

export type UploadFileInput = {
  __typename?: 'UploadFileInput';
  name: Scalars['String'];
  size: Scalars['Float'];
  url: Scalars['String'];
};

export type UserAccessModel = {
  __typename?: 'UserAccessModel';
  role: RoleModel;
  school: SchoolModel;
  status: UserStatuses;
};

export type UserGrade = {
  __typename?: 'UserGrade';
  assignmentId: Scalars['String'];
  draft?: Maybe<Scalars['Float']>;
  gradeExcuse?: Maybe<GradeExcuseModel>;
  latestAction?: Maybe<GradeActionEnum>;
  returned?: Maybe<Scalars['Float']>;
  userId: Scalars['String'];
};

export type UserModel = {
  __typename?: 'UserModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  grade?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<FileModel>;
  isDeleted: Scalars['Boolean'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  schoolAccess: Array<UserAccessModel>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserSchoolInput = {
  attachParentToUserId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  grade?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<FileObjectInput>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  roleId: Scalars['String'];
  status: UserStatuses;
};

export type UserSchoolModel = {
  __typename?: 'UserSchoolModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  grade?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<FileModel>;
  isDeleted: Scalars['Boolean'];
  lastName: Scalars['String'];
  parents?: Maybe<Array<ParentModel>>;
  phoneNumber?: Maybe<Scalars['String']>;
  role: RoleModel;
  status: UserStatuses;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserSchoolPaginationModel = {
  __typename?: 'UserSchoolPaginationModel';
  data?: Maybe<Array<UserSchoolModel>>;
  pagination: CursorPagination;
};

export enum UserStatuses {
  Active = 'active',
  Deactivated = 'deactivated',
  Deleted = 'deleted',
  Ignored = 'ignored',
  Pending = 'pending'
}

export type ValidateAuthTokenInput = {
  districtId: Scalars['String'];
  token: Scalars['String'];
};

export type ValidateDistrictAuthModel = {
  __typename?: 'ValidateDistrictAuthModel';
  background?: Maybe<FileModel>;
  favicon?: Maybe<FileModel>;
  id?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  logo?: Maybe<FileModel>;
  name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type TableAnecdotalFieldsFragment = { __typename?: 'AnecdotalModel', id: string, text: string, severity: AnecdotalSeverityEnum, createdAt?: any | null, user: { __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }, anecdotalType: { __typename?: 'AnecdotalTypeModel', id: string, name: string, isDeleted: boolean }, referTo?: Array<{ __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }> | null, createdBy: { __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } };

export type SingleAnecdotalFieldsFragment = { __typename?: 'AnecdotalModel', isNotifyParent: boolean, isNotifyStudent: boolean, isVisibleParent: boolean, isVisibleStudent: boolean, id: string, text: string, severity: AnecdotalSeverityEnum, createdAt?: any | null, user: { __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }, anecdotalType: { __typename?: 'AnecdotalTypeModel', id: string, name: string, isDeleted: boolean }, referTo?: Array<{ __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }> | null, createdBy: { __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } };

export type CreateAnecdotalMutationVariables = Exact<{
  data: AnecdotalInput;
}>;


export type CreateAnecdotalMutation = { __typename?: 'Mutation', createAnecdotal: boolean };

export type UpdateAnecdotalMutationVariables = Exact<{
  id: Scalars['String'];
  data: AnecdotalUpdateInput;
}>;


export type UpdateAnecdotalMutation = { __typename?: 'Mutation', updateAnecdotal: { __typename?: 'AnecdotalModel', isNotifyParent: boolean, isNotifyStudent: boolean, isVisibleParent: boolean, isVisibleStudent: boolean, id: string, text: string, severity: AnecdotalSeverityEnum, createdAt?: any | null, user: { __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }, anecdotalType: { __typename?: 'AnecdotalTypeModel', id: string, name: string, isDeleted: boolean }, referTo?: Array<{ __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }> | null, createdBy: { __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } } };

export type RemoveAnecdotalsMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type RemoveAnecdotalsMutation = { __typename?: 'Mutation', removeAnecdotals: boolean };

export type GetAnecdotalsQueryVariables = Exact<{
  schoolId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
  userIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  referToIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  createdByIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  anecdotalTypeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  severities?: InputMaybe<Array<AnecdotalSeverityEnum> | AnecdotalSeverityEnum>;
}>;


export type GetAnecdotalsQuery = { __typename?: 'Query', getAnecdotals: { __typename?: 'AnecdotalPaginationModel', pagination: { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number }, data?: Array<{ __typename?: 'AnecdotalModel', id: string, text: string, severity: AnecdotalSeverityEnum, createdAt?: any | null, user: { __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }, anecdotalType: { __typename?: 'AnecdotalTypeModel', id: string, name: string, isDeleted: boolean }, referTo?: Array<{ __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }> | null, createdBy: { __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } }> | null } };

export type GetAnecdotalQueryVariables = Exact<{
  schoolId: Scalars['String'];
  anecdotalId: Scalars['String'];
}>;


export type GetAnecdotalQuery = { __typename?: 'Query', getAnecdotal: { __typename?: 'AnecdotalModel', isNotifyParent: boolean, isNotifyStudent: boolean, isVisibleParent: boolean, isVisibleStudent: boolean, id: string, text: string, severity: AnecdotalSeverityEnum, createdAt?: any | null, user: { __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }, anecdotalType: { __typename?: 'AnecdotalTypeModel', id: string, name: string, isDeleted: boolean }, referTo?: Array<{ __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }> | null, createdBy: { __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } } };

export type SingleTypeAnecdotalFieldsFragment = { __typename?: 'AnecdotalTypeModel', id: string, name: string, isDeleted: boolean, school?: { __typename?: 'SchoolModel', id: string, name: string } | null };

export type CreateAnecdotalTypeMutationVariables = Exact<{
  data: AnecdotalTypeInput;
}>;


export type CreateAnecdotalTypeMutation = { __typename?: 'Mutation', createAnecdotalType: boolean };

export type RemoveAnecdotalTypesMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type RemoveAnecdotalTypesMutation = { __typename?: 'Mutation', removeAnecdotalTypes: boolean };

export type ReactivateAnecdotalTypesMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type ReactivateAnecdotalTypesMutation = { __typename?: 'Mutation', reactivateAnecdotalTypes: boolean };

export type GetAnecdotalTypesQueryVariables = Exact<{
  schoolId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetAnecdotalTypesQuery = { __typename?: 'Query', getAnecdotalTypes: { __typename?: 'AnecdotalTypePaginationModel', pagination: { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number }, data?: Array<{ __typename?: 'AnecdotalTypeModel', id: string, name: string, isDeleted: boolean, school?: { __typename?: 'SchoolModel', id: string, name: string } | null }> | null } };

export type TableTeacherAssignmentFieldsFragment = { __typename?: 'AssignmentModel', id: string, name: string, status: AssignmentStatusEnum, dueDate?: any | null, submissionCount?: number | null, submissionExpected?: number | null, averageGrade?: number | null, points: number, category: { __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string } };

export type SingleTeacherAssignmentFieldsFragment = { __typename?: 'AssignmentModel', description?: string | null, isSubmittable: boolean, id: string, name: string, status: AssignmentStatusEnum, dueDate?: any | null, submissionCount?: number | null, submissionExpected?: number | null, averageGrade?: number | null, points: number, class: { __typename?: 'ClassModel', id: string, name: string }, markingPeriod: { __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } }, attachments: Array<{ __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null }>, users: Array<{ __typename?: 'ClassUserModel', isDeleted: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } }>, category: { __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string } };

export type TableStudentAssignmentFieldsFragment = { __typename?: 'AssignmentModel', id: string, name: string, dueDate?: any | null, points: number, category: { __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string } };

export type SingleStudentAssignmentFieldsFragment = { __typename?: 'AssignmentModel', id: string, name: string, dueDate?: any | null, points: number, description?: string | null, isSubmittable: boolean, category: { __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string }, class: { __typename?: 'ClassModel', id: string, name: string }, markingPeriod: { __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } }, attachments: Array<{ __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null }> };

export type CreateAssignmentMutationVariables = Exact<{
  data: BulkAssignmentInput;
}>;


export type CreateAssignmentMutation = { __typename?: 'Mutation', createAssignment: boolean };

export type UpdateAssignmentMutationVariables = Exact<{
  id: Scalars['String'];
  data: AssignmentInput;
}>;


export type UpdateAssignmentMutation = { __typename?: 'Mutation', updateAssignment: { __typename?: 'AssignmentModel', description?: string | null, isSubmittable: boolean, id: string, name: string, status: AssignmentStatusEnum, dueDate?: any | null, submissionCount?: number | null, submissionExpected?: number | null, averageGrade?: number | null, points: number, class: { __typename?: 'ClassModel', id: string, name: string }, markingPeriod: { __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } }, attachments: Array<{ __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null }>, users: Array<{ __typename?: 'ClassUserModel', isDeleted: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } }>, category: { __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string } } };

export type BulkAssignmentStatusUpdateMutationVariables = Exact<{
  data: MultipleIdsInput;
  status: AssignmentStatusEnum;
}>;


export type BulkAssignmentStatusUpdateMutation = { __typename?: 'Mutation', bulkAssignmentStatusUpdate: boolean };

export type GetAssignmentsQueryVariables = Exact<{
  classId: Scalars['String'];
  markingPeriodId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<AssignmentStatusEnum>;
  categoryId?: InputMaybe<Scalars['String']>;
  userStatus?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetAssignmentsQuery = { __typename?: 'Query', getAssignments: Array<{ __typename?: 'AssignmentModel', id: string, name: string, status: AssignmentStatusEnum, dueDate?: any | null, submissionCount?: number | null, submissionExpected?: number | null, averageGrade?: number | null, points: number, category: { __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string } }> };

export type GetAssignmentQueryVariables = Exact<{
  schoolId: Scalars['String'];
  id: Scalars['String'];
}>;


export type GetAssignmentQuery = { __typename?: 'Query', getAssignment: { __typename?: 'AssignmentModel', description?: string | null, isSubmittable: boolean, id: string, name: string, status: AssignmentStatusEnum, dueDate?: any | null, submissionCount?: number | null, submissionExpected?: number | null, averageGrade?: number | null, points: number, class: { __typename?: 'ClassModel', id: string, name: string }, markingPeriod: { __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } }, attachments: Array<{ __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null }>, users: Array<{ __typename?: 'ClassUserModel', isDeleted: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } }>, category: { __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string } } };

export type GetStudentAssignmentsQueryVariables = Exact<{
  classId: Scalars['String'];
  userId: Scalars['String'];
  markingPeriodId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
}>;


export type GetStudentAssignmentsQuery = { __typename?: 'Query', getStudentAssignments: Array<{ __typename?: 'StudentAssignmentsListModel', userPoints?: number | null, assignment: { __typename?: 'AssignmentModel', id: string, name: string, dueDate?: any | null, points: number, category: { __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string } }, submission?: { __typename?: 'SubmissionModel', id: string, createdAt?: any | null, updatedAt?: any | null } | null }> };

export type AttendanceStatusFieldsFragment = { __typename?: 'AttendanceStatusObject', id: string, name: string, description?: string | null, shortName?: string | null, color: string, isDeleted: boolean };

export type GetAttendanceStatusesQueryVariables = Exact<{
  deleted?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetAttendanceStatusesQuery = { __typename?: 'Query', getAttendanceStatuses: Array<{ __typename?: 'AttendanceStatusObject', id: string, name: string, description?: string | null, shortName?: string | null, color: string, isDeleted: boolean }> };

export type ValidateMagicLinkFieldsFragment = { __typename?: 'MagicLoginModel', email: string, code?: string | null, districts: Array<{ __typename?: 'DistrictModel', id: string, name: string, url: string, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null }> };

export type SendMagicLinkMutationVariables = Exact<{
  data: MagicLoginInput;
}>;


export type SendMagicLinkMutation = { __typename?: 'Mutation', sendMagicLink: boolean };

export type DistrictAuthMutationVariables = Exact<{
  data: DistrictLoginInput;
}>;


export type DistrictAuthMutation = { __typename?: 'Mutation', districtAuth: { __typename?: 'LoginModel', token: string } };

export type MagicAuthMutationVariables = Exact<{
  data: ValidateAuthTokenInput;
}>;


export type MagicAuthMutation = { __typename?: 'Mutation', magicAuth: { __typename?: 'LoginModel', token: string } };

export type ExternalAuthMutationVariables = Exact<{
  data: GoogleAuthTokenInput;
}>;


export type ExternalAuthMutation = { __typename?: 'Mutation', externalAuth: { __typename?: 'LoginModel', token: string } };

export type ForgottenPasswordMutationVariables = Exact<{
  data: ForgotPasswordInput;
}>;


export type ForgottenPasswordMutation = { __typename?: 'Mutation', forgotPassword: boolean };

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type GetGoogleAuthLinkQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGoogleAuthLinkQuery = { __typename?: 'Query', getGoogleAuthLink: string };

export type GetCleverAuthLinkQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCleverAuthLinkQuery = { __typename?: 'Query', getCleverAuthLink: string };

export type ValidateGoogleAuthQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type ValidateGoogleAuthQuery = { __typename?: 'Query', validateGoogleAuth: { __typename?: 'MagicLoginModel', email: string, code?: string | null, districts: Array<{ __typename?: 'DistrictModel', id: string, name: string, url: string, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null }> } };

export type ValidateCleverAuthQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type ValidateCleverAuthQuery = { __typename?: 'Query', validateCleverAuth: { __typename?: 'MagicLoginModel', email: string, code?: string | null, districts: Array<{ __typename?: 'DistrictModel', id: string, name: string, url: string, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null }> } };

export type ValidateMagicLinkQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type ValidateMagicLinkQuery = { __typename?: 'Query', validateMagicLink: { __typename?: 'MagicLoginModel', email: string, code?: string | null, districts: Array<{ __typename?: 'DistrictModel', id: string, name: string, url: string, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null }> } };

export type MeQueryVariables = Exact<{
  districtId: Scalars['String'];
}>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'UserModel', id: string, firstName: string, lastName: string, email: string, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, schoolAccess: Array<{ __typename?: 'UserAccessModel', status: UserStatuses, school: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, defaultMarkingPeriod?: { __typename?: 'MarkingPeriodModel', id: string, name: string, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any } } | null, defaultGradebook?: { __typename?: 'GradebookSettingModel', id: string, name: string } | null }, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }> } };

export type ClassAttendanceFieldsFragment = { __typename?: 'AttendanceClassObject', attendanceStatus?: { __typename?: 'AttendanceStatusObject', id: string, name: string, shortName?: string | null, color: string } | null, user: { __typename?: 'ClassUserModel', isDeleted: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } } };

export type SaveClassAttendanceMutationVariables = Exact<{
  data: AttendanceClassInput;
}>;


export type SaveClassAttendanceMutation = { __typename?: 'Mutation', saveClassAttendance: Array<{ __typename?: 'AttendanceClassObject', attendanceStatus?: { __typename?: 'AttendanceStatusObject', id: string, name: string, shortName?: string | null, color: string } | null, user: { __typename?: 'ClassUserModel', isDeleted: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } } }> };

export type GetClassAttendanceQueryVariables = Exact<{
  classId: Scalars['String'];
  date: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetClassAttendanceQuery = { __typename?: 'Query', getClassAttendance: Array<{ __typename?: 'AttendanceClassObject', attendanceStatus?: { __typename?: 'AttendanceStatusObject', id: string, name: string, shortName?: string | null, color: string } | null, user: { __typename?: 'ClassUserModel', isDeleted: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } } }> };

export type ClassUserFieldsFragment = { __typename?: 'ClassUserModel', isDeleted: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } };

export type ExtendedClassUserFieldsFragment = { __typename?: 'ClassUserModel', isDeleted: boolean, deactivatedInSync: boolean, userFromCleverSync: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } };

export type ClassNameFieldsFragment = { __typename?: 'ClassModel', id: string, name: string };

export type TableClassFieldsFragment = { __typename?: 'ClassModel', id: string, name: string, room?: string | null, isDeleted: boolean, users: Array<{ __typename?: 'ClassUserModel', isDeleted: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } }>, googleData: { __typename?: 'ClassGoogleModel', id?: string | null, name?: string | null, connected: boolean, users: boolean, assignments: boolean } };

export type SingleClassFieldsFragment = { __typename?: 'ClassModel', id: string, name: string, room?: string | null, isDeleted: boolean, cleverId?: string | null, users: Array<{ __typename?: 'ClassUserModel', isDeleted: boolean, deactivatedInSync: boolean, userFromCleverSync: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } }>, gradebookSetting: { __typename?: 'GradebookSettingModel', id: string, name: string, isDeleted: boolean, type: GradebookSettingTypeEnum, values: Array<{ __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string }> }, markingPeriods: Array<{ __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } }>, googleData: { __typename?: 'ClassGoogleModel', id?: string | null, name?: string | null, connected: boolean, users: boolean, assignments: boolean } };

export type CreateClassMutationVariables = Exact<{
  schoolId: Scalars['String'];
  data: ClassInput;
}>;


export type CreateClassMutation = { __typename?: 'Mutation', createClass: { __typename?: 'ClassModel', id: string, name: string, room?: string | null, isDeleted: boolean, cleverId?: string | null, users: Array<{ __typename?: 'ClassUserModel', isDeleted: boolean, deactivatedInSync: boolean, userFromCleverSync: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } }>, gradebookSetting: { __typename?: 'GradebookSettingModel', id: string, name: string, isDeleted: boolean, type: GradebookSettingTypeEnum, values: Array<{ __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string }> }, markingPeriods: Array<{ __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } }>, googleData: { __typename?: 'ClassGoogleModel', id?: string | null, name?: string | null, connected: boolean, users: boolean, assignments: boolean } } };

export type UpdateClassMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateClassInput;
}>;


export type UpdateClassMutation = { __typename?: 'Mutation', updateClass: { __typename?: 'ClassModel', id: string, name: string, room?: string | null, isDeleted: boolean, cleverId?: string | null, users: Array<{ __typename?: 'ClassUserModel', isDeleted: boolean, deactivatedInSync: boolean, userFromCleverSync: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } }>, gradebookSetting: { __typename?: 'GradebookSettingModel', id: string, name: string, isDeleted: boolean, type: GradebookSettingTypeEnum, values: Array<{ __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string }> }, markingPeriods: Array<{ __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } }>, googleData: { __typename?: 'ClassGoogleModel', id?: string | null, name?: string | null, connected: boolean, users: boolean, assignments: boolean } } };

export type ReactivateClassesMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type ReactivateClassesMutation = { __typename?: 'Mutation', reactivateClasses: boolean };

export type RemoveClassesMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type RemoveClassesMutation = { __typename?: 'Mutation', removeClasses: boolean };

export type ToggleClassMarkingPeriodsMutationVariables = Exact<{
  data: UpdateMpClassInput;
}>;


export type ToggleClassMarkingPeriodsMutation = { __typename?: 'Mutation', toggleClassMarkingPeriods: Array<{ __typename?: 'ClassModel', id: string, markingPeriods: Array<{ __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } }> }> };

export type AddUsersToClassMutationVariables = Exact<{
  id: Scalars['String'];
  data: MultipleIdsInput;
}>;


export type AddUsersToClassMutation = { __typename?: 'Mutation', addUsersToClass: { __typename?: 'ClassModel', id: string, name: string, room?: string | null, isDeleted: boolean, cleverId?: string | null, users: Array<{ __typename?: 'ClassUserModel', isDeleted: boolean, deactivatedInSync: boolean, userFromCleverSync: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } }>, gradebookSetting: { __typename?: 'GradebookSettingModel', id: string, name: string, isDeleted: boolean, type: GradebookSettingTypeEnum, values: Array<{ __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string }> }, markingPeriods: Array<{ __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } }>, googleData: { __typename?: 'ClassGoogleModel', id?: string | null, name?: string | null, connected: boolean, users: boolean, assignments: boolean } } };

export type RemoveUsersFromClassMutationVariables = Exact<{
  id: Scalars['String'];
  data: MultipleIdsInput;
}>;


export type RemoveUsersFromClassMutation = { __typename?: 'Mutation', removeUsersFromClass: { __typename?: 'ClassModel', id: string, name: string, room?: string | null, isDeleted: boolean, cleverId?: string | null, users: Array<{ __typename?: 'ClassUserModel', isDeleted: boolean, deactivatedInSync: boolean, userFromCleverSync: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } }>, gradebookSetting: { __typename?: 'GradebookSettingModel', id: string, name: string, isDeleted: boolean, type: GradebookSettingTypeEnum, values: Array<{ __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string }> }, markingPeriods: Array<{ __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } }>, googleData: { __typename?: 'ClassGoogleModel', id?: string | null, name?: string | null, connected: boolean, users: boolean, assignments: boolean } } };

export type ReconnectUserSyncInClassMutationVariables = Exact<{
  id: Scalars['String'];
  data: MultipleIdsInput;
}>;


export type ReconnectUserSyncInClassMutation = { __typename?: 'Mutation', reconnectUserSyncInClass: { __typename?: 'ClassModel', id: string, name: string, room?: string | null, isDeleted: boolean, cleverId?: string | null, users: Array<{ __typename?: 'ClassUserModel', isDeleted: boolean, deactivatedInSync: boolean, userFromCleverSync: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } }>, gradebookSetting: { __typename?: 'GradebookSettingModel', id: string, name: string, isDeleted: boolean, type: GradebookSettingTypeEnum, values: Array<{ __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string }> }, markingPeriods: Array<{ __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } }>, googleData: { __typename?: 'ClassGoogleModel', id?: string | null, name?: string | null, connected: boolean, users: boolean, assignments: boolean } } };

export type GetClassesQueryVariables = Exact<{
  schoolId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  markingPeriodId?: InputMaybe<Scalars['String']>;
}>;


export type GetClassesQuery = { __typename?: 'Query', getClasses: { __typename?: 'ClassPaginationModel', pagination: { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number }, data?: Array<{ __typename?: 'ClassModel', id: string, name: string, room?: string | null, isDeleted: boolean, users: Array<{ __typename?: 'ClassUserModel', isDeleted: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } }>, googleData: { __typename?: 'ClassGoogleModel', id?: string | null, name?: string | null, connected: boolean, users: boolean, assignments: boolean } }> | null } };

export type GetClassesSettingsQueryVariables = Exact<{
  schoolId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetClassesSettingsQuery = { __typename?: 'Query', getClasses: { __typename?: 'ClassPaginationModel', pagination: { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number }, data?: Array<{ __typename?: 'ClassModel', id: string, name: string, markingPeriods: Array<{ __typename?: 'MarkingPeriodModel', id: string, name: string }> }> | null } };

export type GetClassesByUserQueryVariables = Exact<{
  schoolId: Scalars['String'];
  userId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  markingPeriodId?: InputMaybe<Scalars['String']>;
}>;


export type GetClassesByUserQuery = { __typename?: 'Query', getClassesByUser: { __typename?: 'ClassPaginationModel', pagination: { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number }, data?: Array<{ __typename?: 'ClassModel', id: string, name: string, room?: string | null, isDeleted: boolean, users: Array<{ __typename?: 'ClassUserModel', isDeleted: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } }>, googleData: { __typename?: 'ClassGoogleModel', id?: string | null, name?: string | null, connected: boolean, users: boolean, assignments: boolean } }> | null } };

export type GetClassQueryVariables = Exact<{
  schoolId: Scalars['String'];
  id: Scalars['String'];
}>;


export type GetClassQuery = { __typename?: 'Query', getClass: { __typename?: 'ClassModel', id: string, name: string, room?: string | null, isDeleted: boolean, cleverId?: string | null, users: Array<{ __typename?: 'ClassUserModel', isDeleted: boolean, deactivatedInSync: boolean, userFromCleverSync: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } }>, gradebookSetting: { __typename?: 'GradebookSettingModel', id: string, name: string, isDeleted: boolean, type: GradebookSettingTypeEnum, values: Array<{ __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string }> }, markingPeriods: Array<{ __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } }>, googleData: { __typename?: 'ClassGoogleModel', id?: string | null, name?: string | null, connected: boolean, users: boolean, assignments: boolean } } };

export type ImportGradesFieldsFragment = { __typename?: 'ImportAssignmentsGradesObject', overall?: number | null, user?: { __typename?: 'ClassUserModel', isDeleted: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } } | null, assignments?: Array<{ __typename?: 'ImportAssignmentObject', points?: number | null, assignment?: { __typename?: 'AssignmentModel', id: string, name: string, points: number } | null }> | null, errors?: Array<{ __typename?: 'ImportErrorObject', error: string, key: string }> | null };

export type ImportParseAssignmentGradesMutationVariables = Exact<{
  classId: Scalars['String'];
  link: Scalars['String'];
}>;


export type ImportParseAssignmentGradesMutation = { __typename?: 'Mutation', importParseAssignmentGrades: Array<{ __typename?: 'ImportAssignmentsGradesObject', overall?: number | null, user?: { __typename?: 'ClassUserModel', isDeleted: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } } | null, assignments?: Array<{ __typename?: 'ImportAssignmentObject', points?: number | null, assignment?: { __typename?: 'AssignmentModel', id: string, name: string, points: number } | null }> | null, errors?: Array<{ __typename?: 'ImportErrorObject', error: string, key: string }> | null }> };

export type ImportStoreAssignmentGradesMutationVariables = Exact<{
  classId: Scalars['String'];
  markingPeriodId: Scalars['String'];
  data: Array<ImportAssignmentsGradesInput> | ImportAssignmentsGradesInput;
  skipNotValid?: Scalars['Boolean'];
}>;


export type ImportStoreAssignmentGradesMutation = { __typename?: 'Mutation', importStoreAssignmentGrades?: Array<{ __typename?: 'ImportAssignmentsGradesObject', overall?: number | null, user?: { __typename?: 'ClassUserModel', isDeleted: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } } | null, assignments?: Array<{ __typename?: 'ImportAssignmentObject', points?: number | null, assignment?: { __typename?: 'AssignmentModel', id: string, name: string, points: number } | null }> | null, errors?: Array<{ __typename?: 'ImportErrorObject', error: string, key: string }> | null }> | null };

export type CleverPracticeClassFieldsFragment = { __typename?: 'ClassModel', id: string, name: string, isDeleted: boolean, markingPeriods: Array<{ __typename?: 'MarkingPeriodModel', id: string, name: string }> };

export type CleverClassFieldsFragment = { __typename?: 'CleverClassModel', name: string, termId: string, teachers: Array<string>, students: Array<string>, cleverId: string, sectionNumber?: string | null, sectionGrade?: string | null, courseNumber?: string | null, practiceStatus: ProviderStatusEnum, practiceClass?: { __typename?: 'ClassModel', id: string, name: string, isDeleted: boolean, markingPeriods: Array<{ __typename?: 'MarkingPeriodModel', id: string, name: string }> } | null };

export type StoreCleverClassesMutationVariables = Exact<{
  data: CleverClassesInput;
}>;


export type StoreCleverClassesMutation = { __typename?: 'Mutation', storeCleverClasses: boolean };

export type UnSyncCleverClassMutationVariables = Exact<{
  classId: Scalars['String'];
  removeData?: InputMaybe<Scalars['Boolean']>;
}>;


export type UnSyncCleverClassMutation = { __typename?: 'Mutation', unSyncCleverClass: boolean };

export type GetCleverClassesQueryVariables = Exact<{
  schoolId: Scalars['String'];
  status: ProviderStatusEnum;
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetCleverClassesQuery = { __typename?: 'Query', getCleverClasses: Array<{ __typename?: 'CleverClassModel', name: string, termId: string, teachers: Array<string>, students: Array<string>, cleverId: string, sectionNumber?: string | null, sectionGrade?: string | null, courseNumber?: string | null, practiceStatus: ProviderStatusEnum, practiceClass?: { __typename?: 'ClassModel', id: string, name: string, isDeleted: boolean, markingPeriods: Array<{ __typename?: 'MarkingPeriodModel', id: string, name: string }> } | null }> };

export type CleverPracticeUserFieldsFragment = { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, grade?: string | null, isDeleted: boolean, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } };

export type CleverUserFieldsFragment = { __typename?: 'CleverUserModel', cleverId: string, firstName: string, lastName: string, email: string, grade?: string | null, roleId: string, studentNumber?: string | null, practiceStatus: ProviderStatusEnum, practiceUser?: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, grade?: string | null, isDeleted: boolean, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } | null };

export type StoreCleverUsersMutationVariables = Exact<{
  data: CleverUsersInput;
}>;


export type StoreCleverUsersMutation = { __typename?: 'Mutation', storeCleverUsers: boolean };

export type GetCleverUsersQueryVariables = Exact<{
  schoolId: Scalars['String'];
  status: ProviderStatusEnum;
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetCleverUsersQuery = { __typename?: 'Query', getCleverUsers: Array<{ __typename?: 'CleverUserModel', cleverId: string, firstName: string, lastName: string, email: string, grade?: string | null, roleId: string, studentNumber?: string | null, practiceStatus: ProviderStatusEnum, practiceUser?: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, grade?: string | null, isDeleted: boolean, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } | null }> };

export type GetClassCleverUsersQueryVariables = Exact<{
  classId: Scalars['String'];
}>;


export type GetClassCleverUsersQuery = { __typename?: 'Query', getClassCleverUsers: Array<{ __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, grade?: string | null, isDeleted: boolean, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }> };

export type ValidateDistrictUrlFieldsFragment = { __typename?: 'ValidateDistrictAuthModel', id?: string | null, name?: string | null, isDeleted: boolean, timezone?: string | null, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, favicon?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null };

export type DistrictAuthFieldsFragment = { __typename?: 'DistrictModel', id: string, name: string, url: string, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null };

export type DistrictFieldsFragment = { __typename?: 'DistrictModel', id: string, name: string, url: string, isDeleted: boolean, timezone: string, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, favicon?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null };

export type ValidateDistrictUrlQueryVariables = Exact<{
  url: Scalars['String'];
}>;


export type ValidateDistrictUrlQuery = { __typename?: 'Query', validateDistrictURL: { __typename?: 'ValidateDistrictAuthModel', id?: string | null, name?: string | null, isDeleted: boolean, timezone?: string | null, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, favicon?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null } };

export type GetDistrictQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetDistrictQuery = { __typename?: 'Query', getDistrict: { __typename?: 'DistrictModel', id: string, name: string, url: string, isDeleted: boolean, timezone: string, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, favicon?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null } };

export type ExcusedGradeFieldsFragment = { __typename?: 'GradeExcuseModel', id: string, name: string };

export type CreateGradeExcuseMutationVariables = Exact<{
  schoolId: Scalars['String'];
  name: Scalars['String'];
}>;


export type CreateGradeExcuseMutation = { __typename?: 'Mutation', createGradeExcuse: { __typename?: 'GradeExcuseModel', id: string, name: string } };

export type UpdateGradeExcuseMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
}>;


export type UpdateGradeExcuseMutation = { __typename?: 'Mutation', updateGradeExcuse: { __typename?: 'GradeExcuseModel', id: string, name: string } };

export type RemoveGradeExcusesMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type RemoveGradeExcusesMutation = { __typename?: 'Mutation', removeGradeExcuses: boolean };

export type GetGradeExcusesQueryVariables = Exact<{
  schoolId: Scalars['String'];
}>;


export type GetGradeExcusesQuery = { __typename?: 'Query', getGradeExcuses: Array<{ __typename?: 'GradeExcuseModel', id: string, name: string }> };

export type GetGradeExcuseQueryVariables = Exact<{
  schoolId: Scalars['String'];
  id: Scalars['String'];
}>;


export type GetGradeExcuseQuery = { __typename?: 'Query', getGradeExcuse: { __typename?: 'GradeExcuseModel', id: string, name: string } };

export type GooglePracticeAssignmentFieldsFragment = { __typename?: 'AssignmentModel', id: string, name: string, category: { __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string }, markingPeriod: { __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } } };

export type GoogleAssignmentFieldsFragment = { __typename?: 'GoogleClassroomAssignmentModel', googleId: string, name: string, assignees: Array<string>, description: string, dueDate?: any | null, isSubmittable: boolean, points: number, status: AssignmentStatusEnum, practiceStatus: ProviderStatusEnum, attachments: Array<{ __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null }>, practiceAssignment?: { __typename?: 'AssignmentModel', id: string, name: string, category: { __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string }, markingPeriod: { __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } } } | null };

export type StoreGoogleAssignmentsMutationVariables = Exact<{
  data: GoogleAssignmentsInput;
}>;


export type StoreGoogleAssignmentsMutation = { __typename?: 'Mutation', storeGoogleAssignments: boolean };

export type ReSyncGoogleAssignmentsMutationVariables = Exact<{
  classId: Scalars['String'];
}>;


export type ReSyncGoogleAssignmentsMutation = { __typename?: 'Mutation', reSyncGoogleAssignments: boolean };

export type UnSyncGoogleAssignmentsMutationVariables = Exact<{
  classId: Scalars['String'];
  removeData?: InputMaybe<Scalars['Boolean']>;
}>;


export type UnSyncGoogleAssignmentsMutation = { __typename?: 'Mutation', unSyncGoogleAssignments: boolean };

export type GetGoogleAssignmentsQueryVariables = Exact<{
  classId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  status: ProviderStatusEnum;
}>;


export type GetGoogleAssignmentsQuery = { __typename?: 'Query', getGoogleAssignments: Array<{ __typename?: 'GoogleClassroomAssignmentModel', googleId: string, name: string, assignees: Array<string>, description: string, dueDate?: any | null, isSubmittable: boolean, points: number, status: AssignmentStatusEnum, practiceStatus: ProviderStatusEnum, attachments: Array<{ __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null }>, practiceAssignment?: { __typename?: 'AssignmentModel', id: string, name: string, category: { __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string }, markingPeriod: { __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } } } | null }> };

export type GetGooglePracticeAssignmentsQueryVariables = Exact<{
  classId: Scalars['String'];
}>;


export type GetGooglePracticeAssignmentsQuery = { __typename?: 'Query', getAssignments: Array<{ __typename?: 'AssignmentModel', id: string, name: string, category: { __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string }, markingPeriod: { __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } } }> };

export type GoogleClassFieldsFragment = { __typename?: 'GoogleClassroomClassModel', name: string, googleId: string };

export type SyncGoogleClassMutationVariables = Exact<{
  classId: Scalars['String'];
  googleClassId: Scalars['String'];
  googleClassName: Scalars['String'];
}>;


export type SyncGoogleClassMutation = { __typename?: 'Mutation', syncGoogleClass: boolean };

export type UnSyncGoogleClassMutationVariables = Exact<{
  classId: Scalars['String'];
  removeData?: InputMaybe<Scalars['Boolean']>;
}>;


export type UnSyncGoogleClassMutation = { __typename?: 'Mutation', unSyncGoogleClass: boolean };

export type GetGoogleClassesQueryVariables = Exact<{
  classId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetGoogleClassesQuery = { __typename?: 'Query', getGoogleClasses: Array<{ __typename?: 'GoogleClassroomClassModel', name: string, googleId: string }> };

export type GooglePracticeUserFieldsFragment = { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, isDeleted: boolean, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } };

export type GoogleUserFieldsFragment = { __typename?: 'GoogleClassroomUserModel', googleId: string, firstName: string, lastName: string, email: string, roleId: string, practiceStatus: ProviderStatusEnum, practiceUser?: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, isDeleted: boolean, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } | null };

export type StoreGoogleUsersMutationVariables = Exact<{
  data: GoogleUsersInput;
}>;


export type StoreGoogleUsersMutation = { __typename?: 'Mutation', storeGoogleUsers: boolean };

export type UnSyncGoogleUsersMutationVariables = Exact<{
  classId: Scalars['String'];
  removeData?: InputMaybe<Scalars['Boolean']>;
}>;


export type UnSyncGoogleUsersMutation = { __typename?: 'Mutation', unSyncGoogleUsers: boolean };

export type GetGoogleUsersQueryVariables = Exact<{
  classId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  status: ProviderStatusEnum;
}>;


export type GetGoogleUsersQuery = { __typename?: 'Query', getGoogleUsers: Array<{ __typename?: 'GoogleClassroomUserModel', googleId: string, firstName: string, lastName: string, email: string, roleId: string, practiceStatus: ProviderStatusEnum, practiceUser?: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, isDeleted: boolean, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } | null }> };

export type GradebookCategoryFieldsFragment = { __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string };

export type TableGradebookFieldsFragment = { __typename?: 'GradebookSettingModel', id: string, name: string, isDeleted: boolean, type: GradebookSettingTypeEnum };

export type SingleGradebookFieldsFragment = { __typename?: 'GradebookSettingModel', id: string, name: string, isDeleted: boolean, type: GradebookSettingTypeEnum, values: Array<{ __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string }> };

export type CreateGradebookMutationVariables = Exact<{
  schoolId: Scalars['String'];
  data: GradebookSettingInput;
}>;


export type CreateGradebookMutation = { __typename?: 'Mutation', createGradebook: { __typename?: 'GradebookSettingModel', id: string, name: string, isDeleted: boolean, type: GradebookSettingTypeEnum, values: Array<{ __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string }> } };

export type UpdateGradebookMutationVariables = Exact<{
  id: Scalars['String'];
  data: GradebookSettingInput;
}>;


export type UpdateGradebookMutation = { __typename?: 'Mutation', updateGradebook: { __typename?: 'GradebookSettingModel', id: string, name: string, isDeleted: boolean, type: GradebookSettingTypeEnum, values: Array<{ __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string }> } };

export type ReactivateGradebooksMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type ReactivateGradebooksMutation = { __typename?: 'Mutation', reactivateGradebooks: boolean };

export type RemoveGradebooksMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type RemoveGradebooksMutation = { __typename?: 'Mutation', removeGradebooks: boolean };

export type GetGradebooksQueryVariables = Exact<{
  schoolId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetGradebooksQuery = { __typename?: 'Query', getGradebooks: { __typename?: 'GradebookSettingPaginationModel', pagination: { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number }, data?: Array<{ __typename?: 'GradebookSettingModel', id: string, name: string, isDeleted: boolean, type: GradebookSettingTypeEnum }> | null } };

export type GetGradebookQueryVariables = Exact<{
  schoolId: Scalars['String'];
  id: Scalars['String'];
}>;


export type GetGradebookQuery = { __typename?: 'Query', getGradebook: { __typename?: 'GradebookSettingModel', id: string, name: string, isDeleted: boolean, type: GradebookSettingTypeEnum, values: Array<{ __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string }> } };

export type UserGradeFieldsFragment = { __typename?: 'UserGrade', assignmentId: string, userId: string, returned?: number | null, draft?: number | null, latestAction?: GradeActionEnum | null, gradeExcuse?: { __typename?: 'GradeExcuseModel', id: string, name: string } | null };

export type StoreGradeMutationVariables = Exact<{
  data: GradesListInput;
}>;


export type StoreGradeMutation = { __typename?: 'Mutation', storeGrade: boolean };

export type SetOverallMutationVariables = Exact<{
  data: OverallGradeInput;
}>;


export type SetOverallMutation = { __typename?: 'Mutation', setOverall: number };

export type ReturnAllAssignmentGradesMutationVariables = Exact<{
  assignmentId: Scalars['String'];
}>;


export type ReturnAllAssignmentGradesMutation = { __typename?: 'Mutation', returnAllAssignmentGrades: boolean };

export type ResetUserOverallMutationVariables = Exact<{
  data: ResetUserOverallInput;
}>;


export type ResetUserOverallMutation = { __typename?: 'Mutation', resetUserOverall: { __typename?: 'ResetOverallModel', classOverall?: number | null, userOverall?: number | null } };

export type SetGradeExcuseMutationVariables = Exact<{
  data: GradeExcuseListInput;
}>;


export type SetGradeExcuseMutation = { __typename?: 'Mutation', setGradeExcuse: boolean };

export type GetAllAssignmentsGradesQueryVariables = Exact<{
  classId: Scalars['String'];
  markingPeriodId: Scalars['String'];
  userStatus?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetAllAssignmentsGradesQuery = { __typename?: 'Query', getAllAssignmentsGrades: { __typename?: 'AllAssignmentGradesModel', overall?: number | null, users: Array<{ __typename?: 'AssignmentUser', overall?: number | null, user: { __typename?: 'ClassUserModel', isDeleted: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } }, assignmentGrades: Array<{ __typename?: 'UserGrade', assignmentId: string, userId: string, returned?: number | null, draft?: number | null, latestAction?: GradeActionEnum | null, gradeExcuse?: { __typename?: 'GradeExcuseModel', id: string, name: string } | null }> }> } };

export type GetStudentGradesQueryVariables = Exact<{
  schoolId: Scalars['String'];
  termId: Scalars['String'];
  userId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetStudentGradesQuery = { __typename?: 'Query', getStudentGrades: { __typename?: 'StudentGradesModel', classes: Array<{ __typename?: 'StudentGradeClassModel', id: string, name: string, overall?: number | null, markingPeriods: Array<{ __typename?: 'StudentGradeClassMarkingPeriodModel', id: string, value?: number | null }> }>, markingPeriods: Array<{ __typename?: 'StudentGradesMarkingPeriodsModel', id: string, name: string }> } };

export type GetStudentOverallQueryVariables = Exact<{
  classId: Scalars['String'];
  markingPeriodId: Scalars['String'];
  userId: Scalars['String'];
}>;


export type GetStudentOverallQuery = { __typename?: 'Query', getStudentOverall: number };

export type EnableUserOAuthAppMutationVariables = Exact<{
  data: EnableAppInput;
}>;


export type EnableUserOAuthAppMutation = { __typename?: 'Mutation', enableUserOAuthApp: boolean };

export type DisableUserAppMutationVariables = Exact<{
  name: AppEnum;
}>;


export type DisableUserAppMutation = { __typename?: 'Mutation', disableUserApp: boolean };

export type GetPublicIntegrationQueryVariables = Exact<{
  schoolId: Scalars['String'];
  type: ProviderEnum;
}>;


export type GetPublicIntegrationQuery = { __typename?: 'Query', getPublicIntegration: { __typename?: 'IntegrationSetupModel', hasIntegration: boolean, status: boolean, type: ProviderEnum, keys: Array<{ __typename?: 'IntegrationBlocksDTO', key: string, label: string, required: boolean, value?: string | null }> } };

export type GetUserAppsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserAppsQuery = { __typename?: 'Query', getUserApps: Array<{ __typename?: 'AppModel', id: string, name: AppEnum, enabled: boolean }> };

export type GenerateAppAuthLinkQueryVariables = Exact<{
  name: AppEnum;
}>;


export type GenerateAppAuthLinkQuery = { __typename?: 'Query', generateAuthLink: string };

export type MeMarkingPeriodFieldsFragment = { __typename?: 'MarkingPeriodModel', id: string, name: string, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any } };

export type MarkingPeriodFieldsFragment = { __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } };

export type CreateMarkingPeriodMutationVariables = Exact<{
  schoolId: Scalars['String'];
  data: MarkingPeriodInput;
}>;


export type CreateMarkingPeriodMutation = { __typename?: 'Mutation', createMarkingPeriod: { __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } } };

export type UpdateMarkingPeriodMutationVariables = Exact<{
  id: Scalars['String'];
  data: MarkingPeriodInput;
}>;


export type UpdateMarkingPeriodMutation = { __typename?: 'Mutation', updateMarkingPeriod: { __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } } };

export type ReactivateMarkingPeriodsMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type ReactivateMarkingPeriodsMutation = { __typename?: 'Mutation', reactivateMarkingPeriods: boolean };

export type RemoveMarkingPeriodsMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type RemoveMarkingPeriodsMutation = { __typename?: 'Mutation', removeMarkingPeriods: boolean };

export type GetMarkingPeriodsQueryVariables = Exact<{
  schoolId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetMarkingPeriodsQuery = { __typename?: 'Query', getMarkingPeriods: { __typename?: 'MarkingPeriodPaginationModel', pagination: { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number }, data?: Array<{ __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } }> | null } };

export type GetTermMarkingPeriodsQueryVariables = Exact<{
  schoolId: Scalars['String'];
  termId: Scalars['String'];
}>;


export type GetTermMarkingPeriodsQuery = { __typename?: 'Query', getTermMarkingPeriods: Array<{ __typename?: 'MarkingPeriodModel', id: string, name: string }> };

export type GetMarkingPeriodQueryVariables = Exact<{
  schoolId: Scalars['String'];
  id: Scalars['String'];
}>;


export type GetMarkingPeriodQuery = { __typename?: 'Query', getMarkingPeriod: { __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } } };

export type SendMessageMutationVariables = Exact<{
  data: MessageInput;
}>;


export type SendMessageMutation = { __typename?: 'Mutation', sendMessage: boolean };

export type PaginationFieldsFragment = { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number };

export type ReportingFieldsFragment = { __typename?: 'ReportModel', id: string, type: ReportTypeEnum, createdAt: any, term?: { __typename?: 'TermModel', id: string, name: string } | null, markingPeriods?: Array<{ __typename?: 'MarkingPeriodModel', id: string, name: string, term: { __typename?: 'TermModel', id: string, name: string } }> | null, file?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null };

export type CreateReportMutationVariables = Exact<{
  data: ReportInput;
}>;


export type CreateReportMutation = { __typename?: 'Mutation', createReport: boolean };

export type CreateGradeReportMutationVariables = Exact<{
  data: ReportGradeInput;
}>;


export type CreateGradeReportMutation = { __typename?: 'Mutation', createGradeReport: boolean };

export type RemoveReportsMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type RemoveReportsMutation = { __typename?: 'Mutation', removeReports: boolean };

export type GetReportsQueryVariables = Exact<{
  schoolId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetReportsQuery = { __typename?: 'Query', getReports: { __typename?: 'ReportPaginationModel', pagination: { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number }, data?: Array<{ __typename?: 'ReportModel', id: string, type: ReportTypeEnum, createdAt: any, term?: { __typename?: 'TermModel', id: string, name: string } | null, markingPeriods?: Array<{ __typename?: 'MarkingPeriodModel', id: string, name: string, term: { __typename?: 'TermModel', id: string, name: string } }> | null, file?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null }> | null } };

export type MeRoleFieldsFragment = { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum };

export type MinimalRoleFieldsFragment = { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum };

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = { __typename?: 'Query', getRoles: Array<{ __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum }> };

export type MeSchoolFieldsFragment = { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, defaultMarkingPeriod?: { __typename?: 'MarkingPeriodModel', id: string, name: string, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any } } | null, defaultGradebook?: { __typename?: 'GradebookSettingModel', id: string, name: string } | null };

export type UpdateSchoolMutationVariables = Exact<{
  id: Scalars['String'];
  data: SchoolInput;
}>;


export type UpdateSchoolMutation = { __typename?: 'Mutation', updateSchool: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, defaultMarkingPeriod?: { __typename?: 'MarkingPeriodModel', id: string, name: string, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any } } | null, defaultGradebook?: { __typename?: 'GradebookSettingModel', id: string, name: string } | null } };

export type CreateSubmissionMutationVariables = Exact<{
  data: SubmissionInput;
}>;


export type CreateSubmissionMutation = { __typename?: 'Mutation', createSubmission: { __typename?: 'SubmissionModel', id: string, createdAt?: any | null, updatedAt?: any | null, attachments: Array<{ __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null }> } };

export type GetAssignmentSubmissionsQueryVariables = Exact<{
  assignmentId: Scalars['String'];
}>;


export type GetAssignmentSubmissionsQuery = { __typename?: 'Query', getAssignmentSubmissions: Array<{ __typename?: 'AssignmentSubmissionsModel', status: SubmissionStatusEnum, submission?: { __typename?: 'SubmissionModel', id: string, createdAt?: any | null, updatedAt?: any | null } | null, grade: { __typename?: 'UserGrade', assignmentId: string, userId: string, returned?: number | null, draft?: number | null, latestAction?: GradeActionEnum | null, gradeExcuse?: { __typename?: 'GradeExcuseModel', id: string, name: string } | null }, user: { __typename?: 'ClassUserModel', isDeleted: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } } }> };

export type GetStudentSubmissionQueryVariables = Exact<{
  schoolId: Scalars['String'];
  assignmentId: Scalars['String'];
  userId: Scalars['String'];
}>;


export type GetStudentSubmissionQuery = { __typename?: 'Query', getStudentSubmission: { __typename?: 'AssignmentSubmissionsModel', status: SubmissionStatusEnum, assignment: { __typename?: 'AssignmentModel', id: string, name: string, dueDate?: any | null, points: number, description?: string | null, isSubmittable: boolean, category: { __typename?: 'GradebookSettingValuesModel', id: string, name: string, value?: number | null, color: string }, class: { __typename?: 'ClassModel', id: string, name: string }, markingPeriod: { __typename?: 'MarkingPeriodModel', id: string, name: string, isDeleted: boolean, fromDate: any, toDate: any, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean } }, attachments: Array<{ __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null }> }, grade: { __typename?: 'UserGrade', assignmentId: string, userId: string, returned?: number | null, draft?: number | null, latestAction?: GradeActionEnum | null, gradeExcuse?: { __typename?: 'GradeExcuseModel', id: string, name: string } | null }, submission?: { __typename?: 'SubmissionModel', id: string, createdAt?: any | null, updatedAt?: any | null, attachments: Array<{ __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null }> } | null, user: { __typename?: 'ClassUserModel', isDeleted: boolean, user: { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } } } };

export type SubmissionFieldsFragment = { __typename?: 'SubmissionModel', id: string, createdAt?: any | null, updatedAt?: any | null };

export type ExtendedSubmissionFieldsFragment = { __typename?: 'SubmissionModel', id: string, createdAt?: any | null, updatedAt?: any | null, attachments: Array<{ __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null }> };

export type TagFieldsFragment = { __typename?: 'TagModel', id: string, name: string, isDeleted: boolean };

export type CreateTagMutationVariables = Exact<{
  schoolId: Scalars['String'];
  data: TagInput;
}>;


export type CreateTagMutation = { __typename?: 'Mutation', createTag: { __typename?: 'TagModel', id: string, name: string, isDeleted: boolean } };

export type UpdateTagMutationVariables = Exact<{
  id: Scalars['String'];
  data: TagInput;
}>;


export type UpdateTagMutation = { __typename?: 'Mutation', updateTag: { __typename?: 'TagModel', id: string, name: string, isDeleted: boolean } };

export type ReactivateTagsMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type ReactivateTagsMutation = { __typename?: 'Mutation', reactivateTags: boolean };

export type RemoveTagsMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type RemoveTagsMutation = { __typename?: 'Mutation', removeTags: boolean };

export type GetTagsQueryVariables = Exact<{
  schoolId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetTagsQuery = { __typename?: 'Query', getTags: { __typename?: 'TagPaginationModel', pagination: { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number }, data?: Array<{ __typename?: 'TagModel', id: string, name: string, isDeleted: boolean }> | null } };

export type GetTagQueryVariables = Exact<{
  schoolId: Scalars['String'];
  id: Scalars['String'];
}>;


export type GetTagQuery = { __typename?: 'Query', getTag: { __typename?: 'TagModel', id: string, name: string, isDeleted: boolean } };

export type TermFieldsFragment = { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean, school?: { __typename?: 'SchoolModel', id: string, name: string } | null };

export type CreateTermMutationVariables = Exact<{
  schoolId: Scalars['String'];
  data: TermInput;
}>;


export type CreateTermMutation = { __typename?: 'Mutation', createTerm: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean, school?: { __typename?: 'SchoolModel', id: string, name: string } | null } };

export type UpdateTermMutationVariables = Exact<{
  id: Scalars['String'];
  data: TermInput;
}>;


export type UpdateTermMutation = { __typename?: 'Mutation', updateTerm: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean, school?: { __typename?: 'SchoolModel', id: string, name: string } | null } };

export type ReactivateTermsMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type ReactivateTermsMutation = { __typename?: 'Mutation', reactivateTerms: boolean };

export type RemoveTermsMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type RemoveTermsMutation = { __typename?: 'Mutation', removeTerms: boolean };

export type GetTermsQueryVariables = Exact<{
  schoolId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetTermsQuery = { __typename?: 'Query', getTerms: { __typename?: 'TermPaginationModel', pagination: { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number }, data?: Array<{ __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean, school?: { __typename?: 'SchoolModel', id: string, name: string } | null }> | null } };

export type GetTermQueryVariables = Exact<{
  schoolId: Scalars['String'];
  id: Scalars['String'];
}>;


export type GetTermQuery = { __typename?: 'Query', getTerm: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean, school?: { __typename?: 'SchoolModel', id: string, name: string } | null } };

export type FileFieldsFragment = { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null };

export type UploadFilesMutationVariables = Exact<{
  files: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type UploadFilesMutation = { __typename?: 'Mutation', uploadFiles: Array<{ __typename?: 'UploadFileInput', name: string, size: number, url: string }> };

export type UserAttendanceColumnFieldsFragment = { __typename?: 'AttendanceColumnObject', id: string, attendanceStatus: { __typename?: 'AttendanceStatusObject', id: string, name: string, shortName?: string | null, color: string } };

export type GetUserAttendanceQueryVariables = Exact<{
  userId: Scalars['String'];
  from: Scalars['String'];
  to: Scalars['String'];
  classId?: InputMaybe<Scalars['String']>;
}>;


export type GetUserAttendanceQuery = { __typename?: 'Query', getUserAttendance: { __typename?: 'AttendanceUserObject', columns: Array<{ __typename?: 'ColumnObject', id: string, name: string }>, rows: Array<{ __typename?: 'AttendanceRowObject', class: { __typename?: 'ClassModel', id: string, name: string }, attendances: Array<{ __typename?: 'AttendanceColumnObject', id: string, attendanceStatus: { __typename?: 'AttendanceStatusObject', id: string, name: string, shortName?: string | null, color: string } }> }> } };

export type MeUserAccessFieldsFragment = { __typename?: 'UserAccessModel', status: UserStatuses, school: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, defaultMarkingPeriod?: { __typename?: 'MarkingPeriodModel', id: string, name: string, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any } } | null, defaultGradebook?: { __typename?: 'GradebookSettingModel', id: string, name: string } | null }, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } };

export type MeFieldsFragment = { __typename?: 'UserModel', id: string, firstName: string, lastName: string, email: string, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, schoolAccess: Array<{ __typename?: 'UserAccessModel', status: UserStatuses, school: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, defaultMarkingPeriod?: { __typename?: 'MarkingPeriodModel', id: string, name: string, term: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any } } | null, defaultGradebook?: { __typename?: 'GradebookSettingModel', id: string, name: string } | null }, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }> };

export type TableUserFieldsFragment = { __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } };

export type SingleUserFieldsFragment = { __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } };

export type CreateUserMutationVariables = Exact<{
  schoolId: Scalars['String'];
  data: UserSchoolInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'CreateSchoolUserResponse', exists: boolean, user: { __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } } };

export type UpdateUserMutationVariables = Exact<{
  schoolId: Scalars['String'];
  id: Scalars['String'];
  data: UpdateUserSchoolInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } };

export type BulkUserStatusUpdateMutationVariables = Exact<{
  data: UpdateBulkUserSchoolStatusInput;
}>;


export type BulkUserStatusUpdateMutation = { __typename?: 'Mutation', bulkUserStatusUpdate: boolean };

export type AddUserParentsMutationVariables = Exact<{
  parentIds: Array<Scalars['String']> | Scalars['String'];
  userId: Scalars['String'];
}>;


export type AddUserParentsMutation = { __typename?: 'Mutation', addUserParents: boolean };

export type RemoveUserParentsMutationVariables = Exact<{
  parentIds: Array<Scalars['String']> | Scalars['String'];
  userId: Scalars['String'];
}>;


export type RemoveUserParentsMutation = { __typename?: 'Mutation', removeUserParents: boolean };

export type GetUsersQueryVariables = Exact<{
  schoolId: Scalars['String'];
  role?: InputMaybe<Array<RoleTypeEnum> | RoleTypeEnum>;
  status?: InputMaybe<UserStatuses>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetUsersQuery = { __typename?: 'Query', getUsers: { __typename?: 'UserSchoolPaginationModel', pagination: { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number }, data?: Array<{ __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }> | null } };

export type GetUserQueryVariables = Exact<{
  schoolId: Scalars['String'];
  id: Scalars['String'];
}>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } } };

export type GetUserGradeListQueryVariables = Exact<{
  schoolId: Scalars['String'];
}>;


export type GetUserGradeListQuery = { __typename?: 'Query', getUserGradeList: Array<string> };

export type GetParentStudentsQueryVariables = Exact<{
  schoolId: Scalars['String'];
  parentId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetParentStudentsQuery = { __typename?: 'Query', getParentStudents: Array<{ __typename?: 'UserSchoolModel', id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }> };

export type GetUserParentsQueryVariables = Exact<{
  schoolId: Scalars['String'];
  userId: Scalars['String'];
}>;


export type GetUserParentsQuery = { __typename?: 'Query', getUserParents: Array<{ __typename?: 'UserSchoolModel', phoneNumber?: string | null, id: string, firstName: string, lastName: string, email: string, grade?: string | null, status: UserStatuses, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string, createdAt?: any | null } | null, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }> };

export const FileFieldsFragmentDoc = gql`
    fragment FileFields on FileModel {
  id
  name
  size
  url
  createdAt
}
    `;
export const MinimalRoleFieldsFragmentDoc = gql`
    fragment MinimalRoleFields on RoleModel {
  id
  name
  type
}
    `;
export const TableUserFieldsFragmentDoc = gql`
    fragment TableUserFields on UserSchoolModel {
  id
  firstName
  lastName
  email
  grade
  image {
    ...FileFields
  }
  role {
    ...MinimalRoleFields
  }
  status
}
    ${FileFieldsFragmentDoc}
${MinimalRoleFieldsFragmentDoc}`;
export const SingleUserFieldsFragmentDoc = gql`
    fragment SingleUserFields on UserSchoolModel {
  ...TableUserFields
  phoneNumber
}
    ${TableUserFieldsFragmentDoc}`;
export const TableAnecdotalFieldsFragmentDoc = gql`
    fragment TableAnecdotalFields on AnecdotalModel {
  id
  user {
    ...SingleUserFields
  }
  text
  anecdotalType {
    id
    name
    isDeleted
  }
  severity
  referTo {
    ...SingleUserFields
  }
  createdBy {
    ...SingleUserFields
  }
  createdAt
}
    ${SingleUserFieldsFragmentDoc}`;
export const SingleAnecdotalFieldsFragmentDoc = gql`
    fragment SingleAnecdotalFields on AnecdotalModel {
  ...TableAnecdotalFields
  isNotifyParent
  isNotifyStudent
  isVisibleParent
  isVisibleStudent
}
    ${TableAnecdotalFieldsFragmentDoc}`;
export const SingleTypeAnecdotalFieldsFragmentDoc = gql`
    fragment SingleTypeAnecdotalFields on AnecdotalTypeModel {
  id
  name
  isDeleted
  school {
    id
    name
  }
}
    `;
export const GradebookCategoryFieldsFragmentDoc = gql`
    fragment GradebookCategoryFields on GradebookSettingValuesModel {
  id
  name
  value
  color
}
    `;
export const TableTeacherAssignmentFieldsFragmentDoc = gql`
    fragment TableTeacherAssignmentFields on AssignmentModel {
  id
  name
  status
  dueDate
  category {
    ...GradebookCategoryFields
  }
  submissionCount
  submissionExpected
  averageGrade
  points
}
    ${GradebookCategoryFieldsFragmentDoc}`;
export const ClassNameFieldsFragmentDoc = gql`
    fragment ClassNameFields on ClassModel {
  id
  name
}
    `;
export const MarkingPeriodFieldsFragmentDoc = gql`
    fragment MarkingPeriodFields on MarkingPeriodModel {
  id
  name
  isDeleted
  fromDate
  toDate
  term {
    id
    name
    fromDate
    toDate
    isDeleted
  }
}
    `;
export const ClassUserFieldsFragmentDoc = gql`
    fragment ClassUserFields on ClassUserModel {
  isDeleted
  user {
    id
    firstName
    lastName
    email
    image {
      ...FileFields
    }
    role {
      ...MinimalRoleFields
    }
    status
  }
}
    ${FileFieldsFragmentDoc}
${MinimalRoleFieldsFragmentDoc}`;
export const SingleTeacherAssignmentFieldsFragmentDoc = gql`
    fragment SingleTeacherAssignmentFields on AssignmentModel {
  ...TableTeacherAssignmentFields
  class {
    ...ClassNameFields
  }
  description
  isSubmittable
  markingPeriod {
    ...MarkingPeriodFields
  }
  attachments {
    ...FileFields
  }
  users {
    ...ClassUserFields
  }
}
    ${TableTeacherAssignmentFieldsFragmentDoc}
${ClassNameFieldsFragmentDoc}
${MarkingPeriodFieldsFragmentDoc}
${FileFieldsFragmentDoc}
${ClassUserFieldsFragmentDoc}`;
export const TableStudentAssignmentFieldsFragmentDoc = gql`
    fragment TableStudentAssignmentFields on AssignmentModel {
  id
  name
  dueDate
  category {
    ...GradebookCategoryFields
  }
  points
}
    ${GradebookCategoryFieldsFragmentDoc}`;
export const SingleStudentAssignmentFieldsFragmentDoc = gql`
    fragment SingleStudentAssignmentFields on AssignmentModel {
  id
  name
  dueDate
  category {
    ...GradebookCategoryFields
  }
  points
  class {
    ...ClassNameFields
  }
  description
  isSubmittable
  markingPeriod {
    ...MarkingPeriodFields
  }
  attachments {
    ...FileFields
  }
}
    ${GradebookCategoryFieldsFragmentDoc}
${ClassNameFieldsFragmentDoc}
${MarkingPeriodFieldsFragmentDoc}
${FileFieldsFragmentDoc}`;
export const AttendanceStatusFieldsFragmentDoc = gql`
    fragment AttendanceStatusFields on AttendanceStatusObject {
  id
  name
  description
  shortName
  color
  isDeleted
}
    `;
export const DistrictAuthFieldsFragmentDoc = gql`
    fragment DistrictAuthFields on DistrictModel {
  id
  name
  url
  logo {
    ...FileFields
  }
  background {
    ...FileFields
  }
}
    ${FileFieldsFragmentDoc}`;
export const ValidateMagicLinkFieldsFragmentDoc = gql`
    fragment ValidateMagicLinkFields on MagicLoginModel {
  email
  districts {
    ...DistrictAuthFields
  }
  code
}
    ${DistrictAuthFieldsFragmentDoc}`;
export const ClassAttendanceFieldsFragmentDoc = gql`
    fragment ClassAttendanceFields on AttendanceClassObject {
  attendanceStatus {
    id
    name
    shortName
    color
  }
  user {
    ...ClassUserFields
  }
}
    ${ClassUserFieldsFragmentDoc}`;
export const TableClassFieldsFragmentDoc = gql`
    fragment TableClassFields on ClassModel {
  id
  name
  room
  users {
    ...ClassUserFields
  }
  isDeleted
  googleData {
    id
    name
    connected
    users
    assignments
  }
}
    ${ClassUserFieldsFragmentDoc}`;
export const ExtendedClassUserFieldsFragmentDoc = gql`
    fragment ExtendedClassUserFields on ClassUserModel {
  isDeleted
  deactivatedInSync
  userFromCleverSync
  user {
    id
    firstName
    lastName
    email
    image {
      ...FileFields
    }
    role {
      ...MinimalRoleFields
    }
    status
  }
}
    ${FileFieldsFragmentDoc}
${MinimalRoleFieldsFragmentDoc}`;
export const TableGradebookFieldsFragmentDoc = gql`
    fragment TableGradebookFields on GradebookSettingModel {
  id
  name
  isDeleted
  type
}
    `;
export const SingleGradebookFieldsFragmentDoc = gql`
    fragment SingleGradebookFields on GradebookSettingModel {
  ...TableGradebookFields
  values {
    ...GradebookCategoryFields
  }
}
    ${TableGradebookFieldsFragmentDoc}
${GradebookCategoryFieldsFragmentDoc}`;
export const SingleClassFieldsFragmentDoc = gql`
    fragment SingleClassFields on ClassModel {
  id
  name
  room
  isDeleted
  users {
    ...ExtendedClassUserFields
  }
  gradebookSetting {
    ...SingleGradebookFields
  }
  markingPeriods {
    ...MarkingPeriodFields
  }
  cleverId
  googleData {
    id
    name
    connected
    users
    assignments
  }
}
    ${ExtendedClassUserFieldsFragmentDoc}
${SingleGradebookFieldsFragmentDoc}
${MarkingPeriodFieldsFragmentDoc}`;
export const ImportGradesFieldsFragmentDoc = gql`
    fragment ImportGradesFields on ImportAssignmentsGradesObject {
  user {
    ...ClassUserFields
  }
  overall
  assignments {
    assignment {
      id
      name
      points
    }
    points
  }
  errors {
    error
    key
  }
}
    ${ClassUserFieldsFragmentDoc}`;
export const CleverPracticeClassFieldsFragmentDoc = gql`
    fragment CleverPracticeClassFields on ClassModel {
  id
  name
  isDeleted
  markingPeriods {
    id
    name
  }
}
    `;
export const CleverClassFieldsFragmentDoc = gql`
    fragment CleverClassFields on CleverClassModel {
  name
  termId
  teachers
  students
  cleverId
  sectionNumber
  sectionGrade
  courseNumber
  practiceStatus
  practiceClass {
    ...CleverPracticeClassFields
  }
}
    ${CleverPracticeClassFieldsFragmentDoc}`;
export const CleverPracticeUserFieldsFragmentDoc = gql`
    fragment CleverPracticeUserFields on UserSchoolModel {
  id
  firstName
  lastName
  email
  grade
  image {
    ...FileFields
  }
  isDeleted
  role {
    ...MinimalRoleFields
  }
  status
}
    ${FileFieldsFragmentDoc}
${MinimalRoleFieldsFragmentDoc}`;
export const CleverUserFieldsFragmentDoc = gql`
    fragment CleverUserFields on CleverUserModel {
  cleverId
  firstName
  lastName
  email
  grade
  roleId
  studentNumber
  practiceStatus
  practiceUser {
    ...CleverPracticeUserFields
  }
}
    ${CleverPracticeUserFieldsFragmentDoc}`;
export const ValidateDistrictUrlFieldsFragmentDoc = gql`
    fragment ValidateDistrictURLFields on ValidateDistrictAuthModel {
  id
  name
  isDeleted
  timezone
  logo {
    ...FileFields
  }
  favicon {
    ...FileFields
  }
  background {
    ...FileFields
  }
}
    ${FileFieldsFragmentDoc}`;
export const DistrictFieldsFragmentDoc = gql`
    fragment DistrictFields on DistrictModel {
  id
  name
  url
  isDeleted
  timezone
  logo {
    ...FileFields
  }
  favicon {
    ...FileFields
  }
  background {
    ...FileFields
  }
}
    ${FileFieldsFragmentDoc}`;
export const GooglePracticeAssignmentFieldsFragmentDoc = gql`
    fragment GooglePracticeAssignmentFields on AssignmentModel {
  id
  name
  category {
    ...GradebookCategoryFields
  }
  markingPeriod {
    ...MarkingPeriodFields
  }
}
    ${GradebookCategoryFieldsFragmentDoc}
${MarkingPeriodFieldsFragmentDoc}`;
export const GoogleAssignmentFieldsFragmentDoc = gql`
    fragment GoogleAssignmentFields on GoogleClassroomAssignmentModel {
  googleId
  name
  assignees
  attachments {
    ...FileFields
  }
  description
  dueDate
  isSubmittable
  points
  status
  practiceStatus
  practiceAssignment {
    ...GooglePracticeAssignmentFields
  }
}
    ${FileFieldsFragmentDoc}
${GooglePracticeAssignmentFieldsFragmentDoc}`;
export const GoogleClassFieldsFragmentDoc = gql`
    fragment GoogleClassFields on GoogleClassroomClassModel {
  name
  googleId
}
    `;
export const GooglePracticeUserFieldsFragmentDoc = gql`
    fragment GooglePracticeUserFields on UserSchoolModel {
  id
  firstName
  lastName
  email
  image {
    ...FileFields
  }
  isDeleted
  role {
    ...MinimalRoleFields
  }
  status
}
    ${FileFieldsFragmentDoc}
${MinimalRoleFieldsFragmentDoc}`;
export const GoogleUserFieldsFragmentDoc = gql`
    fragment GoogleUserFields on GoogleClassroomUserModel {
  googleId
  firstName
  lastName
  email
  roleId
  practiceStatus
  practiceUser {
    ...GooglePracticeUserFields
  }
}
    ${GooglePracticeUserFieldsFragmentDoc}`;
export const ExcusedGradeFieldsFragmentDoc = gql`
    fragment ExcusedGradeFields on GradeExcuseModel {
  id
  name
}
    `;
export const UserGradeFieldsFragmentDoc = gql`
    fragment UserGradeFields on UserGrade {
  assignmentId
  userId
  returned
  draft
  latestAction
  gradeExcuse {
    ...ExcusedGradeFields
  }
}
    ${ExcusedGradeFieldsFragmentDoc}`;
export const PaginationFieldsFragmentDoc = gql`
    fragment PaginationFields on CursorPagination {
  endCursor
  startCursor
  hasNextPage
  hasPreviousPage
  totalCount
}
    `;
export const ReportingFieldsFragmentDoc = gql`
    fragment ReportingFields on ReportModel {
  id
  type
  createdAt
  term {
    id
    name
  }
  markingPeriods {
    id
    name
    term {
      id
      name
    }
  }
  file {
    ...FileFields
  }
}
    ${FileFieldsFragmentDoc}`;
export const SubmissionFieldsFragmentDoc = gql`
    fragment SubmissionFields on SubmissionModel {
  id
  createdAt
  updatedAt
}
    `;
export const ExtendedSubmissionFieldsFragmentDoc = gql`
    fragment ExtendedSubmissionFields on SubmissionModel {
  ...SubmissionFields
  attachments {
    ...FileFields
  }
}
    ${SubmissionFieldsFragmentDoc}
${FileFieldsFragmentDoc}`;
export const TagFieldsFragmentDoc = gql`
    fragment TagFields on TagModel {
  id
  name
  isDeleted
}
    `;
export const TermFieldsFragmentDoc = gql`
    fragment TermFields on TermModel {
  id
  name
  fromDate
  toDate
  isDeleted
  school {
    id
    name
  }
}
    `;
export const UserAttendanceColumnFieldsFragmentDoc = gql`
    fragment UserAttendanceColumnFields on AttendanceColumnObject {
  id
  attendanceStatus {
    id
    name
    shortName
    color
  }
}
    `;
export const MeMarkingPeriodFieldsFragmentDoc = gql`
    fragment MeMarkingPeriodFields on MarkingPeriodModel {
  id
  name
  term {
    id
    name
    fromDate
    toDate
  }
}
    `;
export const MeSchoolFieldsFragmentDoc = gql`
    fragment MeSchoolFields on SchoolModel {
  id
  name
  logo {
    ...FileFields
  }
  background {
    ...FileFields
  }
  defaultMarkingPeriod {
    ...MeMarkingPeriodFields
  }
  defaultGradebook {
    id
    name
  }
  isDeleted
}
    ${FileFieldsFragmentDoc}
${MeMarkingPeriodFieldsFragmentDoc}`;
export const MeRoleFieldsFragmentDoc = gql`
    fragment MeRoleFields on RoleModel {
  id
  name
  type
}
    `;
export const MeUserAccessFieldsFragmentDoc = gql`
    fragment MeUserAccessFields on UserAccessModel {
  school {
    ...MeSchoolFields
  }
  role {
    ...MeRoleFields
  }
  status
}
    ${MeSchoolFieldsFragmentDoc}
${MeRoleFieldsFragmentDoc}`;
export const MeFieldsFragmentDoc = gql`
    fragment MeFields on UserModel {
  id
  firstName
  lastName
  email
  image {
    ...FileFields
  }
  schoolAccess {
    ...MeUserAccessFields
  }
}
    ${FileFieldsFragmentDoc}
${MeUserAccessFieldsFragmentDoc}`;
export const CreateAnecdotalDocument = gql`
    mutation createAnecdotal($data: AnecdotalInput!) {
  createAnecdotal(input: $data)
}
    `;
export type CreateAnecdotalMutationFn = Apollo.MutationFunction<CreateAnecdotalMutation, CreateAnecdotalMutationVariables>;

/**
 * __useCreateAnecdotalMutation__
 *
 * To run a mutation, you first call `useCreateAnecdotalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnecdotalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnecdotalMutation, { data, loading, error }] = useCreateAnecdotalMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAnecdotalMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnecdotalMutation, CreateAnecdotalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnecdotalMutation, CreateAnecdotalMutationVariables>(CreateAnecdotalDocument, options);
      }
export type CreateAnecdotalMutationHookResult = ReturnType<typeof useCreateAnecdotalMutation>;
export type CreateAnecdotalMutationResult = Apollo.MutationResult<CreateAnecdotalMutation>;
export type CreateAnecdotalMutationOptions = Apollo.BaseMutationOptions<CreateAnecdotalMutation, CreateAnecdotalMutationVariables>;
export const UpdateAnecdotalDocument = gql`
    mutation updateAnecdotal($id: String!, $data: AnecdotalUpdateInput!) {
  updateAnecdotal(id: $id, input: $data) {
    ...SingleAnecdotalFields
  }
}
    ${SingleAnecdotalFieldsFragmentDoc}`;
export type UpdateAnecdotalMutationFn = Apollo.MutationFunction<UpdateAnecdotalMutation, UpdateAnecdotalMutationVariables>;

/**
 * __useUpdateAnecdotalMutation__
 *
 * To run a mutation, you first call `useUpdateAnecdotalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnecdotalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnecdotalMutation, { data, loading, error }] = useUpdateAnecdotalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAnecdotalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnecdotalMutation, UpdateAnecdotalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnecdotalMutation, UpdateAnecdotalMutationVariables>(UpdateAnecdotalDocument, options);
      }
export type UpdateAnecdotalMutationHookResult = ReturnType<typeof useUpdateAnecdotalMutation>;
export type UpdateAnecdotalMutationResult = Apollo.MutationResult<UpdateAnecdotalMutation>;
export type UpdateAnecdotalMutationOptions = Apollo.BaseMutationOptions<UpdateAnecdotalMutation, UpdateAnecdotalMutationVariables>;
export const RemoveAnecdotalsDocument = gql`
    mutation removeAnecdotals($data: MultipleIdsInput!) {
  removeAnecdotals(input: $data)
}
    `;
export type RemoveAnecdotalsMutationFn = Apollo.MutationFunction<RemoveAnecdotalsMutation, RemoveAnecdotalsMutationVariables>;

/**
 * __useRemoveAnecdotalsMutation__
 *
 * To run a mutation, you first call `useRemoveAnecdotalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAnecdotalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAnecdotalsMutation, { data, loading, error }] = useRemoveAnecdotalsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveAnecdotalsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAnecdotalsMutation, RemoveAnecdotalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAnecdotalsMutation, RemoveAnecdotalsMutationVariables>(RemoveAnecdotalsDocument, options);
      }
export type RemoveAnecdotalsMutationHookResult = ReturnType<typeof useRemoveAnecdotalsMutation>;
export type RemoveAnecdotalsMutationResult = Apollo.MutationResult<RemoveAnecdotalsMutation>;
export type RemoveAnecdotalsMutationOptions = Apollo.BaseMutationOptions<RemoveAnecdotalsMutation, RemoveAnecdotalsMutationVariables>;
export const GetAnecdotalsDocument = gql`
    query getAnecdotals($schoolId: String!, $after: String, $before: String, $limit: Int = 10, $search: String, $from: DateTime, $to: DateTime, $userIds: [String!], $referToIds: [String!], $createdByIds: [String!], $anecdotalTypeIds: [String!], $severities: [AnecdotalSeverityEnum!]) {
  getAnecdotals(
    schoolId: $schoolId
    after: $after
    before: $before
    limit: $limit
    search: $search
    from: $from
    to: $to
    userIds: $userIds
    referToIds: $referToIds
    createdByIds: $createdByIds
    anecdotalTypeIds: $anecdotalTypeIds
    severities: $severities
  ) {
    pagination {
      ...PaginationFields
    }
    data {
      ...TableAnecdotalFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${TableAnecdotalFieldsFragmentDoc}`;

/**
 * __useGetAnecdotalsQuery__
 *
 * To run a query within a React component, call `useGetAnecdotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnecdotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnecdotalsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      userIds: // value for 'userIds'
 *      referToIds: // value for 'referToIds'
 *      createdByIds: // value for 'createdByIds'
 *      anecdotalTypeIds: // value for 'anecdotalTypeIds'
 *      severities: // value for 'severities'
 *   },
 * });
 */
export function useGetAnecdotalsQuery(baseOptions: Apollo.QueryHookOptions<GetAnecdotalsQuery, GetAnecdotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnecdotalsQuery, GetAnecdotalsQueryVariables>(GetAnecdotalsDocument, options);
      }
export function useGetAnecdotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnecdotalsQuery, GetAnecdotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnecdotalsQuery, GetAnecdotalsQueryVariables>(GetAnecdotalsDocument, options);
        }
export type GetAnecdotalsQueryHookResult = ReturnType<typeof useGetAnecdotalsQuery>;
export type GetAnecdotalsLazyQueryHookResult = ReturnType<typeof useGetAnecdotalsLazyQuery>;
export type GetAnecdotalsQueryResult = Apollo.QueryResult<GetAnecdotalsQuery, GetAnecdotalsQueryVariables>;
export const GetAnecdotalDocument = gql`
    query getAnecdotal($schoolId: String!, $anecdotalId: String!) {
  getAnecdotal(schoolId: $schoolId, anecdotalId: $anecdotalId) {
    ...SingleAnecdotalFields
  }
}
    ${SingleAnecdotalFieldsFragmentDoc}`;

/**
 * __useGetAnecdotalQuery__
 *
 * To run a query within a React component, call `useGetAnecdotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnecdotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnecdotalQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      anecdotalId: // value for 'anecdotalId'
 *   },
 * });
 */
export function useGetAnecdotalQuery(baseOptions: Apollo.QueryHookOptions<GetAnecdotalQuery, GetAnecdotalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnecdotalQuery, GetAnecdotalQueryVariables>(GetAnecdotalDocument, options);
      }
export function useGetAnecdotalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnecdotalQuery, GetAnecdotalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnecdotalQuery, GetAnecdotalQueryVariables>(GetAnecdotalDocument, options);
        }
export type GetAnecdotalQueryHookResult = ReturnType<typeof useGetAnecdotalQuery>;
export type GetAnecdotalLazyQueryHookResult = ReturnType<typeof useGetAnecdotalLazyQuery>;
export type GetAnecdotalQueryResult = Apollo.QueryResult<GetAnecdotalQuery, GetAnecdotalQueryVariables>;
export const CreateAnecdotalTypeDocument = gql`
    mutation createAnecdotalType($data: AnecdotalTypeInput!) {
  createAnecdotalType(input: $data)
}
    `;
export type CreateAnecdotalTypeMutationFn = Apollo.MutationFunction<CreateAnecdotalTypeMutation, CreateAnecdotalTypeMutationVariables>;

/**
 * __useCreateAnecdotalTypeMutation__
 *
 * To run a mutation, you first call `useCreateAnecdotalTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnecdotalTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnecdotalTypeMutation, { data, loading, error }] = useCreateAnecdotalTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAnecdotalTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnecdotalTypeMutation, CreateAnecdotalTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnecdotalTypeMutation, CreateAnecdotalTypeMutationVariables>(CreateAnecdotalTypeDocument, options);
      }
export type CreateAnecdotalTypeMutationHookResult = ReturnType<typeof useCreateAnecdotalTypeMutation>;
export type CreateAnecdotalTypeMutationResult = Apollo.MutationResult<CreateAnecdotalTypeMutation>;
export type CreateAnecdotalTypeMutationOptions = Apollo.BaseMutationOptions<CreateAnecdotalTypeMutation, CreateAnecdotalTypeMutationVariables>;
export const RemoveAnecdotalTypesDocument = gql`
    mutation removeAnecdotalTypes($data: MultipleIdsInput!) {
  removeAnecdotalTypes(input: $data)
}
    `;
export type RemoveAnecdotalTypesMutationFn = Apollo.MutationFunction<RemoveAnecdotalTypesMutation, RemoveAnecdotalTypesMutationVariables>;

/**
 * __useRemoveAnecdotalTypesMutation__
 *
 * To run a mutation, you first call `useRemoveAnecdotalTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAnecdotalTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAnecdotalTypesMutation, { data, loading, error }] = useRemoveAnecdotalTypesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveAnecdotalTypesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAnecdotalTypesMutation, RemoveAnecdotalTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAnecdotalTypesMutation, RemoveAnecdotalTypesMutationVariables>(RemoveAnecdotalTypesDocument, options);
      }
export type RemoveAnecdotalTypesMutationHookResult = ReturnType<typeof useRemoveAnecdotalTypesMutation>;
export type RemoveAnecdotalTypesMutationResult = Apollo.MutationResult<RemoveAnecdotalTypesMutation>;
export type RemoveAnecdotalTypesMutationOptions = Apollo.BaseMutationOptions<RemoveAnecdotalTypesMutation, RemoveAnecdotalTypesMutationVariables>;
export const ReactivateAnecdotalTypesDocument = gql`
    mutation reactivateAnecdotalTypes($data: MultipleIdsInput!) {
  reactivateAnecdotalTypes(input: $data)
}
    `;
export type ReactivateAnecdotalTypesMutationFn = Apollo.MutationFunction<ReactivateAnecdotalTypesMutation, ReactivateAnecdotalTypesMutationVariables>;

/**
 * __useReactivateAnecdotalTypesMutation__
 *
 * To run a mutation, you first call `useReactivateAnecdotalTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateAnecdotalTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateAnecdotalTypesMutation, { data, loading, error }] = useReactivateAnecdotalTypesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReactivateAnecdotalTypesMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateAnecdotalTypesMutation, ReactivateAnecdotalTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateAnecdotalTypesMutation, ReactivateAnecdotalTypesMutationVariables>(ReactivateAnecdotalTypesDocument, options);
      }
export type ReactivateAnecdotalTypesMutationHookResult = ReturnType<typeof useReactivateAnecdotalTypesMutation>;
export type ReactivateAnecdotalTypesMutationResult = Apollo.MutationResult<ReactivateAnecdotalTypesMutation>;
export type ReactivateAnecdotalTypesMutationOptions = Apollo.BaseMutationOptions<ReactivateAnecdotalTypesMutation, ReactivateAnecdotalTypesMutationVariables>;
export const GetAnecdotalTypesDocument = gql`
    query getAnecdotalTypes($schoolId: String!, $after: String, $before: String, $limit: Int = 10, $search: String, $deleted: Boolean) {
  getAnecdotalTypes(
    schoolId: $schoolId
    after: $after
    before: $before
    limit: $limit
    search: $search
    deleted: $deleted
  ) {
    pagination {
      ...PaginationFields
    }
    data {
      ...SingleTypeAnecdotalFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${SingleTypeAnecdotalFieldsFragmentDoc}`;

/**
 * __useGetAnecdotalTypesQuery__
 *
 * To run a query within a React component, call `useGetAnecdotalTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnecdotalTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnecdotalTypesQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      deleted: // value for 'deleted'
 *   },
 * });
 */
export function useGetAnecdotalTypesQuery(baseOptions: Apollo.QueryHookOptions<GetAnecdotalTypesQuery, GetAnecdotalTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnecdotalTypesQuery, GetAnecdotalTypesQueryVariables>(GetAnecdotalTypesDocument, options);
      }
export function useGetAnecdotalTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnecdotalTypesQuery, GetAnecdotalTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnecdotalTypesQuery, GetAnecdotalTypesQueryVariables>(GetAnecdotalTypesDocument, options);
        }
export type GetAnecdotalTypesQueryHookResult = ReturnType<typeof useGetAnecdotalTypesQuery>;
export type GetAnecdotalTypesLazyQueryHookResult = ReturnType<typeof useGetAnecdotalTypesLazyQuery>;
export type GetAnecdotalTypesQueryResult = Apollo.QueryResult<GetAnecdotalTypesQuery, GetAnecdotalTypesQueryVariables>;
export const CreateAssignmentDocument = gql`
    mutation createAssignment($data: BulkAssignmentInput!) {
  createAssignment(input: $data)
}
    `;
export type CreateAssignmentMutationFn = Apollo.MutationFunction<CreateAssignmentMutation, CreateAssignmentMutationVariables>;

/**
 * __useCreateAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignmentMutation, { data, loading, error }] = useCreateAssignmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssignmentMutation, CreateAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssignmentMutation, CreateAssignmentMutationVariables>(CreateAssignmentDocument, options);
      }
export type CreateAssignmentMutationHookResult = ReturnType<typeof useCreateAssignmentMutation>;
export type CreateAssignmentMutationResult = Apollo.MutationResult<CreateAssignmentMutation>;
export type CreateAssignmentMutationOptions = Apollo.BaseMutationOptions<CreateAssignmentMutation, CreateAssignmentMutationVariables>;
export const UpdateAssignmentDocument = gql`
    mutation updateAssignment($id: String!, $data: AssignmentInput!) {
  updateAssignment(id: $id, input: $data) {
    ...SingleTeacherAssignmentFields
  }
}
    ${SingleTeacherAssignmentFieldsFragmentDoc}`;
export type UpdateAssignmentMutationFn = Apollo.MutationFunction<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>;

/**
 * __useUpdateAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentMutation, { data, loading, error }] = useUpdateAssignmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>(UpdateAssignmentDocument, options);
      }
export type UpdateAssignmentMutationHookResult = ReturnType<typeof useUpdateAssignmentMutation>;
export type UpdateAssignmentMutationResult = Apollo.MutationResult<UpdateAssignmentMutation>;
export type UpdateAssignmentMutationOptions = Apollo.BaseMutationOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>;
export const BulkAssignmentStatusUpdateDocument = gql`
    mutation bulkAssignmentStatusUpdate($data: MultipleIdsInput!, $status: AssignmentStatusEnum!) {
  bulkAssignmentStatusUpdate(input: $data, status: $status)
}
    `;
export type BulkAssignmentStatusUpdateMutationFn = Apollo.MutationFunction<BulkAssignmentStatusUpdateMutation, BulkAssignmentStatusUpdateMutationVariables>;

/**
 * __useBulkAssignmentStatusUpdateMutation__
 *
 * To run a mutation, you first call `useBulkAssignmentStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAssignmentStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAssignmentStatusUpdateMutation, { data, loading, error }] = useBulkAssignmentStatusUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useBulkAssignmentStatusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BulkAssignmentStatusUpdateMutation, BulkAssignmentStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkAssignmentStatusUpdateMutation, BulkAssignmentStatusUpdateMutationVariables>(BulkAssignmentStatusUpdateDocument, options);
      }
export type BulkAssignmentStatusUpdateMutationHookResult = ReturnType<typeof useBulkAssignmentStatusUpdateMutation>;
export type BulkAssignmentStatusUpdateMutationResult = Apollo.MutationResult<BulkAssignmentStatusUpdateMutation>;
export type BulkAssignmentStatusUpdateMutationOptions = Apollo.BaseMutationOptions<BulkAssignmentStatusUpdateMutation, BulkAssignmentStatusUpdateMutationVariables>;
export const GetAssignmentsDocument = gql`
    query getAssignments($classId: String!, $markingPeriodId: String, $search: String, $status: AssignmentStatusEnum, $categoryId: String, $userStatus: Boolean) {
  getAssignments(
    classId: $classId
    search: $search
    markingPeriodId: $markingPeriodId
    status: $status
    categoryId: $categoryId
    userStatus: $userStatus
  ) {
    ...TableTeacherAssignmentFields
  }
}
    ${TableTeacherAssignmentFieldsFragmentDoc}`;

/**
 * __useGetAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      markingPeriodId: // value for 'markingPeriodId'
 *      search: // value for 'search'
 *      status: // value for 'status'
 *      categoryId: // value for 'categoryId'
 *      userStatus: // value for 'userStatus'
 *   },
 * });
 */
export function useGetAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentsQuery, GetAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentsQuery, GetAssignmentsQueryVariables>(GetAssignmentsDocument, options);
      }
export function useGetAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentsQuery, GetAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentsQuery, GetAssignmentsQueryVariables>(GetAssignmentsDocument, options);
        }
export type GetAssignmentsQueryHookResult = ReturnType<typeof useGetAssignmentsQuery>;
export type GetAssignmentsLazyQueryHookResult = ReturnType<typeof useGetAssignmentsLazyQuery>;
export type GetAssignmentsQueryResult = Apollo.QueryResult<GetAssignmentsQuery, GetAssignmentsQueryVariables>;
export const GetAssignmentDocument = gql`
    query getAssignment($schoolId: String!, $id: String!) {
  getAssignment(schoolId: $schoolId, assignmentId: $id) {
    ...SingleTeacherAssignmentFields
  }
}
    ${SingleTeacherAssignmentFieldsFragmentDoc}`;

/**
 * __useGetAssignmentQuery__
 *
 * To run a query within a React component, call `useGetAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssignmentQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, options);
      }
export function useGetAssignmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, options);
        }
export type GetAssignmentQueryHookResult = ReturnType<typeof useGetAssignmentQuery>;
export type GetAssignmentLazyQueryHookResult = ReturnType<typeof useGetAssignmentLazyQuery>;
export type GetAssignmentQueryResult = Apollo.QueryResult<GetAssignmentQuery, GetAssignmentQueryVariables>;
export const GetStudentAssignmentsDocument = gql`
    query getStudentAssignments($classId: String!, $userId: String!, $markingPeriodId: String!, $search: String, $categoryId: String) {
  getStudentAssignments(
    classId: $classId
    userId: $userId
    markingPeriodId: $markingPeriodId
    search: $search
    categoryId: $categoryId
  ) {
    assignment {
      ...TableStudentAssignmentFields
    }
    submission {
      ...SubmissionFields
    }
    userPoints
  }
}
    ${TableStudentAssignmentFieldsFragmentDoc}
${SubmissionFieldsFragmentDoc}`;

/**
 * __useGetStudentAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetStudentAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentAssignmentsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      userId: // value for 'userId'
 *      markingPeriodId: // value for 'markingPeriodId'
 *      search: // value for 'search'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetStudentAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<GetStudentAssignmentsQuery, GetStudentAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentAssignmentsQuery, GetStudentAssignmentsQueryVariables>(GetStudentAssignmentsDocument, options);
      }
export function useGetStudentAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentAssignmentsQuery, GetStudentAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentAssignmentsQuery, GetStudentAssignmentsQueryVariables>(GetStudentAssignmentsDocument, options);
        }
export type GetStudentAssignmentsQueryHookResult = ReturnType<typeof useGetStudentAssignmentsQuery>;
export type GetStudentAssignmentsLazyQueryHookResult = ReturnType<typeof useGetStudentAssignmentsLazyQuery>;
export type GetStudentAssignmentsQueryResult = Apollo.QueryResult<GetStudentAssignmentsQuery, GetStudentAssignmentsQueryVariables>;
export const GetAttendanceStatusesDocument = gql`
    query getAttendanceStatuses($deleted: Boolean) {
  getAttendanceStatuses(deleted: $deleted) {
    ...AttendanceStatusFields
  }
}
    ${AttendanceStatusFieldsFragmentDoc}`;

/**
 * __useGetAttendanceStatusesQuery__
 *
 * To run a query within a React component, call `useGetAttendanceStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceStatusesQuery({
 *   variables: {
 *      deleted: // value for 'deleted'
 *   },
 * });
 */
export function useGetAttendanceStatusesQuery(baseOptions?: Apollo.QueryHookOptions<GetAttendanceStatusesQuery, GetAttendanceStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttendanceStatusesQuery, GetAttendanceStatusesQueryVariables>(GetAttendanceStatusesDocument, options);
      }
export function useGetAttendanceStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttendanceStatusesQuery, GetAttendanceStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttendanceStatusesQuery, GetAttendanceStatusesQueryVariables>(GetAttendanceStatusesDocument, options);
        }
export type GetAttendanceStatusesQueryHookResult = ReturnType<typeof useGetAttendanceStatusesQuery>;
export type GetAttendanceStatusesLazyQueryHookResult = ReturnType<typeof useGetAttendanceStatusesLazyQuery>;
export type GetAttendanceStatusesQueryResult = Apollo.QueryResult<GetAttendanceStatusesQuery, GetAttendanceStatusesQueryVariables>;
export const SendMagicLinkDocument = gql`
    mutation sendMagicLink($data: MagicLoginInput!) {
  sendMagicLink(input: $data)
}
    `;
export type SendMagicLinkMutationFn = Apollo.MutationFunction<SendMagicLinkMutation, SendMagicLinkMutationVariables>;

/**
 * __useSendMagicLinkMutation__
 *
 * To run a mutation, you first call `useSendMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMagicLinkMutation, { data, loading, error }] = useSendMagicLinkMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendMagicLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendMagicLinkMutation, SendMagicLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMagicLinkMutation, SendMagicLinkMutationVariables>(SendMagicLinkDocument, options);
      }
export type SendMagicLinkMutationHookResult = ReturnType<typeof useSendMagicLinkMutation>;
export type SendMagicLinkMutationResult = Apollo.MutationResult<SendMagicLinkMutation>;
export type SendMagicLinkMutationOptions = Apollo.BaseMutationOptions<SendMagicLinkMutation, SendMagicLinkMutationVariables>;
export const DistrictAuthDocument = gql`
    mutation districtAuth($data: DistrictLoginInput!) {
  districtAuth(input: $data) {
    token
  }
}
    `;
export type DistrictAuthMutationFn = Apollo.MutationFunction<DistrictAuthMutation, DistrictAuthMutationVariables>;

/**
 * __useDistrictAuthMutation__
 *
 * To run a mutation, you first call `useDistrictAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDistrictAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [districtAuthMutation, { data, loading, error }] = useDistrictAuthMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDistrictAuthMutation(baseOptions?: Apollo.MutationHookOptions<DistrictAuthMutation, DistrictAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DistrictAuthMutation, DistrictAuthMutationVariables>(DistrictAuthDocument, options);
      }
export type DistrictAuthMutationHookResult = ReturnType<typeof useDistrictAuthMutation>;
export type DistrictAuthMutationResult = Apollo.MutationResult<DistrictAuthMutation>;
export type DistrictAuthMutationOptions = Apollo.BaseMutationOptions<DistrictAuthMutation, DistrictAuthMutationVariables>;
export const MagicAuthDocument = gql`
    mutation magicAuth($data: ValidateAuthTokenInput!) {
  magicAuth(input: $data) {
    token
  }
}
    `;
export type MagicAuthMutationFn = Apollo.MutationFunction<MagicAuthMutation, MagicAuthMutationVariables>;

/**
 * __useMagicAuthMutation__
 *
 * To run a mutation, you first call `useMagicAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMagicAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [magicAuthMutation, { data, loading, error }] = useMagicAuthMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMagicAuthMutation(baseOptions?: Apollo.MutationHookOptions<MagicAuthMutation, MagicAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MagicAuthMutation, MagicAuthMutationVariables>(MagicAuthDocument, options);
      }
export type MagicAuthMutationHookResult = ReturnType<typeof useMagicAuthMutation>;
export type MagicAuthMutationResult = Apollo.MutationResult<MagicAuthMutation>;
export type MagicAuthMutationOptions = Apollo.BaseMutationOptions<MagicAuthMutation, MagicAuthMutationVariables>;
export const ExternalAuthDocument = gql`
    mutation externalAuth($data: GoogleAuthTokenInput!) {
  externalAuth(input: $data) {
    token
  }
}
    `;
export type ExternalAuthMutationFn = Apollo.MutationFunction<ExternalAuthMutation, ExternalAuthMutationVariables>;

/**
 * __useExternalAuthMutation__
 *
 * To run a mutation, you first call `useExternalAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExternalAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [externalAuthMutation, { data, loading, error }] = useExternalAuthMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExternalAuthMutation(baseOptions?: Apollo.MutationHookOptions<ExternalAuthMutation, ExternalAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExternalAuthMutation, ExternalAuthMutationVariables>(ExternalAuthDocument, options);
      }
export type ExternalAuthMutationHookResult = ReturnType<typeof useExternalAuthMutation>;
export type ExternalAuthMutationResult = Apollo.MutationResult<ExternalAuthMutation>;
export type ExternalAuthMutationOptions = Apollo.BaseMutationOptions<ExternalAuthMutation, ExternalAuthMutationVariables>;
export const ForgottenPasswordDocument = gql`
    mutation forgottenPassword($data: ForgotPasswordInput!) {
  forgotPassword(input: $data)
}
    `;
export type ForgottenPasswordMutationFn = Apollo.MutationFunction<ForgottenPasswordMutation, ForgottenPasswordMutationVariables>;

/**
 * __useForgottenPasswordMutation__
 *
 * To run a mutation, you first call `useForgottenPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgottenPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgottenPasswordMutation, { data, loading, error }] = useForgottenPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useForgottenPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgottenPasswordMutation, ForgottenPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgottenPasswordMutation, ForgottenPasswordMutationVariables>(ForgottenPasswordDocument, options);
      }
export type ForgottenPasswordMutationHookResult = ReturnType<typeof useForgottenPasswordMutation>;
export type ForgottenPasswordMutationResult = Apollo.MutationResult<ForgottenPasswordMutation>;
export type ForgottenPasswordMutationOptions = Apollo.BaseMutationOptions<ForgottenPasswordMutation, ForgottenPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($data: ResetPasswordInput!) {
  resetPassword(input: $data)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const GetGoogleAuthLinkDocument = gql`
    query getGoogleAuthLink {
  getGoogleAuthLink
}
    `;

/**
 * __useGetGoogleAuthLinkQuery__
 *
 * To run a query within a React component, call `useGetGoogleAuthLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleAuthLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleAuthLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGoogleAuthLinkQuery(baseOptions?: Apollo.QueryHookOptions<GetGoogleAuthLinkQuery, GetGoogleAuthLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoogleAuthLinkQuery, GetGoogleAuthLinkQueryVariables>(GetGoogleAuthLinkDocument, options);
      }
export function useGetGoogleAuthLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoogleAuthLinkQuery, GetGoogleAuthLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoogleAuthLinkQuery, GetGoogleAuthLinkQueryVariables>(GetGoogleAuthLinkDocument, options);
        }
export type GetGoogleAuthLinkQueryHookResult = ReturnType<typeof useGetGoogleAuthLinkQuery>;
export type GetGoogleAuthLinkLazyQueryHookResult = ReturnType<typeof useGetGoogleAuthLinkLazyQuery>;
export type GetGoogleAuthLinkQueryResult = Apollo.QueryResult<GetGoogleAuthLinkQuery, GetGoogleAuthLinkQueryVariables>;
export const GetCleverAuthLinkDocument = gql`
    query getCleverAuthLink {
  getCleverAuthLink
}
    `;

/**
 * __useGetCleverAuthLinkQuery__
 *
 * To run a query within a React component, call `useGetCleverAuthLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCleverAuthLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCleverAuthLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCleverAuthLinkQuery(baseOptions?: Apollo.QueryHookOptions<GetCleverAuthLinkQuery, GetCleverAuthLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCleverAuthLinkQuery, GetCleverAuthLinkQueryVariables>(GetCleverAuthLinkDocument, options);
      }
export function useGetCleverAuthLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCleverAuthLinkQuery, GetCleverAuthLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCleverAuthLinkQuery, GetCleverAuthLinkQueryVariables>(GetCleverAuthLinkDocument, options);
        }
export type GetCleverAuthLinkQueryHookResult = ReturnType<typeof useGetCleverAuthLinkQuery>;
export type GetCleverAuthLinkLazyQueryHookResult = ReturnType<typeof useGetCleverAuthLinkLazyQuery>;
export type GetCleverAuthLinkQueryResult = Apollo.QueryResult<GetCleverAuthLinkQuery, GetCleverAuthLinkQueryVariables>;
export const ValidateGoogleAuthDocument = gql`
    query validateGoogleAuth($code: String!) {
  validateGoogleAuth(code: $code) {
    ...ValidateMagicLinkFields
  }
}
    ${ValidateMagicLinkFieldsFragmentDoc}`;

/**
 * __useValidateGoogleAuthQuery__
 *
 * To run a query within a React component, call `useValidateGoogleAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateGoogleAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateGoogleAuthQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidateGoogleAuthQuery(baseOptions: Apollo.QueryHookOptions<ValidateGoogleAuthQuery, ValidateGoogleAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateGoogleAuthQuery, ValidateGoogleAuthQueryVariables>(ValidateGoogleAuthDocument, options);
      }
export function useValidateGoogleAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateGoogleAuthQuery, ValidateGoogleAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateGoogleAuthQuery, ValidateGoogleAuthQueryVariables>(ValidateGoogleAuthDocument, options);
        }
export type ValidateGoogleAuthQueryHookResult = ReturnType<typeof useValidateGoogleAuthQuery>;
export type ValidateGoogleAuthLazyQueryHookResult = ReturnType<typeof useValidateGoogleAuthLazyQuery>;
export type ValidateGoogleAuthQueryResult = Apollo.QueryResult<ValidateGoogleAuthQuery, ValidateGoogleAuthQueryVariables>;
export const ValidateCleverAuthDocument = gql`
    query validateCleverAuth($code: String!) {
  validateCleverAuth(code: $code) {
    ...ValidateMagicLinkFields
  }
}
    ${ValidateMagicLinkFieldsFragmentDoc}`;

/**
 * __useValidateCleverAuthQuery__
 *
 * To run a query within a React component, call `useValidateCleverAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateCleverAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateCleverAuthQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidateCleverAuthQuery(baseOptions: Apollo.QueryHookOptions<ValidateCleverAuthQuery, ValidateCleverAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateCleverAuthQuery, ValidateCleverAuthQueryVariables>(ValidateCleverAuthDocument, options);
      }
export function useValidateCleverAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateCleverAuthQuery, ValidateCleverAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateCleverAuthQuery, ValidateCleverAuthQueryVariables>(ValidateCleverAuthDocument, options);
        }
export type ValidateCleverAuthQueryHookResult = ReturnType<typeof useValidateCleverAuthQuery>;
export type ValidateCleverAuthLazyQueryHookResult = ReturnType<typeof useValidateCleverAuthLazyQuery>;
export type ValidateCleverAuthQueryResult = Apollo.QueryResult<ValidateCleverAuthQuery, ValidateCleverAuthQueryVariables>;
export const ValidateMagicLinkDocument = gql`
    query validateMagicLink($token: String!) {
  validateMagicLink(token: $token) {
    ...ValidateMagicLinkFields
  }
}
    ${ValidateMagicLinkFieldsFragmentDoc}`;

/**
 * __useValidateMagicLinkQuery__
 *
 * To run a query within a React component, call `useValidateMagicLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateMagicLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateMagicLinkQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidateMagicLinkQuery(baseOptions: Apollo.QueryHookOptions<ValidateMagicLinkQuery, ValidateMagicLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateMagicLinkQuery, ValidateMagicLinkQueryVariables>(ValidateMagicLinkDocument, options);
      }
export function useValidateMagicLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateMagicLinkQuery, ValidateMagicLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateMagicLinkQuery, ValidateMagicLinkQueryVariables>(ValidateMagicLinkDocument, options);
        }
export type ValidateMagicLinkQueryHookResult = ReturnType<typeof useValidateMagicLinkQuery>;
export type ValidateMagicLinkLazyQueryHookResult = ReturnType<typeof useValidateMagicLinkLazyQuery>;
export type ValidateMagicLinkQueryResult = Apollo.QueryResult<ValidateMagicLinkQuery, ValidateMagicLinkQueryVariables>;
export const MeDocument = gql`
    query me($districtId: String!) {
  me(districtId: $districtId) {
    ...MeFields
  }
}
    ${MeFieldsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *   },
 * });
 */
export function useMeQuery(baseOptions: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SaveClassAttendanceDocument = gql`
    mutation saveClassAttendance($data: AttendanceClassInput!) {
  saveClassAttendance(input: $data) {
    ...ClassAttendanceFields
  }
}
    ${ClassAttendanceFieldsFragmentDoc}`;
export type SaveClassAttendanceMutationFn = Apollo.MutationFunction<SaveClassAttendanceMutation, SaveClassAttendanceMutationVariables>;

/**
 * __useSaveClassAttendanceMutation__
 *
 * To run a mutation, you first call `useSaveClassAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveClassAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveClassAttendanceMutation, { data, loading, error }] = useSaveClassAttendanceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveClassAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<SaveClassAttendanceMutation, SaveClassAttendanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveClassAttendanceMutation, SaveClassAttendanceMutationVariables>(SaveClassAttendanceDocument, options);
      }
export type SaveClassAttendanceMutationHookResult = ReturnType<typeof useSaveClassAttendanceMutation>;
export type SaveClassAttendanceMutationResult = Apollo.MutationResult<SaveClassAttendanceMutation>;
export type SaveClassAttendanceMutationOptions = Apollo.BaseMutationOptions<SaveClassAttendanceMutation, SaveClassAttendanceMutationVariables>;
export const GetClassAttendanceDocument = gql`
    query getClassAttendance($classId: String!, $date: String!, $search: String) {
  getClassAttendance(classId: $classId, date: $date, search: $search) {
    ...ClassAttendanceFields
  }
}
    ${ClassAttendanceFieldsFragmentDoc}`;

/**
 * __useGetClassAttendanceQuery__
 *
 * To run a query within a React component, call `useGetClassAttendanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassAttendanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassAttendanceQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      date: // value for 'date'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetClassAttendanceQuery(baseOptions: Apollo.QueryHookOptions<GetClassAttendanceQuery, GetClassAttendanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassAttendanceQuery, GetClassAttendanceQueryVariables>(GetClassAttendanceDocument, options);
      }
export function useGetClassAttendanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassAttendanceQuery, GetClassAttendanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassAttendanceQuery, GetClassAttendanceQueryVariables>(GetClassAttendanceDocument, options);
        }
export type GetClassAttendanceQueryHookResult = ReturnType<typeof useGetClassAttendanceQuery>;
export type GetClassAttendanceLazyQueryHookResult = ReturnType<typeof useGetClassAttendanceLazyQuery>;
export type GetClassAttendanceQueryResult = Apollo.QueryResult<GetClassAttendanceQuery, GetClassAttendanceQueryVariables>;
export const CreateClassDocument = gql`
    mutation createClass($schoolId: String!, $data: ClassInput!) {
  createClass(schoolId: $schoolId, input: $data) {
    ...SingleClassFields
  }
}
    ${SingleClassFieldsFragmentDoc}`;
export type CreateClassMutationFn = Apollo.MutationFunction<CreateClassMutation, CreateClassMutationVariables>;

/**
 * __useCreateClassMutation__
 *
 * To run a mutation, you first call `useCreateClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassMutation, { data, loading, error }] = useCreateClassMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateClassMutation(baseOptions?: Apollo.MutationHookOptions<CreateClassMutation, CreateClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClassMutation, CreateClassMutationVariables>(CreateClassDocument, options);
      }
export type CreateClassMutationHookResult = ReturnType<typeof useCreateClassMutation>;
export type CreateClassMutationResult = Apollo.MutationResult<CreateClassMutation>;
export type CreateClassMutationOptions = Apollo.BaseMutationOptions<CreateClassMutation, CreateClassMutationVariables>;
export const UpdateClassDocument = gql`
    mutation updateClass($id: String!, $data: UpdateClassInput!) {
  updateClass(id: $id, input: $data) {
    ...SingleClassFields
  }
}
    ${SingleClassFieldsFragmentDoc}`;
export type UpdateClassMutationFn = Apollo.MutationFunction<UpdateClassMutation, UpdateClassMutationVariables>;

/**
 * __useUpdateClassMutation__
 *
 * To run a mutation, you first call `useUpdateClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassMutation, { data, loading, error }] = useUpdateClassMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateClassMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClassMutation, UpdateClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClassMutation, UpdateClassMutationVariables>(UpdateClassDocument, options);
      }
export type UpdateClassMutationHookResult = ReturnType<typeof useUpdateClassMutation>;
export type UpdateClassMutationResult = Apollo.MutationResult<UpdateClassMutation>;
export type UpdateClassMutationOptions = Apollo.BaseMutationOptions<UpdateClassMutation, UpdateClassMutationVariables>;
export const ReactivateClassesDocument = gql`
    mutation reactivateClasses($data: MultipleIdsInput!) {
  reactivateClasses(input: $data)
}
    `;
export type ReactivateClassesMutationFn = Apollo.MutationFunction<ReactivateClassesMutation, ReactivateClassesMutationVariables>;

/**
 * __useReactivateClassesMutation__
 *
 * To run a mutation, you first call `useReactivateClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateClassesMutation, { data, loading, error }] = useReactivateClassesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReactivateClassesMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateClassesMutation, ReactivateClassesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateClassesMutation, ReactivateClassesMutationVariables>(ReactivateClassesDocument, options);
      }
export type ReactivateClassesMutationHookResult = ReturnType<typeof useReactivateClassesMutation>;
export type ReactivateClassesMutationResult = Apollo.MutationResult<ReactivateClassesMutation>;
export type ReactivateClassesMutationOptions = Apollo.BaseMutationOptions<ReactivateClassesMutation, ReactivateClassesMutationVariables>;
export const RemoveClassesDocument = gql`
    mutation removeClasses($data: MultipleIdsInput!) {
  removeClasses(input: $data)
}
    `;
export type RemoveClassesMutationFn = Apollo.MutationFunction<RemoveClassesMutation, RemoveClassesMutationVariables>;

/**
 * __useRemoveClassesMutation__
 *
 * To run a mutation, you first call `useRemoveClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClassesMutation, { data, loading, error }] = useRemoveClassesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveClassesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveClassesMutation, RemoveClassesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveClassesMutation, RemoveClassesMutationVariables>(RemoveClassesDocument, options);
      }
export type RemoveClassesMutationHookResult = ReturnType<typeof useRemoveClassesMutation>;
export type RemoveClassesMutationResult = Apollo.MutationResult<RemoveClassesMutation>;
export type RemoveClassesMutationOptions = Apollo.BaseMutationOptions<RemoveClassesMutation, RemoveClassesMutationVariables>;
export const ToggleClassMarkingPeriodsDocument = gql`
    mutation toggleClassMarkingPeriods($data: UpdateMPClassInput!) {
  toggleClassMarkingPeriods(input: $data) {
    id
    markingPeriods {
      ...MarkingPeriodFields
    }
  }
}
    ${MarkingPeriodFieldsFragmentDoc}`;
export type ToggleClassMarkingPeriodsMutationFn = Apollo.MutationFunction<ToggleClassMarkingPeriodsMutation, ToggleClassMarkingPeriodsMutationVariables>;

/**
 * __useToggleClassMarkingPeriodsMutation__
 *
 * To run a mutation, you first call `useToggleClassMarkingPeriodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleClassMarkingPeriodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleClassMarkingPeriodsMutation, { data, loading, error }] = useToggleClassMarkingPeriodsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useToggleClassMarkingPeriodsMutation(baseOptions?: Apollo.MutationHookOptions<ToggleClassMarkingPeriodsMutation, ToggleClassMarkingPeriodsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleClassMarkingPeriodsMutation, ToggleClassMarkingPeriodsMutationVariables>(ToggleClassMarkingPeriodsDocument, options);
      }
export type ToggleClassMarkingPeriodsMutationHookResult = ReturnType<typeof useToggleClassMarkingPeriodsMutation>;
export type ToggleClassMarkingPeriodsMutationResult = Apollo.MutationResult<ToggleClassMarkingPeriodsMutation>;
export type ToggleClassMarkingPeriodsMutationOptions = Apollo.BaseMutationOptions<ToggleClassMarkingPeriodsMutation, ToggleClassMarkingPeriodsMutationVariables>;
export const AddUsersToClassDocument = gql`
    mutation addUsersToClass($id: String!, $data: MultipleIdsInput!) {
  addUsersToClass(id: $id, input: $data) {
    ...SingleClassFields
  }
}
    ${SingleClassFieldsFragmentDoc}`;
export type AddUsersToClassMutationFn = Apollo.MutationFunction<AddUsersToClassMutation, AddUsersToClassMutationVariables>;

/**
 * __useAddUsersToClassMutation__
 *
 * To run a mutation, you first call `useAddUsersToClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToClassMutation, { data, loading, error }] = useAddUsersToClassMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddUsersToClassMutation(baseOptions?: Apollo.MutationHookOptions<AddUsersToClassMutation, AddUsersToClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUsersToClassMutation, AddUsersToClassMutationVariables>(AddUsersToClassDocument, options);
      }
export type AddUsersToClassMutationHookResult = ReturnType<typeof useAddUsersToClassMutation>;
export type AddUsersToClassMutationResult = Apollo.MutationResult<AddUsersToClassMutation>;
export type AddUsersToClassMutationOptions = Apollo.BaseMutationOptions<AddUsersToClassMutation, AddUsersToClassMutationVariables>;
export const RemoveUsersFromClassDocument = gql`
    mutation removeUsersFromClass($id: String!, $data: MultipleIdsInput!) {
  removeUsersFromClass(id: $id, input: $data) {
    ...SingleClassFields
  }
}
    ${SingleClassFieldsFragmentDoc}`;
export type RemoveUsersFromClassMutationFn = Apollo.MutationFunction<RemoveUsersFromClassMutation, RemoveUsersFromClassMutationVariables>;

/**
 * __useRemoveUsersFromClassMutation__
 *
 * To run a mutation, you first call `useRemoveUsersFromClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUsersFromClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUsersFromClassMutation, { data, loading, error }] = useRemoveUsersFromClassMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveUsersFromClassMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUsersFromClassMutation, RemoveUsersFromClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUsersFromClassMutation, RemoveUsersFromClassMutationVariables>(RemoveUsersFromClassDocument, options);
      }
export type RemoveUsersFromClassMutationHookResult = ReturnType<typeof useRemoveUsersFromClassMutation>;
export type RemoveUsersFromClassMutationResult = Apollo.MutationResult<RemoveUsersFromClassMutation>;
export type RemoveUsersFromClassMutationOptions = Apollo.BaseMutationOptions<RemoveUsersFromClassMutation, RemoveUsersFromClassMutationVariables>;
export const ReconnectUserSyncInClassDocument = gql`
    mutation reconnectUserSyncInClass($id: String!, $data: MultipleIdsInput!) {
  reconnectUserSyncInClass(id: $id, input: $data) {
    ...SingleClassFields
  }
}
    ${SingleClassFieldsFragmentDoc}`;
export type ReconnectUserSyncInClassMutationFn = Apollo.MutationFunction<ReconnectUserSyncInClassMutation, ReconnectUserSyncInClassMutationVariables>;

/**
 * __useReconnectUserSyncInClassMutation__
 *
 * To run a mutation, you first call `useReconnectUserSyncInClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReconnectUserSyncInClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reconnectUserSyncInClassMutation, { data, loading, error }] = useReconnectUserSyncInClassMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReconnectUserSyncInClassMutation(baseOptions?: Apollo.MutationHookOptions<ReconnectUserSyncInClassMutation, ReconnectUserSyncInClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReconnectUserSyncInClassMutation, ReconnectUserSyncInClassMutationVariables>(ReconnectUserSyncInClassDocument, options);
      }
export type ReconnectUserSyncInClassMutationHookResult = ReturnType<typeof useReconnectUserSyncInClassMutation>;
export type ReconnectUserSyncInClassMutationResult = Apollo.MutationResult<ReconnectUserSyncInClassMutation>;
export type ReconnectUserSyncInClassMutationOptions = Apollo.BaseMutationOptions<ReconnectUserSyncInClassMutation, ReconnectUserSyncInClassMutationVariables>;
export const GetClassesDocument = gql`
    query getClasses($schoolId: String!, $after: String, $before: String, $limit: Int = 10, $search: String, $deleted: Boolean, $markingPeriodId: String) {
  getClasses(
    schoolId: $schoolId
    after: $after
    before: $before
    limit: $limit
    search: $search
    deleted: $deleted
    markingPeriodId: $markingPeriodId
  ) {
    pagination {
      ...PaginationFields
    }
    data {
      ...TableClassFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${TableClassFieldsFragmentDoc}`;

/**
 * __useGetClassesQuery__
 *
 * To run a query within a React component, call `useGetClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassesQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      deleted: // value for 'deleted'
 *      markingPeriodId: // value for 'markingPeriodId'
 *   },
 * });
 */
export function useGetClassesQuery(baseOptions: Apollo.QueryHookOptions<GetClassesQuery, GetClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassesQuery, GetClassesQueryVariables>(GetClassesDocument, options);
      }
export function useGetClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassesQuery, GetClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassesQuery, GetClassesQueryVariables>(GetClassesDocument, options);
        }
export type GetClassesQueryHookResult = ReturnType<typeof useGetClassesQuery>;
export type GetClassesLazyQueryHookResult = ReturnType<typeof useGetClassesLazyQuery>;
export type GetClassesQueryResult = Apollo.QueryResult<GetClassesQuery, GetClassesQueryVariables>;
export const GetClassesSettingsDocument = gql`
    query getClassesSettings($schoolId: String!, $after: String, $before: String, $limit: Int = 10, $search: String, $deleted: Boolean) {
  getClasses(
    schoolId: $schoolId
    after: $after
    before: $before
    limit: $limit
    search: $search
    deleted: $deleted
  ) {
    pagination {
      ...PaginationFields
    }
    data {
      id
      name
      markingPeriods {
        id
        name
      }
    }
  }
}
    ${PaginationFieldsFragmentDoc}`;

/**
 * __useGetClassesSettingsQuery__
 *
 * To run a query within a React component, call `useGetClassesSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassesSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassesSettingsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      deleted: // value for 'deleted'
 *   },
 * });
 */
export function useGetClassesSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetClassesSettingsQuery, GetClassesSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassesSettingsQuery, GetClassesSettingsQueryVariables>(GetClassesSettingsDocument, options);
      }
export function useGetClassesSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassesSettingsQuery, GetClassesSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassesSettingsQuery, GetClassesSettingsQueryVariables>(GetClassesSettingsDocument, options);
        }
export type GetClassesSettingsQueryHookResult = ReturnType<typeof useGetClassesSettingsQuery>;
export type GetClassesSettingsLazyQueryHookResult = ReturnType<typeof useGetClassesSettingsLazyQuery>;
export type GetClassesSettingsQueryResult = Apollo.QueryResult<GetClassesSettingsQuery, GetClassesSettingsQueryVariables>;
export const GetClassesByUserDocument = gql`
    query getClassesByUser($schoolId: String!, $userId: String!, $after: String, $before: String, $limit: Int = 10, $search: String, $deleted: Boolean, $markingPeriodId: String) {
  getClassesByUser(
    schoolId: $schoolId
    userId: $userId
    after: $after
    before: $before
    limit: $limit
    search: $search
    deleted: $deleted
    markingPeriodId: $markingPeriodId
  ) {
    pagination {
      ...PaginationFields
    }
    data {
      ...TableClassFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${TableClassFieldsFragmentDoc}`;

/**
 * __useGetClassesByUserQuery__
 *
 * To run a query within a React component, call `useGetClassesByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassesByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassesByUserQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      userId: // value for 'userId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      deleted: // value for 'deleted'
 *      markingPeriodId: // value for 'markingPeriodId'
 *   },
 * });
 */
export function useGetClassesByUserQuery(baseOptions: Apollo.QueryHookOptions<GetClassesByUserQuery, GetClassesByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassesByUserQuery, GetClassesByUserQueryVariables>(GetClassesByUserDocument, options);
      }
export function useGetClassesByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassesByUserQuery, GetClassesByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassesByUserQuery, GetClassesByUserQueryVariables>(GetClassesByUserDocument, options);
        }
export type GetClassesByUserQueryHookResult = ReturnType<typeof useGetClassesByUserQuery>;
export type GetClassesByUserLazyQueryHookResult = ReturnType<typeof useGetClassesByUserLazyQuery>;
export type GetClassesByUserQueryResult = Apollo.QueryResult<GetClassesByUserQuery, GetClassesByUserQueryVariables>;
export const GetClassDocument = gql`
    query getClass($schoolId: String!, $id: String!) {
  getClass(schoolId: $schoolId, id: $id) {
    ...SingleClassFields
  }
}
    ${SingleClassFieldsFragmentDoc}`;

/**
 * __useGetClassQuery__
 *
 * To run a query within a React component, call `useGetClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClassQuery(baseOptions: Apollo.QueryHookOptions<GetClassQuery, GetClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassQuery, GetClassQueryVariables>(GetClassDocument, options);
      }
export function useGetClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassQuery, GetClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassQuery, GetClassQueryVariables>(GetClassDocument, options);
        }
export type GetClassQueryHookResult = ReturnType<typeof useGetClassQuery>;
export type GetClassLazyQueryHookResult = ReturnType<typeof useGetClassLazyQuery>;
export type GetClassQueryResult = Apollo.QueryResult<GetClassQuery, GetClassQueryVariables>;
export const ImportParseAssignmentGradesDocument = gql`
    mutation importParseAssignmentGrades($classId: String!, $link: String!) {
  importParseAssignmentGrades(classId: $classId, link: $link) {
    ...ImportGradesFields
  }
}
    ${ImportGradesFieldsFragmentDoc}`;
export type ImportParseAssignmentGradesMutationFn = Apollo.MutationFunction<ImportParseAssignmentGradesMutation, ImportParseAssignmentGradesMutationVariables>;

/**
 * __useImportParseAssignmentGradesMutation__
 *
 * To run a mutation, you first call `useImportParseAssignmentGradesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportParseAssignmentGradesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importParseAssignmentGradesMutation, { data, loading, error }] = useImportParseAssignmentGradesMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useImportParseAssignmentGradesMutation(baseOptions?: Apollo.MutationHookOptions<ImportParseAssignmentGradesMutation, ImportParseAssignmentGradesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportParseAssignmentGradesMutation, ImportParseAssignmentGradesMutationVariables>(ImportParseAssignmentGradesDocument, options);
      }
export type ImportParseAssignmentGradesMutationHookResult = ReturnType<typeof useImportParseAssignmentGradesMutation>;
export type ImportParseAssignmentGradesMutationResult = Apollo.MutationResult<ImportParseAssignmentGradesMutation>;
export type ImportParseAssignmentGradesMutationOptions = Apollo.BaseMutationOptions<ImportParseAssignmentGradesMutation, ImportParseAssignmentGradesMutationVariables>;
export const ImportStoreAssignmentGradesDocument = gql`
    mutation importStoreAssignmentGrades($classId: String!, $markingPeriodId: String!, $data: [ImportAssignmentsGradesInput!]!, $skipNotValid: Boolean! = false) {
  importStoreAssignmentGrades(
    classId: $classId
    markingPeriodId: $markingPeriodId
    input: $data
    skipNotValid: $skipNotValid
  ) {
    ...ImportGradesFields
  }
}
    ${ImportGradesFieldsFragmentDoc}`;
export type ImportStoreAssignmentGradesMutationFn = Apollo.MutationFunction<ImportStoreAssignmentGradesMutation, ImportStoreAssignmentGradesMutationVariables>;

/**
 * __useImportStoreAssignmentGradesMutation__
 *
 * To run a mutation, you first call `useImportStoreAssignmentGradesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportStoreAssignmentGradesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importStoreAssignmentGradesMutation, { data, loading, error }] = useImportStoreAssignmentGradesMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      markingPeriodId: // value for 'markingPeriodId'
 *      data: // value for 'data'
 *      skipNotValid: // value for 'skipNotValid'
 *   },
 * });
 */
export function useImportStoreAssignmentGradesMutation(baseOptions?: Apollo.MutationHookOptions<ImportStoreAssignmentGradesMutation, ImportStoreAssignmentGradesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportStoreAssignmentGradesMutation, ImportStoreAssignmentGradesMutationVariables>(ImportStoreAssignmentGradesDocument, options);
      }
export type ImportStoreAssignmentGradesMutationHookResult = ReturnType<typeof useImportStoreAssignmentGradesMutation>;
export type ImportStoreAssignmentGradesMutationResult = Apollo.MutationResult<ImportStoreAssignmentGradesMutation>;
export type ImportStoreAssignmentGradesMutationOptions = Apollo.BaseMutationOptions<ImportStoreAssignmentGradesMutation, ImportStoreAssignmentGradesMutationVariables>;
export const StoreCleverClassesDocument = gql`
    mutation storeCleverClasses($data: CleverClassesInput!) {
  storeCleverClasses(input: $data)
}
    `;
export type StoreCleverClassesMutationFn = Apollo.MutationFunction<StoreCleverClassesMutation, StoreCleverClassesMutationVariables>;

/**
 * __useStoreCleverClassesMutation__
 *
 * To run a mutation, you first call `useStoreCleverClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreCleverClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeCleverClassesMutation, { data, loading, error }] = useStoreCleverClassesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStoreCleverClassesMutation(baseOptions?: Apollo.MutationHookOptions<StoreCleverClassesMutation, StoreCleverClassesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreCleverClassesMutation, StoreCleverClassesMutationVariables>(StoreCleverClassesDocument, options);
      }
export type StoreCleverClassesMutationHookResult = ReturnType<typeof useStoreCleverClassesMutation>;
export type StoreCleverClassesMutationResult = Apollo.MutationResult<StoreCleverClassesMutation>;
export type StoreCleverClassesMutationOptions = Apollo.BaseMutationOptions<StoreCleverClassesMutation, StoreCleverClassesMutationVariables>;
export const UnSyncCleverClassDocument = gql`
    mutation unSyncCleverClass($classId: String!, $removeData: Boolean = false) {
  unSyncCleverClass(classId: $classId, removeData: $removeData)
}
    `;
export type UnSyncCleverClassMutationFn = Apollo.MutationFunction<UnSyncCleverClassMutation, UnSyncCleverClassMutationVariables>;

/**
 * __useUnSyncCleverClassMutation__
 *
 * To run a mutation, you first call `useUnSyncCleverClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnSyncCleverClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unSyncCleverClassMutation, { data, loading, error }] = useUnSyncCleverClassMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      removeData: // value for 'removeData'
 *   },
 * });
 */
export function useUnSyncCleverClassMutation(baseOptions?: Apollo.MutationHookOptions<UnSyncCleverClassMutation, UnSyncCleverClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnSyncCleverClassMutation, UnSyncCleverClassMutationVariables>(UnSyncCleverClassDocument, options);
      }
export type UnSyncCleverClassMutationHookResult = ReturnType<typeof useUnSyncCleverClassMutation>;
export type UnSyncCleverClassMutationResult = Apollo.MutationResult<UnSyncCleverClassMutation>;
export type UnSyncCleverClassMutationOptions = Apollo.BaseMutationOptions<UnSyncCleverClassMutation, UnSyncCleverClassMutationVariables>;
export const GetCleverClassesDocument = gql`
    query getCleverClasses($schoolId: String!, $status: ProviderStatusEnum!, $search: String) {
  getCleverClasses(schoolId: $schoolId, status: $status, search: $search) {
    ...CleverClassFields
  }
}
    ${CleverClassFieldsFragmentDoc}`;

/**
 * __useGetCleverClassesQuery__
 *
 * To run a query within a React component, call `useGetCleverClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCleverClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCleverClassesQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetCleverClassesQuery(baseOptions: Apollo.QueryHookOptions<GetCleverClassesQuery, GetCleverClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCleverClassesQuery, GetCleverClassesQueryVariables>(GetCleverClassesDocument, options);
      }
export function useGetCleverClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCleverClassesQuery, GetCleverClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCleverClassesQuery, GetCleverClassesQueryVariables>(GetCleverClassesDocument, options);
        }
export type GetCleverClassesQueryHookResult = ReturnType<typeof useGetCleverClassesQuery>;
export type GetCleverClassesLazyQueryHookResult = ReturnType<typeof useGetCleverClassesLazyQuery>;
export type GetCleverClassesQueryResult = Apollo.QueryResult<GetCleverClassesQuery, GetCleverClassesQueryVariables>;
export const StoreCleverUsersDocument = gql`
    mutation storeCleverUsers($data: CleverUsersInput!) {
  storeCleverUsers(input: $data)
}
    `;
export type StoreCleverUsersMutationFn = Apollo.MutationFunction<StoreCleverUsersMutation, StoreCleverUsersMutationVariables>;

/**
 * __useStoreCleverUsersMutation__
 *
 * To run a mutation, you first call `useStoreCleverUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreCleverUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeCleverUsersMutation, { data, loading, error }] = useStoreCleverUsersMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStoreCleverUsersMutation(baseOptions?: Apollo.MutationHookOptions<StoreCleverUsersMutation, StoreCleverUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreCleverUsersMutation, StoreCleverUsersMutationVariables>(StoreCleverUsersDocument, options);
      }
export type StoreCleverUsersMutationHookResult = ReturnType<typeof useStoreCleverUsersMutation>;
export type StoreCleverUsersMutationResult = Apollo.MutationResult<StoreCleverUsersMutation>;
export type StoreCleverUsersMutationOptions = Apollo.BaseMutationOptions<StoreCleverUsersMutation, StoreCleverUsersMutationVariables>;
export const GetCleverUsersDocument = gql`
    query getCleverUsers($schoolId: String!, $status: ProviderStatusEnum!, $search: String) {
  getCleverUsers(schoolId: $schoolId, status: $status, search: $search) {
    ...CleverUserFields
  }
}
    ${CleverUserFieldsFragmentDoc}`;

/**
 * __useGetCleverUsersQuery__
 *
 * To run a query within a React component, call `useGetCleverUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCleverUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCleverUsersQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetCleverUsersQuery(baseOptions: Apollo.QueryHookOptions<GetCleverUsersQuery, GetCleverUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCleverUsersQuery, GetCleverUsersQueryVariables>(GetCleverUsersDocument, options);
      }
export function useGetCleverUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCleverUsersQuery, GetCleverUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCleverUsersQuery, GetCleverUsersQueryVariables>(GetCleverUsersDocument, options);
        }
export type GetCleverUsersQueryHookResult = ReturnType<typeof useGetCleverUsersQuery>;
export type GetCleverUsersLazyQueryHookResult = ReturnType<typeof useGetCleverUsersLazyQuery>;
export type GetCleverUsersQueryResult = Apollo.QueryResult<GetCleverUsersQuery, GetCleverUsersQueryVariables>;
export const GetClassCleverUsersDocument = gql`
    query getClassCleverUsers($classId: String!) {
  getClassCleverUsers(classId: $classId) {
    ...CleverPracticeUserFields
  }
}
    ${CleverPracticeUserFieldsFragmentDoc}`;

/**
 * __useGetClassCleverUsersQuery__
 *
 * To run a query within a React component, call `useGetClassCleverUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassCleverUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassCleverUsersQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetClassCleverUsersQuery(baseOptions: Apollo.QueryHookOptions<GetClassCleverUsersQuery, GetClassCleverUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassCleverUsersQuery, GetClassCleverUsersQueryVariables>(GetClassCleverUsersDocument, options);
      }
export function useGetClassCleverUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassCleverUsersQuery, GetClassCleverUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassCleverUsersQuery, GetClassCleverUsersQueryVariables>(GetClassCleverUsersDocument, options);
        }
export type GetClassCleverUsersQueryHookResult = ReturnType<typeof useGetClassCleverUsersQuery>;
export type GetClassCleverUsersLazyQueryHookResult = ReturnType<typeof useGetClassCleverUsersLazyQuery>;
export type GetClassCleverUsersQueryResult = Apollo.QueryResult<GetClassCleverUsersQuery, GetClassCleverUsersQueryVariables>;
export const ValidateDistrictUrlDocument = gql`
    query validateDistrictURL($url: String!) {
  validateDistrictURL(url: $url) {
    ...ValidateDistrictURLFields
  }
}
    ${ValidateDistrictUrlFieldsFragmentDoc}`;

/**
 * __useValidateDistrictUrlQuery__
 *
 * To run a query within a React component, call `useValidateDistrictUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateDistrictUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateDistrictUrlQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useValidateDistrictUrlQuery(baseOptions: Apollo.QueryHookOptions<ValidateDistrictUrlQuery, ValidateDistrictUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateDistrictUrlQuery, ValidateDistrictUrlQueryVariables>(ValidateDistrictUrlDocument, options);
      }
export function useValidateDistrictUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateDistrictUrlQuery, ValidateDistrictUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateDistrictUrlQuery, ValidateDistrictUrlQueryVariables>(ValidateDistrictUrlDocument, options);
        }
export type ValidateDistrictUrlQueryHookResult = ReturnType<typeof useValidateDistrictUrlQuery>;
export type ValidateDistrictUrlLazyQueryHookResult = ReturnType<typeof useValidateDistrictUrlLazyQuery>;
export type ValidateDistrictUrlQueryResult = Apollo.QueryResult<ValidateDistrictUrlQuery, ValidateDistrictUrlQueryVariables>;
export const GetDistrictDocument = gql`
    query getDistrict($id: String!) {
  getDistrict(id: $id) {
    ...DistrictFields
  }
}
    ${DistrictFieldsFragmentDoc}`;

/**
 * __useGetDistrictQuery__
 *
 * To run a query within a React component, call `useGetDistrictQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistrictQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistrictQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDistrictQuery(baseOptions: Apollo.QueryHookOptions<GetDistrictQuery, GetDistrictQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDistrictQuery, GetDistrictQueryVariables>(GetDistrictDocument, options);
      }
export function useGetDistrictLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDistrictQuery, GetDistrictQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDistrictQuery, GetDistrictQueryVariables>(GetDistrictDocument, options);
        }
export type GetDistrictQueryHookResult = ReturnType<typeof useGetDistrictQuery>;
export type GetDistrictLazyQueryHookResult = ReturnType<typeof useGetDistrictLazyQuery>;
export type GetDistrictQueryResult = Apollo.QueryResult<GetDistrictQuery, GetDistrictQueryVariables>;
export const CreateGradeExcuseDocument = gql`
    mutation createGradeExcuse($schoolId: String!, $name: String!) {
  createGradeExcuse(schoolId: $schoolId, name: $name) {
    ...ExcusedGradeFields
  }
}
    ${ExcusedGradeFieldsFragmentDoc}`;
export type CreateGradeExcuseMutationFn = Apollo.MutationFunction<CreateGradeExcuseMutation, CreateGradeExcuseMutationVariables>;

/**
 * __useCreateGradeExcuseMutation__
 *
 * To run a mutation, you first call `useCreateGradeExcuseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGradeExcuseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGradeExcuseMutation, { data, loading, error }] = useCreateGradeExcuseMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateGradeExcuseMutation(baseOptions?: Apollo.MutationHookOptions<CreateGradeExcuseMutation, CreateGradeExcuseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGradeExcuseMutation, CreateGradeExcuseMutationVariables>(CreateGradeExcuseDocument, options);
      }
export type CreateGradeExcuseMutationHookResult = ReturnType<typeof useCreateGradeExcuseMutation>;
export type CreateGradeExcuseMutationResult = Apollo.MutationResult<CreateGradeExcuseMutation>;
export type CreateGradeExcuseMutationOptions = Apollo.BaseMutationOptions<CreateGradeExcuseMutation, CreateGradeExcuseMutationVariables>;
export const UpdateGradeExcuseDocument = gql`
    mutation updateGradeExcuse($id: String!, $name: String!) {
  updateGradeExcuse(id: $id, name: $name) {
    ...ExcusedGradeFields
  }
}
    ${ExcusedGradeFieldsFragmentDoc}`;
export type UpdateGradeExcuseMutationFn = Apollo.MutationFunction<UpdateGradeExcuseMutation, UpdateGradeExcuseMutationVariables>;

/**
 * __useUpdateGradeExcuseMutation__
 *
 * To run a mutation, you first call `useUpdateGradeExcuseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGradeExcuseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGradeExcuseMutation, { data, loading, error }] = useUpdateGradeExcuseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateGradeExcuseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGradeExcuseMutation, UpdateGradeExcuseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGradeExcuseMutation, UpdateGradeExcuseMutationVariables>(UpdateGradeExcuseDocument, options);
      }
export type UpdateGradeExcuseMutationHookResult = ReturnType<typeof useUpdateGradeExcuseMutation>;
export type UpdateGradeExcuseMutationResult = Apollo.MutationResult<UpdateGradeExcuseMutation>;
export type UpdateGradeExcuseMutationOptions = Apollo.BaseMutationOptions<UpdateGradeExcuseMutation, UpdateGradeExcuseMutationVariables>;
export const RemoveGradeExcusesDocument = gql`
    mutation removeGradeExcuses($data: MultipleIdsInput!) {
  removeGradeExcuses(input: $data)
}
    `;
export type RemoveGradeExcusesMutationFn = Apollo.MutationFunction<RemoveGradeExcusesMutation, RemoveGradeExcusesMutationVariables>;

/**
 * __useRemoveGradeExcusesMutation__
 *
 * To run a mutation, you first call `useRemoveGradeExcusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGradeExcusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGradeExcusesMutation, { data, loading, error }] = useRemoveGradeExcusesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveGradeExcusesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveGradeExcusesMutation, RemoveGradeExcusesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveGradeExcusesMutation, RemoveGradeExcusesMutationVariables>(RemoveGradeExcusesDocument, options);
      }
export type RemoveGradeExcusesMutationHookResult = ReturnType<typeof useRemoveGradeExcusesMutation>;
export type RemoveGradeExcusesMutationResult = Apollo.MutationResult<RemoveGradeExcusesMutation>;
export type RemoveGradeExcusesMutationOptions = Apollo.BaseMutationOptions<RemoveGradeExcusesMutation, RemoveGradeExcusesMutationVariables>;
export const GetGradeExcusesDocument = gql`
    query getGradeExcuses($schoolId: String!) {
  getGradeExcuses(schoolId: $schoolId) {
    ...ExcusedGradeFields
  }
}
    ${ExcusedGradeFieldsFragmentDoc}`;

/**
 * __useGetGradeExcusesQuery__
 *
 * To run a query within a React component, call `useGetGradeExcusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGradeExcusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGradeExcusesQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetGradeExcusesQuery(baseOptions: Apollo.QueryHookOptions<GetGradeExcusesQuery, GetGradeExcusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGradeExcusesQuery, GetGradeExcusesQueryVariables>(GetGradeExcusesDocument, options);
      }
export function useGetGradeExcusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGradeExcusesQuery, GetGradeExcusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGradeExcusesQuery, GetGradeExcusesQueryVariables>(GetGradeExcusesDocument, options);
        }
export type GetGradeExcusesQueryHookResult = ReturnType<typeof useGetGradeExcusesQuery>;
export type GetGradeExcusesLazyQueryHookResult = ReturnType<typeof useGetGradeExcusesLazyQuery>;
export type GetGradeExcusesQueryResult = Apollo.QueryResult<GetGradeExcusesQuery, GetGradeExcusesQueryVariables>;
export const GetGradeExcuseDocument = gql`
    query getGradeExcuse($schoolId: String!, $id: String!) {
  getGradeExcuse(schoolId: $schoolId, id: $id) {
    ...ExcusedGradeFields
  }
}
    ${ExcusedGradeFieldsFragmentDoc}`;

/**
 * __useGetGradeExcuseQuery__
 *
 * To run a query within a React component, call `useGetGradeExcuseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGradeExcuseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGradeExcuseQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGradeExcuseQuery(baseOptions: Apollo.QueryHookOptions<GetGradeExcuseQuery, GetGradeExcuseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGradeExcuseQuery, GetGradeExcuseQueryVariables>(GetGradeExcuseDocument, options);
      }
export function useGetGradeExcuseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGradeExcuseQuery, GetGradeExcuseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGradeExcuseQuery, GetGradeExcuseQueryVariables>(GetGradeExcuseDocument, options);
        }
export type GetGradeExcuseQueryHookResult = ReturnType<typeof useGetGradeExcuseQuery>;
export type GetGradeExcuseLazyQueryHookResult = ReturnType<typeof useGetGradeExcuseLazyQuery>;
export type GetGradeExcuseQueryResult = Apollo.QueryResult<GetGradeExcuseQuery, GetGradeExcuseQueryVariables>;
export const StoreGoogleAssignmentsDocument = gql`
    mutation storeGoogleAssignments($data: GoogleAssignmentsInput!) {
  storeGoogleAssignments(input: $data)
}
    `;
export type StoreGoogleAssignmentsMutationFn = Apollo.MutationFunction<StoreGoogleAssignmentsMutation, StoreGoogleAssignmentsMutationVariables>;

/**
 * __useStoreGoogleAssignmentsMutation__
 *
 * To run a mutation, you first call `useStoreGoogleAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreGoogleAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeGoogleAssignmentsMutation, { data, loading, error }] = useStoreGoogleAssignmentsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStoreGoogleAssignmentsMutation(baseOptions?: Apollo.MutationHookOptions<StoreGoogleAssignmentsMutation, StoreGoogleAssignmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreGoogleAssignmentsMutation, StoreGoogleAssignmentsMutationVariables>(StoreGoogleAssignmentsDocument, options);
      }
export type StoreGoogleAssignmentsMutationHookResult = ReturnType<typeof useStoreGoogleAssignmentsMutation>;
export type StoreGoogleAssignmentsMutationResult = Apollo.MutationResult<StoreGoogleAssignmentsMutation>;
export type StoreGoogleAssignmentsMutationOptions = Apollo.BaseMutationOptions<StoreGoogleAssignmentsMutation, StoreGoogleAssignmentsMutationVariables>;
export const ReSyncGoogleAssignmentsDocument = gql`
    mutation reSyncGoogleAssignments($classId: String!) {
  reSyncGoogleAssignments(classId: $classId)
}
    `;
export type ReSyncGoogleAssignmentsMutationFn = Apollo.MutationFunction<ReSyncGoogleAssignmentsMutation, ReSyncGoogleAssignmentsMutationVariables>;

/**
 * __useReSyncGoogleAssignmentsMutation__
 *
 * To run a mutation, you first call `useReSyncGoogleAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReSyncGoogleAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reSyncGoogleAssignmentsMutation, { data, loading, error }] = useReSyncGoogleAssignmentsMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useReSyncGoogleAssignmentsMutation(baseOptions?: Apollo.MutationHookOptions<ReSyncGoogleAssignmentsMutation, ReSyncGoogleAssignmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReSyncGoogleAssignmentsMutation, ReSyncGoogleAssignmentsMutationVariables>(ReSyncGoogleAssignmentsDocument, options);
      }
export type ReSyncGoogleAssignmentsMutationHookResult = ReturnType<typeof useReSyncGoogleAssignmentsMutation>;
export type ReSyncGoogleAssignmentsMutationResult = Apollo.MutationResult<ReSyncGoogleAssignmentsMutation>;
export type ReSyncGoogleAssignmentsMutationOptions = Apollo.BaseMutationOptions<ReSyncGoogleAssignmentsMutation, ReSyncGoogleAssignmentsMutationVariables>;
export const UnSyncGoogleAssignmentsDocument = gql`
    mutation unSyncGoogleAssignments($classId: String!, $removeData: Boolean = false) {
  unSyncGoogleAssignments(classId: $classId, removeData: $removeData)
}
    `;
export type UnSyncGoogleAssignmentsMutationFn = Apollo.MutationFunction<UnSyncGoogleAssignmentsMutation, UnSyncGoogleAssignmentsMutationVariables>;

/**
 * __useUnSyncGoogleAssignmentsMutation__
 *
 * To run a mutation, you first call `useUnSyncGoogleAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnSyncGoogleAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unSyncGoogleAssignmentsMutation, { data, loading, error }] = useUnSyncGoogleAssignmentsMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      removeData: // value for 'removeData'
 *   },
 * });
 */
export function useUnSyncGoogleAssignmentsMutation(baseOptions?: Apollo.MutationHookOptions<UnSyncGoogleAssignmentsMutation, UnSyncGoogleAssignmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnSyncGoogleAssignmentsMutation, UnSyncGoogleAssignmentsMutationVariables>(UnSyncGoogleAssignmentsDocument, options);
      }
export type UnSyncGoogleAssignmentsMutationHookResult = ReturnType<typeof useUnSyncGoogleAssignmentsMutation>;
export type UnSyncGoogleAssignmentsMutationResult = Apollo.MutationResult<UnSyncGoogleAssignmentsMutation>;
export type UnSyncGoogleAssignmentsMutationOptions = Apollo.BaseMutationOptions<UnSyncGoogleAssignmentsMutation, UnSyncGoogleAssignmentsMutationVariables>;
export const GetGoogleAssignmentsDocument = gql`
    query getGoogleAssignments($classId: String!, $search: String, $status: ProviderStatusEnum!) {
  getGoogleAssignments(classId: $classId, search: $search, status: $status) {
    ...GoogleAssignmentFields
  }
}
    ${GoogleAssignmentFieldsFragmentDoc}`;

/**
 * __useGetGoogleAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetGoogleAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleAssignmentsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      search: // value for 'search'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetGoogleAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<GetGoogleAssignmentsQuery, GetGoogleAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoogleAssignmentsQuery, GetGoogleAssignmentsQueryVariables>(GetGoogleAssignmentsDocument, options);
      }
export function useGetGoogleAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoogleAssignmentsQuery, GetGoogleAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoogleAssignmentsQuery, GetGoogleAssignmentsQueryVariables>(GetGoogleAssignmentsDocument, options);
        }
export type GetGoogleAssignmentsQueryHookResult = ReturnType<typeof useGetGoogleAssignmentsQuery>;
export type GetGoogleAssignmentsLazyQueryHookResult = ReturnType<typeof useGetGoogleAssignmentsLazyQuery>;
export type GetGoogleAssignmentsQueryResult = Apollo.QueryResult<GetGoogleAssignmentsQuery, GetGoogleAssignmentsQueryVariables>;
export const GetGooglePracticeAssignmentsDocument = gql`
    query getGooglePracticeAssignments($classId: String!) {
  getAssignments(classId: $classId) {
    ...GooglePracticeAssignmentFields
  }
}
    ${GooglePracticeAssignmentFieldsFragmentDoc}`;

/**
 * __useGetGooglePracticeAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetGooglePracticeAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGooglePracticeAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGooglePracticeAssignmentsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetGooglePracticeAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<GetGooglePracticeAssignmentsQuery, GetGooglePracticeAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGooglePracticeAssignmentsQuery, GetGooglePracticeAssignmentsQueryVariables>(GetGooglePracticeAssignmentsDocument, options);
      }
export function useGetGooglePracticeAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGooglePracticeAssignmentsQuery, GetGooglePracticeAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGooglePracticeAssignmentsQuery, GetGooglePracticeAssignmentsQueryVariables>(GetGooglePracticeAssignmentsDocument, options);
        }
export type GetGooglePracticeAssignmentsQueryHookResult = ReturnType<typeof useGetGooglePracticeAssignmentsQuery>;
export type GetGooglePracticeAssignmentsLazyQueryHookResult = ReturnType<typeof useGetGooglePracticeAssignmentsLazyQuery>;
export type GetGooglePracticeAssignmentsQueryResult = Apollo.QueryResult<GetGooglePracticeAssignmentsQuery, GetGooglePracticeAssignmentsQueryVariables>;
export const SyncGoogleClassDocument = gql`
    mutation syncGoogleClass($classId: String!, $googleClassId: String!, $googleClassName: String!) {
  syncGoogleClass(
    classId: $classId
    googleClassId: $googleClassId
    googleClassName: $googleClassName
  )
}
    `;
export type SyncGoogleClassMutationFn = Apollo.MutationFunction<SyncGoogleClassMutation, SyncGoogleClassMutationVariables>;

/**
 * __useSyncGoogleClassMutation__
 *
 * To run a mutation, you first call `useSyncGoogleClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncGoogleClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncGoogleClassMutation, { data, loading, error }] = useSyncGoogleClassMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      googleClassId: // value for 'googleClassId'
 *      googleClassName: // value for 'googleClassName'
 *   },
 * });
 */
export function useSyncGoogleClassMutation(baseOptions?: Apollo.MutationHookOptions<SyncGoogleClassMutation, SyncGoogleClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncGoogleClassMutation, SyncGoogleClassMutationVariables>(SyncGoogleClassDocument, options);
      }
export type SyncGoogleClassMutationHookResult = ReturnType<typeof useSyncGoogleClassMutation>;
export type SyncGoogleClassMutationResult = Apollo.MutationResult<SyncGoogleClassMutation>;
export type SyncGoogleClassMutationOptions = Apollo.BaseMutationOptions<SyncGoogleClassMutation, SyncGoogleClassMutationVariables>;
export const UnSyncGoogleClassDocument = gql`
    mutation unSyncGoogleClass($classId: String!, $removeData: Boolean = false) {
  unSyncGoogleClass(classId: $classId, removeData: $removeData)
}
    `;
export type UnSyncGoogleClassMutationFn = Apollo.MutationFunction<UnSyncGoogleClassMutation, UnSyncGoogleClassMutationVariables>;

/**
 * __useUnSyncGoogleClassMutation__
 *
 * To run a mutation, you first call `useUnSyncGoogleClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnSyncGoogleClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unSyncGoogleClassMutation, { data, loading, error }] = useUnSyncGoogleClassMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      removeData: // value for 'removeData'
 *   },
 * });
 */
export function useUnSyncGoogleClassMutation(baseOptions?: Apollo.MutationHookOptions<UnSyncGoogleClassMutation, UnSyncGoogleClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnSyncGoogleClassMutation, UnSyncGoogleClassMutationVariables>(UnSyncGoogleClassDocument, options);
      }
export type UnSyncGoogleClassMutationHookResult = ReturnType<typeof useUnSyncGoogleClassMutation>;
export type UnSyncGoogleClassMutationResult = Apollo.MutationResult<UnSyncGoogleClassMutation>;
export type UnSyncGoogleClassMutationOptions = Apollo.BaseMutationOptions<UnSyncGoogleClassMutation, UnSyncGoogleClassMutationVariables>;
export const GetGoogleClassesDocument = gql`
    query getGoogleClasses($classId: String!, $search: String) {
  getGoogleClasses(classId: $classId, search: $search) {
    ...GoogleClassFields
  }
}
    ${GoogleClassFieldsFragmentDoc}`;

/**
 * __useGetGoogleClassesQuery__
 *
 * To run a query within a React component, call `useGetGoogleClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleClassesQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetGoogleClassesQuery(baseOptions: Apollo.QueryHookOptions<GetGoogleClassesQuery, GetGoogleClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoogleClassesQuery, GetGoogleClassesQueryVariables>(GetGoogleClassesDocument, options);
      }
export function useGetGoogleClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoogleClassesQuery, GetGoogleClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoogleClassesQuery, GetGoogleClassesQueryVariables>(GetGoogleClassesDocument, options);
        }
export type GetGoogleClassesQueryHookResult = ReturnType<typeof useGetGoogleClassesQuery>;
export type GetGoogleClassesLazyQueryHookResult = ReturnType<typeof useGetGoogleClassesLazyQuery>;
export type GetGoogleClassesQueryResult = Apollo.QueryResult<GetGoogleClassesQuery, GetGoogleClassesQueryVariables>;
export const StoreGoogleUsersDocument = gql`
    mutation storeGoogleUsers($data: GoogleUsersInput!) {
  storeGoogleUsers(input: $data)
}
    `;
export type StoreGoogleUsersMutationFn = Apollo.MutationFunction<StoreGoogleUsersMutation, StoreGoogleUsersMutationVariables>;

/**
 * __useStoreGoogleUsersMutation__
 *
 * To run a mutation, you first call `useStoreGoogleUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreGoogleUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeGoogleUsersMutation, { data, loading, error }] = useStoreGoogleUsersMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStoreGoogleUsersMutation(baseOptions?: Apollo.MutationHookOptions<StoreGoogleUsersMutation, StoreGoogleUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreGoogleUsersMutation, StoreGoogleUsersMutationVariables>(StoreGoogleUsersDocument, options);
      }
export type StoreGoogleUsersMutationHookResult = ReturnType<typeof useStoreGoogleUsersMutation>;
export type StoreGoogleUsersMutationResult = Apollo.MutationResult<StoreGoogleUsersMutation>;
export type StoreGoogleUsersMutationOptions = Apollo.BaseMutationOptions<StoreGoogleUsersMutation, StoreGoogleUsersMutationVariables>;
export const UnSyncGoogleUsersDocument = gql`
    mutation unSyncGoogleUsers($classId: String!, $removeData: Boolean = false) {
  unSyncGoogleUsers(classId: $classId, removeData: $removeData)
}
    `;
export type UnSyncGoogleUsersMutationFn = Apollo.MutationFunction<UnSyncGoogleUsersMutation, UnSyncGoogleUsersMutationVariables>;

/**
 * __useUnSyncGoogleUsersMutation__
 *
 * To run a mutation, you first call `useUnSyncGoogleUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnSyncGoogleUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unSyncGoogleUsersMutation, { data, loading, error }] = useUnSyncGoogleUsersMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      removeData: // value for 'removeData'
 *   },
 * });
 */
export function useUnSyncGoogleUsersMutation(baseOptions?: Apollo.MutationHookOptions<UnSyncGoogleUsersMutation, UnSyncGoogleUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnSyncGoogleUsersMutation, UnSyncGoogleUsersMutationVariables>(UnSyncGoogleUsersDocument, options);
      }
export type UnSyncGoogleUsersMutationHookResult = ReturnType<typeof useUnSyncGoogleUsersMutation>;
export type UnSyncGoogleUsersMutationResult = Apollo.MutationResult<UnSyncGoogleUsersMutation>;
export type UnSyncGoogleUsersMutationOptions = Apollo.BaseMutationOptions<UnSyncGoogleUsersMutation, UnSyncGoogleUsersMutationVariables>;
export const GetGoogleUsersDocument = gql`
    query getGoogleUsers($classId: String!, $search: String, $status: ProviderStatusEnum!) {
  getGoogleUsers(classId: $classId, search: $search, status: $status) {
    ...GoogleUserFields
  }
}
    ${GoogleUserFieldsFragmentDoc}`;

/**
 * __useGetGoogleUsersQuery__
 *
 * To run a query within a React component, call `useGetGoogleUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleUsersQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      search: // value for 'search'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetGoogleUsersQuery(baseOptions: Apollo.QueryHookOptions<GetGoogleUsersQuery, GetGoogleUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoogleUsersQuery, GetGoogleUsersQueryVariables>(GetGoogleUsersDocument, options);
      }
export function useGetGoogleUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoogleUsersQuery, GetGoogleUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoogleUsersQuery, GetGoogleUsersQueryVariables>(GetGoogleUsersDocument, options);
        }
export type GetGoogleUsersQueryHookResult = ReturnType<typeof useGetGoogleUsersQuery>;
export type GetGoogleUsersLazyQueryHookResult = ReturnType<typeof useGetGoogleUsersLazyQuery>;
export type GetGoogleUsersQueryResult = Apollo.QueryResult<GetGoogleUsersQuery, GetGoogleUsersQueryVariables>;
export const CreateGradebookDocument = gql`
    mutation createGradebook($schoolId: String!, $data: GradebookSettingInput!) {
  createGradebook(schoolId: $schoolId, input: $data) {
    ...SingleGradebookFields
  }
}
    ${SingleGradebookFieldsFragmentDoc}`;
export type CreateGradebookMutationFn = Apollo.MutationFunction<CreateGradebookMutation, CreateGradebookMutationVariables>;

/**
 * __useCreateGradebookMutation__
 *
 * To run a mutation, you first call `useCreateGradebookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGradebookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGradebookMutation, { data, loading, error }] = useCreateGradebookMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateGradebookMutation(baseOptions?: Apollo.MutationHookOptions<CreateGradebookMutation, CreateGradebookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGradebookMutation, CreateGradebookMutationVariables>(CreateGradebookDocument, options);
      }
export type CreateGradebookMutationHookResult = ReturnType<typeof useCreateGradebookMutation>;
export type CreateGradebookMutationResult = Apollo.MutationResult<CreateGradebookMutation>;
export type CreateGradebookMutationOptions = Apollo.BaseMutationOptions<CreateGradebookMutation, CreateGradebookMutationVariables>;
export const UpdateGradebookDocument = gql`
    mutation updateGradebook($id: String!, $data: GradebookSettingInput!) {
  updateGradebook(id: $id, input: $data) {
    ...SingleGradebookFields
  }
}
    ${SingleGradebookFieldsFragmentDoc}`;
export type UpdateGradebookMutationFn = Apollo.MutationFunction<UpdateGradebookMutation, UpdateGradebookMutationVariables>;

/**
 * __useUpdateGradebookMutation__
 *
 * To run a mutation, you first call `useUpdateGradebookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGradebookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGradebookMutation, { data, loading, error }] = useUpdateGradebookMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateGradebookMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGradebookMutation, UpdateGradebookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGradebookMutation, UpdateGradebookMutationVariables>(UpdateGradebookDocument, options);
      }
export type UpdateGradebookMutationHookResult = ReturnType<typeof useUpdateGradebookMutation>;
export type UpdateGradebookMutationResult = Apollo.MutationResult<UpdateGradebookMutation>;
export type UpdateGradebookMutationOptions = Apollo.BaseMutationOptions<UpdateGradebookMutation, UpdateGradebookMutationVariables>;
export const ReactivateGradebooksDocument = gql`
    mutation reactivateGradebooks($data: MultipleIdsInput!) {
  reactivateGradebooks(input: $data)
}
    `;
export type ReactivateGradebooksMutationFn = Apollo.MutationFunction<ReactivateGradebooksMutation, ReactivateGradebooksMutationVariables>;

/**
 * __useReactivateGradebooksMutation__
 *
 * To run a mutation, you first call `useReactivateGradebooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateGradebooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateGradebooksMutation, { data, loading, error }] = useReactivateGradebooksMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReactivateGradebooksMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateGradebooksMutation, ReactivateGradebooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateGradebooksMutation, ReactivateGradebooksMutationVariables>(ReactivateGradebooksDocument, options);
      }
export type ReactivateGradebooksMutationHookResult = ReturnType<typeof useReactivateGradebooksMutation>;
export type ReactivateGradebooksMutationResult = Apollo.MutationResult<ReactivateGradebooksMutation>;
export type ReactivateGradebooksMutationOptions = Apollo.BaseMutationOptions<ReactivateGradebooksMutation, ReactivateGradebooksMutationVariables>;
export const RemoveGradebooksDocument = gql`
    mutation removeGradebooks($data: MultipleIdsInput!) {
  removeGradebooks(input: $data)
}
    `;
export type RemoveGradebooksMutationFn = Apollo.MutationFunction<RemoveGradebooksMutation, RemoveGradebooksMutationVariables>;

/**
 * __useRemoveGradebooksMutation__
 *
 * To run a mutation, you first call `useRemoveGradebooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGradebooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGradebooksMutation, { data, loading, error }] = useRemoveGradebooksMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveGradebooksMutation(baseOptions?: Apollo.MutationHookOptions<RemoveGradebooksMutation, RemoveGradebooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveGradebooksMutation, RemoveGradebooksMutationVariables>(RemoveGradebooksDocument, options);
      }
export type RemoveGradebooksMutationHookResult = ReturnType<typeof useRemoveGradebooksMutation>;
export type RemoveGradebooksMutationResult = Apollo.MutationResult<RemoveGradebooksMutation>;
export type RemoveGradebooksMutationOptions = Apollo.BaseMutationOptions<RemoveGradebooksMutation, RemoveGradebooksMutationVariables>;
export const GetGradebooksDocument = gql`
    query getGradebooks($schoolId: String!, $after: String, $before: String, $limit: Int = 10, $search: String, $deleted: Boolean) {
  getGradebooks(
    schoolId: $schoolId
    after: $after
    before: $before
    limit: $limit
    search: $search
    deleted: $deleted
  ) {
    pagination {
      ...PaginationFields
    }
    data {
      ...TableGradebookFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${TableGradebookFieldsFragmentDoc}`;

/**
 * __useGetGradebooksQuery__
 *
 * To run a query within a React component, call `useGetGradebooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGradebooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGradebooksQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      deleted: // value for 'deleted'
 *   },
 * });
 */
export function useGetGradebooksQuery(baseOptions: Apollo.QueryHookOptions<GetGradebooksQuery, GetGradebooksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGradebooksQuery, GetGradebooksQueryVariables>(GetGradebooksDocument, options);
      }
export function useGetGradebooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGradebooksQuery, GetGradebooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGradebooksQuery, GetGradebooksQueryVariables>(GetGradebooksDocument, options);
        }
export type GetGradebooksQueryHookResult = ReturnType<typeof useGetGradebooksQuery>;
export type GetGradebooksLazyQueryHookResult = ReturnType<typeof useGetGradebooksLazyQuery>;
export type GetGradebooksQueryResult = Apollo.QueryResult<GetGradebooksQuery, GetGradebooksQueryVariables>;
export const GetGradebookDocument = gql`
    query getGradebook($schoolId: String!, $id: String!) {
  getGradebook(schoolId: $schoolId, id: $id) {
    ...SingleGradebookFields
  }
}
    ${SingleGradebookFieldsFragmentDoc}`;

/**
 * __useGetGradebookQuery__
 *
 * To run a query within a React component, call `useGetGradebookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGradebookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGradebookQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGradebookQuery(baseOptions: Apollo.QueryHookOptions<GetGradebookQuery, GetGradebookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGradebookQuery, GetGradebookQueryVariables>(GetGradebookDocument, options);
      }
export function useGetGradebookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGradebookQuery, GetGradebookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGradebookQuery, GetGradebookQueryVariables>(GetGradebookDocument, options);
        }
export type GetGradebookQueryHookResult = ReturnType<typeof useGetGradebookQuery>;
export type GetGradebookLazyQueryHookResult = ReturnType<typeof useGetGradebookLazyQuery>;
export type GetGradebookQueryResult = Apollo.QueryResult<GetGradebookQuery, GetGradebookQueryVariables>;
export const StoreGradeDocument = gql`
    mutation storeGrade($data: GradesListInput!) {
  storeGrade(input: $data)
}
    `;
export type StoreGradeMutationFn = Apollo.MutationFunction<StoreGradeMutation, StoreGradeMutationVariables>;

/**
 * __useStoreGradeMutation__
 *
 * To run a mutation, you first call `useStoreGradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreGradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeGradeMutation, { data, loading, error }] = useStoreGradeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStoreGradeMutation(baseOptions?: Apollo.MutationHookOptions<StoreGradeMutation, StoreGradeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreGradeMutation, StoreGradeMutationVariables>(StoreGradeDocument, options);
      }
export type StoreGradeMutationHookResult = ReturnType<typeof useStoreGradeMutation>;
export type StoreGradeMutationResult = Apollo.MutationResult<StoreGradeMutation>;
export type StoreGradeMutationOptions = Apollo.BaseMutationOptions<StoreGradeMutation, StoreGradeMutationVariables>;
export const SetOverallDocument = gql`
    mutation setOverall($data: OverallGradeInput!) {
  setOverall(input: $data)
}
    `;
export type SetOverallMutationFn = Apollo.MutationFunction<SetOverallMutation, SetOverallMutationVariables>;

/**
 * __useSetOverallMutation__
 *
 * To run a mutation, you first call `useSetOverallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOverallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOverallMutation, { data, loading, error }] = useSetOverallMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetOverallMutation(baseOptions?: Apollo.MutationHookOptions<SetOverallMutation, SetOverallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOverallMutation, SetOverallMutationVariables>(SetOverallDocument, options);
      }
export type SetOverallMutationHookResult = ReturnType<typeof useSetOverallMutation>;
export type SetOverallMutationResult = Apollo.MutationResult<SetOverallMutation>;
export type SetOverallMutationOptions = Apollo.BaseMutationOptions<SetOverallMutation, SetOverallMutationVariables>;
export const ReturnAllAssignmentGradesDocument = gql`
    mutation returnAllAssignmentGrades($assignmentId: String!) {
  returnAllAssignmentGrades(assignmentId: $assignmentId)
}
    `;
export type ReturnAllAssignmentGradesMutationFn = Apollo.MutationFunction<ReturnAllAssignmentGradesMutation, ReturnAllAssignmentGradesMutationVariables>;

/**
 * __useReturnAllAssignmentGradesMutation__
 *
 * To run a mutation, you first call `useReturnAllAssignmentGradesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReturnAllAssignmentGradesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [returnAllAssignmentGradesMutation, { data, loading, error }] = useReturnAllAssignmentGradesMutation({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useReturnAllAssignmentGradesMutation(baseOptions?: Apollo.MutationHookOptions<ReturnAllAssignmentGradesMutation, ReturnAllAssignmentGradesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReturnAllAssignmentGradesMutation, ReturnAllAssignmentGradesMutationVariables>(ReturnAllAssignmentGradesDocument, options);
      }
export type ReturnAllAssignmentGradesMutationHookResult = ReturnType<typeof useReturnAllAssignmentGradesMutation>;
export type ReturnAllAssignmentGradesMutationResult = Apollo.MutationResult<ReturnAllAssignmentGradesMutation>;
export type ReturnAllAssignmentGradesMutationOptions = Apollo.BaseMutationOptions<ReturnAllAssignmentGradesMutation, ReturnAllAssignmentGradesMutationVariables>;
export const ResetUserOverallDocument = gql`
    mutation resetUserOverall($data: ResetUserOverallInput!) {
  resetUserOverall(input: $data) {
    classOverall
    userOverall
  }
}
    `;
export type ResetUserOverallMutationFn = Apollo.MutationFunction<ResetUserOverallMutation, ResetUserOverallMutationVariables>;

/**
 * __useResetUserOverallMutation__
 *
 * To run a mutation, you first call `useResetUserOverallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserOverallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserOverallMutation, { data, loading, error }] = useResetUserOverallMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResetUserOverallMutation(baseOptions?: Apollo.MutationHookOptions<ResetUserOverallMutation, ResetUserOverallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetUserOverallMutation, ResetUserOverallMutationVariables>(ResetUserOverallDocument, options);
      }
export type ResetUserOverallMutationHookResult = ReturnType<typeof useResetUserOverallMutation>;
export type ResetUserOverallMutationResult = Apollo.MutationResult<ResetUserOverallMutation>;
export type ResetUserOverallMutationOptions = Apollo.BaseMutationOptions<ResetUserOverallMutation, ResetUserOverallMutationVariables>;
export const SetGradeExcuseDocument = gql`
    mutation setGradeExcuse($data: GradeExcuseListInput!) {
  setGradeExcuse(input: $data)
}
    `;
export type SetGradeExcuseMutationFn = Apollo.MutationFunction<SetGradeExcuseMutation, SetGradeExcuseMutationVariables>;

/**
 * __useSetGradeExcuseMutation__
 *
 * To run a mutation, you first call `useSetGradeExcuseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGradeExcuseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGradeExcuseMutation, { data, loading, error }] = useSetGradeExcuseMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetGradeExcuseMutation(baseOptions?: Apollo.MutationHookOptions<SetGradeExcuseMutation, SetGradeExcuseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetGradeExcuseMutation, SetGradeExcuseMutationVariables>(SetGradeExcuseDocument, options);
      }
export type SetGradeExcuseMutationHookResult = ReturnType<typeof useSetGradeExcuseMutation>;
export type SetGradeExcuseMutationResult = Apollo.MutationResult<SetGradeExcuseMutation>;
export type SetGradeExcuseMutationOptions = Apollo.BaseMutationOptions<SetGradeExcuseMutation, SetGradeExcuseMutationVariables>;
export const GetAllAssignmentsGradesDocument = gql`
    query getAllAssignmentsGrades($classId: String!, $markingPeriodId: String!, $userStatus: Boolean, $search: String) {
  getAllAssignmentsGrades(
    classId: $classId
    markingPeriodId: $markingPeriodId
    userStatus: $userStatus
    search: $search
  ) {
    overall
    users {
      overall
      user {
        ...ClassUserFields
      }
      assignmentGrades {
        ...UserGradeFields
      }
    }
  }
}
    ${ClassUserFieldsFragmentDoc}
${UserGradeFieldsFragmentDoc}`;

/**
 * __useGetAllAssignmentsGradesQuery__
 *
 * To run a query within a React component, call `useGetAllAssignmentsGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssignmentsGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssignmentsGradesQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      markingPeriodId: // value for 'markingPeriodId'
 *      userStatus: // value for 'userStatus'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetAllAssignmentsGradesQuery(baseOptions: Apollo.QueryHookOptions<GetAllAssignmentsGradesQuery, GetAllAssignmentsGradesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAssignmentsGradesQuery, GetAllAssignmentsGradesQueryVariables>(GetAllAssignmentsGradesDocument, options);
      }
export function useGetAllAssignmentsGradesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAssignmentsGradesQuery, GetAllAssignmentsGradesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAssignmentsGradesQuery, GetAllAssignmentsGradesQueryVariables>(GetAllAssignmentsGradesDocument, options);
        }
export type GetAllAssignmentsGradesQueryHookResult = ReturnType<typeof useGetAllAssignmentsGradesQuery>;
export type GetAllAssignmentsGradesLazyQueryHookResult = ReturnType<typeof useGetAllAssignmentsGradesLazyQuery>;
export type GetAllAssignmentsGradesQueryResult = Apollo.QueryResult<GetAllAssignmentsGradesQuery, GetAllAssignmentsGradesQueryVariables>;
export const GetStudentGradesDocument = gql`
    query getStudentGrades($schoolId: String!, $termId: String!, $userId: String!, $search: String) {
  getStudentGrades(
    schoolId: $schoolId
    termId: $termId
    userId: $userId
    search: $search
  ) {
    classes {
      id
      name
      overall
      markingPeriods {
        id
        value
      }
    }
    markingPeriods {
      id
      name
    }
  }
}
    `;

/**
 * __useGetStudentGradesQuery__
 *
 * To run a query within a React component, call `useGetStudentGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentGradesQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      termId: // value for 'termId'
 *      userId: // value for 'userId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetStudentGradesQuery(baseOptions: Apollo.QueryHookOptions<GetStudentGradesQuery, GetStudentGradesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentGradesQuery, GetStudentGradesQueryVariables>(GetStudentGradesDocument, options);
      }
export function useGetStudentGradesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentGradesQuery, GetStudentGradesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentGradesQuery, GetStudentGradesQueryVariables>(GetStudentGradesDocument, options);
        }
export type GetStudentGradesQueryHookResult = ReturnType<typeof useGetStudentGradesQuery>;
export type GetStudentGradesLazyQueryHookResult = ReturnType<typeof useGetStudentGradesLazyQuery>;
export type GetStudentGradesQueryResult = Apollo.QueryResult<GetStudentGradesQuery, GetStudentGradesQueryVariables>;
export const GetStudentOverallDocument = gql`
    query getStudentOverall($classId: String!, $markingPeriodId: String!, $userId: String!) {
  getStudentOverall(
    classId: $classId
    markingPeriodId: $markingPeriodId
    userId: $userId
  )
}
    `;

/**
 * __useGetStudentOverallQuery__
 *
 * To run a query within a React component, call `useGetStudentOverallQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentOverallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentOverallQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      markingPeriodId: // value for 'markingPeriodId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetStudentOverallQuery(baseOptions: Apollo.QueryHookOptions<GetStudentOverallQuery, GetStudentOverallQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentOverallQuery, GetStudentOverallQueryVariables>(GetStudentOverallDocument, options);
      }
export function useGetStudentOverallLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentOverallQuery, GetStudentOverallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentOverallQuery, GetStudentOverallQueryVariables>(GetStudentOverallDocument, options);
        }
export type GetStudentOverallQueryHookResult = ReturnType<typeof useGetStudentOverallQuery>;
export type GetStudentOverallLazyQueryHookResult = ReturnType<typeof useGetStudentOverallLazyQuery>;
export type GetStudentOverallQueryResult = Apollo.QueryResult<GetStudentOverallQuery, GetStudentOverallQueryVariables>;
export const EnableUserOAuthAppDocument = gql`
    mutation enableUserOAuthApp($data: EnableAppInput!) {
  enableUserOAuthApp(input: $data)
}
    `;
export type EnableUserOAuthAppMutationFn = Apollo.MutationFunction<EnableUserOAuthAppMutation, EnableUserOAuthAppMutationVariables>;

/**
 * __useEnableUserOAuthAppMutation__
 *
 * To run a mutation, you first call `useEnableUserOAuthAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableUserOAuthAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableUserOAuthAppMutation, { data, loading, error }] = useEnableUserOAuthAppMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEnableUserOAuthAppMutation(baseOptions?: Apollo.MutationHookOptions<EnableUserOAuthAppMutation, EnableUserOAuthAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableUserOAuthAppMutation, EnableUserOAuthAppMutationVariables>(EnableUserOAuthAppDocument, options);
      }
export type EnableUserOAuthAppMutationHookResult = ReturnType<typeof useEnableUserOAuthAppMutation>;
export type EnableUserOAuthAppMutationResult = Apollo.MutationResult<EnableUserOAuthAppMutation>;
export type EnableUserOAuthAppMutationOptions = Apollo.BaseMutationOptions<EnableUserOAuthAppMutation, EnableUserOAuthAppMutationVariables>;
export const DisableUserAppDocument = gql`
    mutation disableUserApp($name: AppEnum!) {
  disableUserApp(name: $name)
}
    `;
export type DisableUserAppMutationFn = Apollo.MutationFunction<DisableUserAppMutation, DisableUserAppMutationVariables>;

/**
 * __useDisableUserAppMutation__
 *
 * To run a mutation, you first call `useDisableUserAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableUserAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableUserAppMutation, { data, loading, error }] = useDisableUserAppMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDisableUserAppMutation(baseOptions?: Apollo.MutationHookOptions<DisableUserAppMutation, DisableUserAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableUserAppMutation, DisableUserAppMutationVariables>(DisableUserAppDocument, options);
      }
export type DisableUserAppMutationHookResult = ReturnType<typeof useDisableUserAppMutation>;
export type DisableUserAppMutationResult = Apollo.MutationResult<DisableUserAppMutation>;
export type DisableUserAppMutationOptions = Apollo.BaseMutationOptions<DisableUserAppMutation, DisableUserAppMutationVariables>;
export const GetPublicIntegrationDocument = gql`
    query getPublicIntegration($schoolId: String!, $type: ProviderEnum!) {
  getPublicIntegration(schoolId: $schoolId, type: $type) {
    hasIntegration
    status
    type
    keys {
      key
      label
      required
      value
    }
  }
}
    `;

/**
 * __useGetPublicIntegrationQuery__
 *
 * To run a query within a React component, call `useGetPublicIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicIntegrationQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetPublicIntegrationQuery(baseOptions: Apollo.QueryHookOptions<GetPublicIntegrationQuery, GetPublicIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicIntegrationQuery, GetPublicIntegrationQueryVariables>(GetPublicIntegrationDocument, options);
      }
export function useGetPublicIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicIntegrationQuery, GetPublicIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicIntegrationQuery, GetPublicIntegrationQueryVariables>(GetPublicIntegrationDocument, options);
        }
export type GetPublicIntegrationQueryHookResult = ReturnType<typeof useGetPublicIntegrationQuery>;
export type GetPublicIntegrationLazyQueryHookResult = ReturnType<typeof useGetPublicIntegrationLazyQuery>;
export type GetPublicIntegrationQueryResult = Apollo.QueryResult<GetPublicIntegrationQuery, GetPublicIntegrationQueryVariables>;
export const GetUserAppsDocument = gql`
    query getUserApps {
  getUserApps {
    id
    name
    enabled
  }
}
    `;

/**
 * __useGetUserAppsQuery__
 *
 * To run a query within a React component, call `useGetUserAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAppsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserAppsQuery, GetUserAppsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAppsQuery, GetUserAppsQueryVariables>(GetUserAppsDocument, options);
      }
export function useGetUserAppsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAppsQuery, GetUserAppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAppsQuery, GetUserAppsQueryVariables>(GetUserAppsDocument, options);
        }
export type GetUserAppsQueryHookResult = ReturnType<typeof useGetUserAppsQuery>;
export type GetUserAppsLazyQueryHookResult = ReturnType<typeof useGetUserAppsLazyQuery>;
export type GetUserAppsQueryResult = Apollo.QueryResult<GetUserAppsQuery, GetUserAppsQueryVariables>;
export const GenerateAppAuthLinkDocument = gql`
    query generateAppAuthLink($name: AppEnum!) {
  generateAuthLink(name: $name)
}
    `;

/**
 * __useGenerateAppAuthLinkQuery__
 *
 * To run a query within a React component, call `useGenerateAppAuthLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateAppAuthLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateAppAuthLinkQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGenerateAppAuthLinkQuery(baseOptions: Apollo.QueryHookOptions<GenerateAppAuthLinkQuery, GenerateAppAuthLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateAppAuthLinkQuery, GenerateAppAuthLinkQueryVariables>(GenerateAppAuthLinkDocument, options);
      }
export function useGenerateAppAuthLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateAppAuthLinkQuery, GenerateAppAuthLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateAppAuthLinkQuery, GenerateAppAuthLinkQueryVariables>(GenerateAppAuthLinkDocument, options);
        }
export type GenerateAppAuthLinkQueryHookResult = ReturnType<typeof useGenerateAppAuthLinkQuery>;
export type GenerateAppAuthLinkLazyQueryHookResult = ReturnType<typeof useGenerateAppAuthLinkLazyQuery>;
export type GenerateAppAuthLinkQueryResult = Apollo.QueryResult<GenerateAppAuthLinkQuery, GenerateAppAuthLinkQueryVariables>;
export const CreateMarkingPeriodDocument = gql`
    mutation createMarkingPeriod($schoolId: String!, $data: MarkingPeriodInput!) {
  createMarkingPeriod(schoolId: $schoolId, input: $data) {
    ...MarkingPeriodFields
  }
}
    ${MarkingPeriodFieldsFragmentDoc}`;
export type CreateMarkingPeriodMutationFn = Apollo.MutationFunction<CreateMarkingPeriodMutation, CreateMarkingPeriodMutationVariables>;

/**
 * __useCreateMarkingPeriodMutation__
 *
 * To run a mutation, you first call `useCreateMarkingPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarkingPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMarkingPeriodMutation, { data, loading, error }] = useCreateMarkingPeriodMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMarkingPeriodMutation(baseOptions?: Apollo.MutationHookOptions<CreateMarkingPeriodMutation, CreateMarkingPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMarkingPeriodMutation, CreateMarkingPeriodMutationVariables>(CreateMarkingPeriodDocument, options);
      }
export type CreateMarkingPeriodMutationHookResult = ReturnType<typeof useCreateMarkingPeriodMutation>;
export type CreateMarkingPeriodMutationResult = Apollo.MutationResult<CreateMarkingPeriodMutation>;
export type CreateMarkingPeriodMutationOptions = Apollo.BaseMutationOptions<CreateMarkingPeriodMutation, CreateMarkingPeriodMutationVariables>;
export const UpdateMarkingPeriodDocument = gql`
    mutation updateMarkingPeriod($id: String!, $data: MarkingPeriodInput!) {
  updateMarkingPeriod(id: $id, input: $data) {
    ...MarkingPeriodFields
  }
}
    ${MarkingPeriodFieldsFragmentDoc}`;
export type UpdateMarkingPeriodMutationFn = Apollo.MutationFunction<UpdateMarkingPeriodMutation, UpdateMarkingPeriodMutationVariables>;

/**
 * __useUpdateMarkingPeriodMutation__
 *
 * To run a mutation, you first call `useUpdateMarkingPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarkingPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarkingPeriodMutation, { data, loading, error }] = useUpdateMarkingPeriodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMarkingPeriodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMarkingPeriodMutation, UpdateMarkingPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMarkingPeriodMutation, UpdateMarkingPeriodMutationVariables>(UpdateMarkingPeriodDocument, options);
      }
export type UpdateMarkingPeriodMutationHookResult = ReturnType<typeof useUpdateMarkingPeriodMutation>;
export type UpdateMarkingPeriodMutationResult = Apollo.MutationResult<UpdateMarkingPeriodMutation>;
export type UpdateMarkingPeriodMutationOptions = Apollo.BaseMutationOptions<UpdateMarkingPeriodMutation, UpdateMarkingPeriodMutationVariables>;
export const ReactivateMarkingPeriodsDocument = gql`
    mutation reactivateMarkingPeriods($data: MultipleIdsInput!) {
  reactivateMarkingPeriods(input: $data)
}
    `;
export type ReactivateMarkingPeriodsMutationFn = Apollo.MutationFunction<ReactivateMarkingPeriodsMutation, ReactivateMarkingPeriodsMutationVariables>;

/**
 * __useReactivateMarkingPeriodsMutation__
 *
 * To run a mutation, you first call `useReactivateMarkingPeriodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateMarkingPeriodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateMarkingPeriodsMutation, { data, loading, error }] = useReactivateMarkingPeriodsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReactivateMarkingPeriodsMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateMarkingPeriodsMutation, ReactivateMarkingPeriodsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateMarkingPeriodsMutation, ReactivateMarkingPeriodsMutationVariables>(ReactivateMarkingPeriodsDocument, options);
      }
export type ReactivateMarkingPeriodsMutationHookResult = ReturnType<typeof useReactivateMarkingPeriodsMutation>;
export type ReactivateMarkingPeriodsMutationResult = Apollo.MutationResult<ReactivateMarkingPeriodsMutation>;
export type ReactivateMarkingPeriodsMutationOptions = Apollo.BaseMutationOptions<ReactivateMarkingPeriodsMutation, ReactivateMarkingPeriodsMutationVariables>;
export const RemoveMarkingPeriodsDocument = gql`
    mutation removeMarkingPeriods($data: MultipleIdsInput!) {
  removeMarkingPeriods(input: $data)
}
    `;
export type RemoveMarkingPeriodsMutationFn = Apollo.MutationFunction<RemoveMarkingPeriodsMutation, RemoveMarkingPeriodsMutationVariables>;

/**
 * __useRemoveMarkingPeriodsMutation__
 *
 * To run a mutation, you first call `useRemoveMarkingPeriodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMarkingPeriodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMarkingPeriodsMutation, { data, loading, error }] = useRemoveMarkingPeriodsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveMarkingPeriodsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMarkingPeriodsMutation, RemoveMarkingPeriodsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMarkingPeriodsMutation, RemoveMarkingPeriodsMutationVariables>(RemoveMarkingPeriodsDocument, options);
      }
export type RemoveMarkingPeriodsMutationHookResult = ReturnType<typeof useRemoveMarkingPeriodsMutation>;
export type RemoveMarkingPeriodsMutationResult = Apollo.MutationResult<RemoveMarkingPeriodsMutation>;
export type RemoveMarkingPeriodsMutationOptions = Apollo.BaseMutationOptions<RemoveMarkingPeriodsMutation, RemoveMarkingPeriodsMutationVariables>;
export const GetMarkingPeriodsDocument = gql`
    query getMarkingPeriods($schoolId: String!, $after: String, $before: String, $limit: Int = 10, $search: String, $deleted: Boolean) {
  getMarkingPeriods(
    schoolId: $schoolId
    after: $after
    before: $before
    limit: $limit
    search: $search
    deleted: $deleted
  ) {
    pagination {
      ...PaginationFields
    }
    data {
      ...MarkingPeriodFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${MarkingPeriodFieldsFragmentDoc}`;

/**
 * __useGetMarkingPeriodsQuery__
 *
 * To run a query within a React component, call `useGetMarkingPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarkingPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarkingPeriodsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      deleted: // value for 'deleted'
 *   },
 * });
 */
export function useGetMarkingPeriodsQuery(baseOptions: Apollo.QueryHookOptions<GetMarkingPeriodsQuery, GetMarkingPeriodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarkingPeriodsQuery, GetMarkingPeriodsQueryVariables>(GetMarkingPeriodsDocument, options);
      }
export function useGetMarkingPeriodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarkingPeriodsQuery, GetMarkingPeriodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarkingPeriodsQuery, GetMarkingPeriodsQueryVariables>(GetMarkingPeriodsDocument, options);
        }
export type GetMarkingPeriodsQueryHookResult = ReturnType<typeof useGetMarkingPeriodsQuery>;
export type GetMarkingPeriodsLazyQueryHookResult = ReturnType<typeof useGetMarkingPeriodsLazyQuery>;
export type GetMarkingPeriodsQueryResult = Apollo.QueryResult<GetMarkingPeriodsQuery, GetMarkingPeriodsQueryVariables>;
export const GetTermMarkingPeriodsDocument = gql`
    query getTermMarkingPeriods($schoolId: String!, $termId: String!) {
  getTermMarkingPeriods(schoolId: $schoolId, termId: $termId) {
    id
    name
  }
}
    `;

/**
 * __useGetTermMarkingPeriodsQuery__
 *
 * To run a query within a React component, call `useGetTermMarkingPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTermMarkingPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTermMarkingPeriodsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      termId: // value for 'termId'
 *   },
 * });
 */
export function useGetTermMarkingPeriodsQuery(baseOptions: Apollo.QueryHookOptions<GetTermMarkingPeriodsQuery, GetTermMarkingPeriodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTermMarkingPeriodsQuery, GetTermMarkingPeriodsQueryVariables>(GetTermMarkingPeriodsDocument, options);
      }
export function useGetTermMarkingPeriodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTermMarkingPeriodsQuery, GetTermMarkingPeriodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTermMarkingPeriodsQuery, GetTermMarkingPeriodsQueryVariables>(GetTermMarkingPeriodsDocument, options);
        }
export type GetTermMarkingPeriodsQueryHookResult = ReturnType<typeof useGetTermMarkingPeriodsQuery>;
export type GetTermMarkingPeriodsLazyQueryHookResult = ReturnType<typeof useGetTermMarkingPeriodsLazyQuery>;
export type GetTermMarkingPeriodsQueryResult = Apollo.QueryResult<GetTermMarkingPeriodsQuery, GetTermMarkingPeriodsQueryVariables>;
export const GetMarkingPeriodDocument = gql`
    query getMarkingPeriod($schoolId: String!, $id: String!) {
  getMarkingPeriod(schoolId: $schoolId, id: $id) {
    ...MarkingPeriodFields
  }
}
    ${MarkingPeriodFieldsFragmentDoc}`;

/**
 * __useGetMarkingPeriodQuery__
 *
 * To run a query within a React component, call `useGetMarkingPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarkingPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarkingPeriodQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMarkingPeriodQuery(baseOptions: Apollo.QueryHookOptions<GetMarkingPeriodQuery, GetMarkingPeriodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarkingPeriodQuery, GetMarkingPeriodQueryVariables>(GetMarkingPeriodDocument, options);
      }
export function useGetMarkingPeriodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarkingPeriodQuery, GetMarkingPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarkingPeriodQuery, GetMarkingPeriodQueryVariables>(GetMarkingPeriodDocument, options);
        }
export type GetMarkingPeriodQueryHookResult = ReturnType<typeof useGetMarkingPeriodQuery>;
export type GetMarkingPeriodLazyQueryHookResult = ReturnType<typeof useGetMarkingPeriodLazyQuery>;
export type GetMarkingPeriodQueryResult = Apollo.QueryResult<GetMarkingPeriodQuery, GetMarkingPeriodQueryVariables>;
export const SendMessageDocument = gql`
    mutation sendMessage($data: MessageInput!) {
  sendMessage(input: $data)
}
    `;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const CreateReportDocument = gql`
    mutation createReport($data: ReportInput!) {
  createReport(input: $data)
}
    `;
export type CreateReportMutationFn = Apollo.MutationFunction<CreateReportMutation, CreateReportMutationVariables>;

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportMutation, CreateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportMutation, CreateReportMutationVariables>(CreateReportDocument, options);
      }
export type CreateReportMutationHookResult = ReturnType<typeof useCreateReportMutation>;
export type CreateReportMutationResult = Apollo.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;
export const CreateGradeReportDocument = gql`
    mutation createGradeReport($data: ReportGradeInput!) {
  createGradeReport(input: $data)
}
    `;
export type CreateGradeReportMutationFn = Apollo.MutationFunction<CreateGradeReportMutation, CreateGradeReportMutationVariables>;

/**
 * __useCreateGradeReportMutation__
 *
 * To run a mutation, you first call `useCreateGradeReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGradeReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGradeReportMutation, { data, loading, error }] = useCreateGradeReportMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateGradeReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateGradeReportMutation, CreateGradeReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGradeReportMutation, CreateGradeReportMutationVariables>(CreateGradeReportDocument, options);
      }
export type CreateGradeReportMutationHookResult = ReturnType<typeof useCreateGradeReportMutation>;
export type CreateGradeReportMutationResult = Apollo.MutationResult<CreateGradeReportMutation>;
export type CreateGradeReportMutationOptions = Apollo.BaseMutationOptions<CreateGradeReportMutation, CreateGradeReportMutationVariables>;
export const RemoveReportsDocument = gql`
    mutation removeReports($data: MultipleIdsInput!) {
  removeReports(input: $data)
}
    `;
export type RemoveReportsMutationFn = Apollo.MutationFunction<RemoveReportsMutation, RemoveReportsMutationVariables>;

/**
 * __useRemoveReportsMutation__
 *
 * To run a mutation, you first call `useRemoveReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReportsMutation, { data, loading, error }] = useRemoveReportsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveReportsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveReportsMutation, RemoveReportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveReportsMutation, RemoveReportsMutationVariables>(RemoveReportsDocument, options);
      }
export type RemoveReportsMutationHookResult = ReturnType<typeof useRemoveReportsMutation>;
export type RemoveReportsMutationResult = Apollo.MutationResult<RemoveReportsMutation>;
export type RemoveReportsMutationOptions = Apollo.BaseMutationOptions<RemoveReportsMutation, RemoveReportsMutationVariables>;
export const GetReportsDocument = gql`
    query getReports($schoolId: String!, $after: String, $before: String, $limit: Int = 10) {
  getReports(schoolId: $schoolId, after: $after, before: $before, limit: $limit) {
    pagination {
      ...PaginationFields
    }
    data {
      ...ReportingFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${ReportingFieldsFragmentDoc}`;

/**
 * __useGetReportsQuery__
 *
 * To run a query within a React component, call `useGetReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetReportsQuery(baseOptions: Apollo.QueryHookOptions<GetReportsQuery, GetReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options);
      }
export function useGetReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportsQuery, GetReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options);
        }
export type GetReportsQueryHookResult = ReturnType<typeof useGetReportsQuery>;
export type GetReportsLazyQueryHookResult = ReturnType<typeof useGetReportsLazyQuery>;
export type GetReportsQueryResult = Apollo.QueryResult<GetReportsQuery, GetReportsQueryVariables>;
export const GetRolesDocument = gql`
    query getRoles {
  getRoles {
    ...MinimalRoleFields
  }
}
    ${MinimalRoleFieldsFragmentDoc}`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const UpdateSchoolDocument = gql`
    mutation updateSchool($id: String!, $data: SchoolInput!) {
  updateSchool(id: $id, input: $data) {
    ...MeSchoolFields
  }
}
    ${MeSchoolFieldsFragmentDoc}`;
export type UpdateSchoolMutationFn = Apollo.MutationFunction<UpdateSchoolMutation, UpdateSchoolMutationVariables>;

/**
 * __useUpdateSchoolMutation__
 *
 * To run a mutation, you first call `useUpdateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchoolMutation, { data, loading, error }] = useUpdateSchoolMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSchoolMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchoolMutation, UpdateSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchoolMutation, UpdateSchoolMutationVariables>(UpdateSchoolDocument, options);
      }
export type UpdateSchoolMutationHookResult = ReturnType<typeof useUpdateSchoolMutation>;
export type UpdateSchoolMutationResult = Apollo.MutationResult<UpdateSchoolMutation>;
export type UpdateSchoolMutationOptions = Apollo.BaseMutationOptions<UpdateSchoolMutation, UpdateSchoolMutationVariables>;
export const CreateSubmissionDocument = gql`
    mutation createSubmission($data: SubmissionInput!) {
  createSubmission(input: $data) {
    ...ExtendedSubmissionFields
  }
}
    ${ExtendedSubmissionFieldsFragmentDoc}`;
export type CreateSubmissionMutationFn = Apollo.MutationFunction<CreateSubmissionMutation, CreateSubmissionMutationVariables>;

/**
 * __useCreateSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubmissionMutation, { data, loading, error }] = useCreateSubmissionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubmissionMutation, CreateSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubmissionMutation, CreateSubmissionMutationVariables>(CreateSubmissionDocument, options);
      }
export type CreateSubmissionMutationHookResult = ReturnType<typeof useCreateSubmissionMutation>;
export type CreateSubmissionMutationResult = Apollo.MutationResult<CreateSubmissionMutation>;
export type CreateSubmissionMutationOptions = Apollo.BaseMutationOptions<CreateSubmissionMutation, CreateSubmissionMutationVariables>;
export const GetAssignmentSubmissionsDocument = gql`
    query getAssignmentSubmissions($assignmentId: String!) {
  getAssignmentSubmissions(assignmentId: $assignmentId) {
    status
    submission {
      ...SubmissionFields
    }
    grade {
      ...UserGradeFields
    }
    user {
      ...ClassUserFields
    }
  }
}
    ${SubmissionFieldsFragmentDoc}
${UserGradeFieldsFragmentDoc}
${ClassUserFieldsFragmentDoc}`;

/**
 * __useGetAssignmentSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetAssignmentSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentSubmissionsQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useGetAssignmentSubmissionsQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentSubmissionsQuery, GetAssignmentSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentSubmissionsQuery, GetAssignmentSubmissionsQueryVariables>(GetAssignmentSubmissionsDocument, options);
      }
export function useGetAssignmentSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentSubmissionsQuery, GetAssignmentSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentSubmissionsQuery, GetAssignmentSubmissionsQueryVariables>(GetAssignmentSubmissionsDocument, options);
        }
export type GetAssignmentSubmissionsQueryHookResult = ReturnType<typeof useGetAssignmentSubmissionsQuery>;
export type GetAssignmentSubmissionsLazyQueryHookResult = ReturnType<typeof useGetAssignmentSubmissionsLazyQuery>;
export type GetAssignmentSubmissionsQueryResult = Apollo.QueryResult<GetAssignmentSubmissionsQuery, GetAssignmentSubmissionsQueryVariables>;
export const GetStudentSubmissionDocument = gql`
    query getStudentSubmission($schoolId: String!, $assignmentId: String!, $userId: String!) {
  getStudentSubmission(
    schoolId: $schoolId
    assignmentId: $assignmentId
    userId: $userId
  ) {
    status
    assignment {
      ...SingleStudentAssignmentFields
    }
    grade {
      ...UserGradeFields
    }
    submission {
      ...ExtendedSubmissionFields
    }
    user {
      ...ClassUserFields
    }
  }
}
    ${SingleStudentAssignmentFieldsFragmentDoc}
${UserGradeFieldsFragmentDoc}
${ExtendedSubmissionFieldsFragmentDoc}
${ClassUserFieldsFragmentDoc}`;

/**
 * __useGetStudentSubmissionQuery__
 *
 * To run a query within a React component, call `useGetStudentSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentSubmissionQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      assignmentId: // value for 'assignmentId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetStudentSubmissionQuery(baseOptions: Apollo.QueryHookOptions<GetStudentSubmissionQuery, GetStudentSubmissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentSubmissionQuery, GetStudentSubmissionQueryVariables>(GetStudentSubmissionDocument, options);
      }
export function useGetStudentSubmissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentSubmissionQuery, GetStudentSubmissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentSubmissionQuery, GetStudentSubmissionQueryVariables>(GetStudentSubmissionDocument, options);
        }
export type GetStudentSubmissionQueryHookResult = ReturnType<typeof useGetStudentSubmissionQuery>;
export type GetStudentSubmissionLazyQueryHookResult = ReturnType<typeof useGetStudentSubmissionLazyQuery>;
export type GetStudentSubmissionQueryResult = Apollo.QueryResult<GetStudentSubmissionQuery, GetStudentSubmissionQueryVariables>;
export const CreateTagDocument = gql`
    mutation createTag($schoolId: String!, $data: TagInput!) {
  createTag(schoolId: $schoolId, input: $data) {
    ...TagFields
  }
}
    ${TagFieldsFragmentDoc}`;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, options);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const UpdateTagDocument = gql`
    mutation updateTag($id: String!, $data: TagInput!) {
  updateTag(id: $id, input: $data) {
    ...TagFields
  }
}
    ${TagFieldsFragmentDoc}`;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, options);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const ReactivateTagsDocument = gql`
    mutation reactivateTags($data: MultipleIdsInput!) {
  reactivateTags(input: $data)
}
    `;
export type ReactivateTagsMutationFn = Apollo.MutationFunction<ReactivateTagsMutation, ReactivateTagsMutationVariables>;

/**
 * __useReactivateTagsMutation__
 *
 * To run a mutation, you first call `useReactivateTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateTagsMutation, { data, loading, error }] = useReactivateTagsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReactivateTagsMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateTagsMutation, ReactivateTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateTagsMutation, ReactivateTagsMutationVariables>(ReactivateTagsDocument, options);
      }
export type ReactivateTagsMutationHookResult = ReturnType<typeof useReactivateTagsMutation>;
export type ReactivateTagsMutationResult = Apollo.MutationResult<ReactivateTagsMutation>;
export type ReactivateTagsMutationOptions = Apollo.BaseMutationOptions<ReactivateTagsMutation, ReactivateTagsMutationVariables>;
export const RemoveTagsDocument = gql`
    mutation removeTags($data: MultipleIdsInput!) {
  removeTags(input: $data)
}
    `;
export type RemoveTagsMutationFn = Apollo.MutationFunction<RemoveTagsMutation, RemoveTagsMutationVariables>;

/**
 * __useRemoveTagsMutation__
 *
 * To run a mutation, you first call `useRemoveTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagsMutation, { data, loading, error }] = useRemoveTagsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveTagsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTagsMutation, RemoveTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTagsMutation, RemoveTagsMutationVariables>(RemoveTagsDocument, options);
      }
export type RemoveTagsMutationHookResult = ReturnType<typeof useRemoveTagsMutation>;
export type RemoveTagsMutationResult = Apollo.MutationResult<RemoveTagsMutation>;
export type RemoveTagsMutationOptions = Apollo.BaseMutationOptions<RemoveTagsMutation, RemoveTagsMutationVariables>;
export const GetTagsDocument = gql`
    query getTags($schoolId: String!, $after: String, $before: String, $limit: Int = 10, $search: String, $deleted: Boolean) {
  getTags(
    schoolId: $schoolId
    after: $after
    before: $before
    limit: $limit
    search: $search
    deleted: $deleted
  ) {
    pagination {
      ...PaginationFields
    }
    data {
      ...TagFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${TagFieldsFragmentDoc}`;

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      deleted: // value for 'deleted'
 *   },
 * });
 */
export function useGetTagsQuery(baseOptions: Apollo.QueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
      }
export function useGetTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
        }
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>;
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>;
export type GetTagsQueryResult = Apollo.QueryResult<GetTagsQuery, GetTagsQueryVariables>;
export const GetTagDocument = gql`
    query getTag($schoolId: String!, $id: String!) {
  getTag(schoolId: $schoolId, id: $id) {
    ...TagFields
  }
}
    ${TagFieldsFragmentDoc}`;

/**
 * __useGetTagQuery__
 *
 * To run a query within a React component, call `useGetTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTagQuery(baseOptions: Apollo.QueryHookOptions<GetTagQuery, GetTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagQuery, GetTagQueryVariables>(GetTagDocument, options);
      }
export function useGetTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagQuery, GetTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagQuery, GetTagQueryVariables>(GetTagDocument, options);
        }
export type GetTagQueryHookResult = ReturnType<typeof useGetTagQuery>;
export type GetTagLazyQueryHookResult = ReturnType<typeof useGetTagLazyQuery>;
export type GetTagQueryResult = Apollo.QueryResult<GetTagQuery, GetTagQueryVariables>;
export const CreateTermDocument = gql`
    mutation createTerm($schoolId: String!, $data: TermInput!) {
  createTerm(schoolId: $schoolId, input: $data) {
    ...TermFields
  }
}
    ${TermFieldsFragmentDoc}`;
export type CreateTermMutationFn = Apollo.MutationFunction<CreateTermMutation, CreateTermMutationVariables>;

/**
 * __useCreateTermMutation__
 *
 * To run a mutation, you first call `useCreateTermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTermMutation, { data, loading, error }] = useCreateTermMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTermMutation(baseOptions?: Apollo.MutationHookOptions<CreateTermMutation, CreateTermMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTermMutation, CreateTermMutationVariables>(CreateTermDocument, options);
      }
export type CreateTermMutationHookResult = ReturnType<typeof useCreateTermMutation>;
export type CreateTermMutationResult = Apollo.MutationResult<CreateTermMutation>;
export type CreateTermMutationOptions = Apollo.BaseMutationOptions<CreateTermMutation, CreateTermMutationVariables>;
export const UpdateTermDocument = gql`
    mutation updateTerm($id: String!, $data: TermInput!) {
  updateTerm(id: $id, input: $data) {
    ...TermFields
  }
}
    ${TermFieldsFragmentDoc}`;
export type UpdateTermMutationFn = Apollo.MutationFunction<UpdateTermMutation, UpdateTermMutationVariables>;

/**
 * __useUpdateTermMutation__
 *
 * To run a mutation, you first call `useUpdateTermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTermMutation, { data, loading, error }] = useUpdateTermMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTermMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTermMutation, UpdateTermMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTermMutation, UpdateTermMutationVariables>(UpdateTermDocument, options);
      }
export type UpdateTermMutationHookResult = ReturnType<typeof useUpdateTermMutation>;
export type UpdateTermMutationResult = Apollo.MutationResult<UpdateTermMutation>;
export type UpdateTermMutationOptions = Apollo.BaseMutationOptions<UpdateTermMutation, UpdateTermMutationVariables>;
export const ReactivateTermsDocument = gql`
    mutation reactivateTerms($data: MultipleIdsInput!) {
  reactivateTerms(input: $data)
}
    `;
export type ReactivateTermsMutationFn = Apollo.MutationFunction<ReactivateTermsMutation, ReactivateTermsMutationVariables>;

/**
 * __useReactivateTermsMutation__
 *
 * To run a mutation, you first call `useReactivateTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateTermsMutation, { data, loading, error }] = useReactivateTermsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReactivateTermsMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateTermsMutation, ReactivateTermsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateTermsMutation, ReactivateTermsMutationVariables>(ReactivateTermsDocument, options);
      }
export type ReactivateTermsMutationHookResult = ReturnType<typeof useReactivateTermsMutation>;
export type ReactivateTermsMutationResult = Apollo.MutationResult<ReactivateTermsMutation>;
export type ReactivateTermsMutationOptions = Apollo.BaseMutationOptions<ReactivateTermsMutation, ReactivateTermsMutationVariables>;
export const RemoveTermsDocument = gql`
    mutation removeTerms($data: MultipleIdsInput!) {
  removeTerms(input: $data)
}
    `;
export type RemoveTermsMutationFn = Apollo.MutationFunction<RemoveTermsMutation, RemoveTermsMutationVariables>;

/**
 * __useRemoveTermsMutation__
 *
 * To run a mutation, you first call `useRemoveTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTermsMutation, { data, loading, error }] = useRemoveTermsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveTermsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTermsMutation, RemoveTermsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTermsMutation, RemoveTermsMutationVariables>(RemoveTermsDocument, options);
      }
export type RemoveTermsMutationHookResult = ReturnType<typeof useRemoveTermsMutation>;
export type RemoveTermsMutationResult = Apollo.MutationResult<RemoveTermsMutation>;
export type RemoveTermsMutationOptions = Apollo.BaseMutationOptions<RemoveTermsMutation, RemoveTermsMutationVariables>;
export const GetTermsDocument = gql`
    query getTerms($schoolId: String!, $after: String, $before: String, $limit: Int = 10, $search: String, $deleted: Boolean) {
  getTerms(
    schoolId: $schoolId
    after: $after
    before: $before
    limit: $limit
    search: $search
    deleted: $deleted
  ) {
    pagination {
      ...PaginationFields
    }
    data {
      ...TermFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${TermFieldsFragmentDoc}`;

/**
 * __useGetTermsQuery__
 *
 * To run a query within a React component, call `useGetTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTermsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      deleted: // value for 'deleted'
 *   },
 * });
 */
export function useGetTermsQuery(baseOptions: Apollo.QueryHookOptions<GetTermsQuery, GetTermsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTermsQuery, GetTermsQueryVariables>(GetTermsDocument, options);
      }
export function useGetTermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTermsQuery, GetTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTermsQuery, GetTermsQueryVariables>(GetTermsDocument, options);
        }
export type GetTermsQueryHookResult = ReturnType<typeof useGetTermsQuery>;
export type GetTermsLazyQueryHookResult = ReturnType<typeof useGetTermsLazyQuery>;
export type GetTermsQueryResult = Apollo.QueryResult<GetTermsQuery, GetTermsQueryVariables>;
export const GetTermDocument = gql`
    query getTerm($schoolId: String!, $id: String!) {
  getTerm(schoolId: $schoolId, id: $id) {
    ...TermFields
  }
}
    ${TermFieldsFragmentDoc}`;

/**
 * __useGetTermQuery__
 *
 * To run a query within a React component, call `useGetTermQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTermQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTermQuery(baseOptions: Apollo.QueryHookOptions<GetTermQuery, GetTermQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTermQuery, GetTermQueryVariables>(GetTermDocument, options);
      }
export function useGetTermLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTermQuery, GetTermQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTermQuery, GetTermQueryVariables>(GetTermDocument, options);
        }
export type GetTermQueryHookResult = ReturnType<typeof useGetTermQuery>;
export type GetTermLazyQueryHookResult = ReturnType<typeof useGetTermLazyQuery>;
export type GetTermQueryResult = Apollo.QueryResult<GetTermQuery, GetTermQueryVariables>;
export const UploadFilesDocument = gql`
    mutation uploadFiles($files: [Upload!]!) {
  uploadFiles(files: $files) {
    name
    size
    url
  }
}
    `;
export type UploadFilesMutationFn = Apollo.MutationFunction<UploadFilesMutation, UploadFilesMutationVariables>;

/**
 * __useUploadFilesMutation__
 *
 * To run a mutation, you first call `useUploadFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFilesMutation, { data, loading, error }] = useUploadFilesMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadFilesMutation(baseOptions?: Apollo.MutationHookOptions<UploadFilesMutation, UploadFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFilesMutation, UploadFilesMutationVariables>(UploadFilesDocument, options);
      }
export type UploadFilesMutationHookResult = ReturnType<typeof useUploadFilesMutation>;
export type UploadFilesMutationResult = Apollo.MutationResult<UploadFilesMutation>;
export type UploadFilesMutationOptions = Apollo.BaseMutationOptions<UploadFilesMutation, UploadFilesMutationVariables>;
export const GetUserAttendanceDocument = gql`
    query getUserAttendance($userId: String!, $from: String!, $to: String!, $classId: String) {
  getUserAttendance(userId: $userId, from: $from, to: $to, classId: $classId) {
    columns {
      id
      name
    }
    rows {
      class {
        id
        name
      }
      attendances {
        ...UserAttendanceColumnFields
      }
    }
  }
}
    ${UserAttendanceColumnFieldsFragmentDoc}`;

/**
 * __useGetUserAttendanceQuery__
 *
 * To run a query within a React component, call `useGetUserAttendanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAttendanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAttendanceQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetUserAttendanceQuery(baseOptions: Apollo.QueryHookOptions<GetUserAttendanceQuery, GetUserAttendanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAttendanceQuery, GetUserAttendanceQueryVariables>(GetUserAttendanceDocument, options);
      }
export function useGetUserAttendanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAttendanceQuery, GetUserAttendanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAttendanceQuery, GetUserAttendanceQueryVariables>(GetUserAttendanceDocument, options);
        }
export type GetUserAttendanceQueryHookResult = ReturnType<typeof useGetUserAttendanceQuery>;
export type GetUserAttendanceLazyQueryHookResult = ReturnType<typeof useGetUserAttendanceLazyQuery>;
export type GetUserAttendanceQueryResult = Apollo.QueryResult<GetUserAttendanceQuery, GetUserAttendanceQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($schoolId: String!, $data: UserSchoolInput!) {
  createUser(schoolId: $schoolId, input: $data) {
    exists
    user {
      ...SingleUserFields
    }
  }
}
    ${SingleUserFieldsFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($schoolId: String!, $id: String!, $data: UpdateUserSchoolInput!) {
  updateUser(schoolId: $schoolId, userId: $id, input: $data) {
    ...SingleUserFields
  }
}
    ${SingleUserFieldsFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const BulkUserStatusUpdateDocument = gql`
    mutation bulkUserStatusUpdate($data: UpdateBulkUserSchoolStatusInput!) {
  bulkUserStatusUpdate(input: $data)
}
    `;
export type BulkUserStatusUpdateMutationFn = Apollo.MutationFunction<BulkUserStatusUpdateMutation, BulkUserStatusUpdateMutationVariables>;

/**
 * __useBulkUserStatusUpdateMutation__
 *
 * To run a mutation, you first call `useBulkUserStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUserStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUserStatusUpdateMutation, { data, loading, error }] = useBulkUserStatusUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBulkUserStatusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BulkUserStatusUpdateMutation, BulkUserStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUserStatusUpdateMutation, BulkUserStatusUpdateMutationVariables>(BulkUserStatusUpdateDocument, options);
      }
export type BulkUserStatusUpdateMutationHookResult = ReturnType<typeof useBulkUserStatusUpdateMutation>;
export type BulkUserStatusUpdateMutationResult = Apollo.MutationResult<BulkUserStatusUpdateMutation>;
export type BulkUserStatusUpdateMutationOptions = Apollo.BaseMutationOptions<BulkUserStatusUpdateMutation, BulkUserStatusUpdateMutationVariables>;
export const AddUserParentsDocument = gql`
    mutation addUserParents($parentIds: [String!]!, $userId: String!) {
  addUserParents(parentIds: $parentIds, userId: $userId)
}
    `;
export type AddUserParentsMutationFn = Apollo.MutationFunction<AddUserParentsMutation, AddUserParentsMutationVariables>;

/**
 * __useAddUserParentsMutation__
 *
 * To run a mutation, you first call `useAddUserParentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserParentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserParentsMutation, { data, loading, error }] = useAddUserParentsMutation({
 *   variables: {
 *      parentIds: // value for 'parentIds'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddUserParentsMutation(baseOptions?: Apollo.MutationHookOptions<AddUserParentsMutation, AddUserParentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserParentsMutation, AddUserParentsMutationVariables>(AddUserParentsDocument, options);
      }
export type AddUserParentsMutationHookResult = ReturnType<typeof useAddUserParentsMutation>;
export type AddUserParentsMutationResult = Apollo.MutationResult<AddUserParentsMutation>;
export type AddUserParentsMutationOptions = Apollo.BaseMutationOptions<AddUserParentsMutation, AddUserParentsMutationVariables>;
export const RemoveUserParentsDocument = gql`
    mutation removeUserParents($parentIds: [String!]!, $userId: String!) {
  removeUserParents(parentIds: $parentIds, userId: $userId)
}
    `;
export type RemoveUserParentsMutationFn = Apollo.MutationFunction<RemoveUserParentsMutation, RemoveUserParentsMutationVariables>;

/**
 * __useRemoveUserParentsMutation__
 *
 * To run a mutation, you first call `useRemoveUserParentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserParentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserParentsMutation, { data, loading, error }] = useRemoveUserParentsMutation({
 *   variables: {
 *      parentIds: // value for 'parentIds'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveUserParentsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserParentsMutation, RemoveUserParentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserParentsMutation, RemoveUserParentsMutationVariables>(RemoveUserParentsDocument, options);
      }
export type RemoveUserParentsMutationHookResult = ReturnType<typeof useRemoveUserParentsMutation>;
export type RemoveUserParentsMutationResult = Apollo.MutationResult<RemoveUserParentsMutation>;
export type RemoveUserParentsMutationOptions = Apollo.BaseMutationOptions<RemoveUserParentsMutation, RemoveUserParentsMutationVariables>;
export const GetUsersDocument = gql`
    query getUsers($schoolId: String!, $role: [RoleTypeEnum!], $status: UserStatuses, $after: String, $before: String, $limit: Int = 10, $search: String) {
  getUsers(
    schoolId: $schoolId
    role: $role
    status: $status
    after: $after
    before: $before
    limit: $limit
    search: $search
  ) {
    pagination {
      ...PaginationFields
    }
    data {
      ...TableUserFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${TableUserFieldsFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      role: // value for 'role'
 *      status: // value for 'status'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserDocument = gql`
    query getUser($schoolId: String!, $id: String!) {
  getUser(schoolId: $schoolId, id: $id) {
    ...SingleUserFields
  }
}
    ${SingleUserFieldsFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserGradeListDocument = gql`
    query getUserGradeList($schoolId: String!) {
  getUserGradeList(schoolId: $schoolId)
}
    `;

/**
 * __useGetUserGradeListQuery__
 *
 * To run a query within a React component, call `useGetUserGradeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserGradeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserGradeListQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetUserGradeListQuery(baseOptions: Apollo.QueryHookOptions<GetUserGradeListQuery, GetUserGradeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserGradeListQuery, GetUserGradeListQueryVariables>(GetUserGradeListDocument, options);
      }
export function useGetUserGradeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserGradeListQuery, GetUserGradeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserGradeListQuery, GetUserGradeListQueryVariables>(GetUserGradeListDocument, options);
        }
export type GetUserGradeListQueryHookResult = ReturnType<typeof useGetUserGradeListQuery>;
export type GetUserGradeListLazyQueryHookResult = ReturnType<typeof useGetUserGradeListLazyQuery>;
export type GetUserGradeListQueryResult = Apollo.QueryResult<GetUserGradeListQuery, GetUserGradeListQueryVariables>;
export const GetParentStudentsDocument = gql`
    query getParentStudents($schoolId: String!, $parentId: String!, $search: String) {
  getParentStudents(schoolId: $schoolId, parentId: $parentId, search: $search) {
    ...TableUserFields
  }
}
    ${TableUserFieldsFragmentDoc}`;

/**
 * __useGetParentStudentsQuery__
 *
 * To run a query within a React component, call `useGetParentStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentStudentsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      parentId: // value for 'parentId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetParentStudentsQuery(baseOptions: Apollo.QueryHookOptions<GetParentStudentsQuery, GetParentStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParentStudentsQuery, GetParentStudentsQueryVariables>(GetParentStudentsDocument, options);
      }
export function useGetParentStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParentStudentsQuery, GetParentStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParentStudentsQuery, GetParentStudentsQueryVariables>(GetParentStudentsDocument, options);
        }
export type GetParentStudentsQueryHookResult = ReturnType<typeof useGetParentStudentsQuery>;
export type GetParentStudentsLazyQueryHookResult = ReturnType<typeof useGetParentStudentsLazyQuery>;
export type GetParentStudentsQueryResult = Apollo.QueryResult<GetParentStudentsQuery, GetParentStudentsQueryVariables>;
export const GetUserParentsDocument = gql`
    query getUserParents($schoolId: String!, $userId: String!) {
  getUserParents(schoolId: $schoolId, userId: $userId) {
    ...SingleUserFields
  }
}
    ${SingleUserFieldsFragmentDoc}`;

/**
 * __useGetUserParentsQuery__
 *
 * To run a query within a React component, call `useGetUserParentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserParentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserParentsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserParentsQuery(baseOptions: Apollo.QueryHookOptions<GetUserParentsQuery, GetUserParentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserParentsQuery, GetUserParentsQueryVariables>(GetUserParentsDocument, options);
      }
export function useGetUserParentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserParentsQuery, GetUserParentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserParentsQuery, GetUserParentsQueryVariables>(GetUserParentsDocument, options);
        }
export type GetUserParentsQueryHookResult = ReturnType<typeof useGetUserParentsQuery>;
export type GetUserParentsLazyQueryHookResult = ReturnType<typeof useGetUserParentsLazyQuery>;
export type GetUserParentsQueryResult = Apollo.QueryResult<GetUserParentsQuery, GetUserParentsQueryVariables>;